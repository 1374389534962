import { BigNumber } from "bignumber.js";
import dayjs from "dayjs";
import { ethers } from "ethers";
import utc from "dayjs/plugin/utc";
import { timeframeOptions } from "./helpers";
import Numeral from "numeral";

// format libraries
BigNumber.set({ EXPONENTIAL_AT: 50 });
dayjs.extend(utc);

export function getTimeframe(timeWindow) {
  const utcEndTime = dayjs.utc();
  // based on window, get starttime
  let utcStartTime;
  switch (timeWindow) {
    case timeframeOptions.ONE_DAY:
      utcStartTime = utcEndTime.subtract(1, "day").endOf("day").unix() - 1;
      break;
    case timeframeOptions.THERE_DAYS:
      utcStartTime = utcEndTime.subtract(3, "day").endOf("day").unix() - 1;
      break;
    case timeframeOptions.WEEK:
      utcStartTime = utcEndTime.subtract(1, "week").endOf("day").unix() - 1;
      break;
    case timeframeOptions.MONTH:
      utcStartTime = utcEndTime.subtract(1, "month").endOf("day").unix() - 1;
      break;
    case timeframeOptions.ALL_TIME:
      utcStartTime = utcEndTime.subtract(1, "year").endOf("day").unix() - 1;
      break;
    default:
      utcStartTime = utcEndTime.subtract(1, "year").startOf("year").unix() - 1;
      break;
  }
  return utcStartTime;
}

export const toNiceDateTime = (date) => {
  console.log("utils toNiceDate", date);
  let x = dayjs.utc(dayjs.unix(date)).format("YYYY/MM/DD");
  return x;
};

export const toNiceDate = (date) => {
  console.log(
    "utils toNiceDate",
    date,
    dayjs.utc(dayjs.unix(date)).format("YYYY/MM/DD")
  );
  let x = dayjs.utc(dayjs.unix(date)).format("MMM DD");
  return x;
};

export const toNiceDateYear = (date) =>
  dayjs.utc(dayjs.unix(date)).format("MMMM DD h:mm A, YYYY");

export const isAddress = (value) => {
  try {
    return ethers.utils.getAddress(value.toLowerCase());
  } catch {
    return false;
  }
};

// using a currency library here in case we want to add more in future
var priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 3,
});

export const toK = (num) => {
  return Numeral(num).format("0.[00]a");
};

export const formattedNum = (number, usd = false, acceptNegatives = false) => {
  if (isNaN(number) || number === "" || number === undefined) {
    return usd ? "$0" : 0;
  }
  let num = parseFloat(number);

  if (num > 500000000) {
    return (usd ? "$" : "") + toK(num.toFixed(0), true);
  }

  if (num === 0) {
    if (usd) {
      return "$0";
    }
    return 0;
  }

  if (num < 0.0001 && num > 0) {
    return usd ? "< $0.0001" : "< 0.0001";
  }

  if (num > 1000) {
    return usd
      ? "$" + Number(parseFloat(num).toFixed(0)).toLocaleString("en-US")
      : "" + Number(parseFloat(num).toFixed(0)).toLocaleString("en-US");
  }

  if (usd) {
    if (num < 0.1) {
      return "$" + Number(parseFloat(num).toFixed(4));
    } else {
      let usdString = priceFormatter.format(num);
      return "$" + usdString.slice(1, usdString.length);
    }
  }

  return Number(parseFloat(num).toFixed(5));
};

export const parseScientific = (num) => {
  // If the number is not in scientific notation return it as it is
  if (!/\d+\.?\d*e[+-]*\d+/i.test(num)) return num;

  // Remove the sign
  const numberSign = Math.sign(Number(num));
  num = Math.abs(Number(num)).toString();

  // Parse into coefficient and exponent
  const [coefficient, exponent] = num.toLowerCase().split("e");
  let zeros = Math.abs(Number(exponent));
  const exponentSign = Math.sign(Number(exponent));
  const [integer, decimals] = (
    coefficient.indexOf(".") !== -1 ? coefficient : `${coefficient}.`
  ).split(".");

  if (exponentSign === -1) {
    zeros -= integer.length;
    num =
      zeros < 0
        ? integer.slice(0, zeros) + "." + integer.slice(zeros) + decimals
        : "0." + "0".repeat(zeros) + integer + decimals;
  } else {
    if (decimals) zeros -= decimals.length;
    num =
      zeros < 0
        ? integer + decimals.slice(0, zeros) + "." + decimals.slice(zeros)
        : integer + decimals + "0".repeat(zeros);
  }

  return numberSign < 0 ? "-" + num : num;
};

export const convertToBlockTimestamp = (day, month, year) => {
  const date = new Date(year, month - 1, day);
  return Math.floor(date.getTime() / 1000);
};
