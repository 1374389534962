import React, { Component, useRef } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Button, Col } from "react-bootstrap";
import Loader from "../loader";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { format } from "date-fns";
import VerticallyModal from "../../components/modal/VerticallyModal";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import { AddManager } from "../modal/AddManager";
import { AddLiquidity } from "../modal/IssueLiquidity";
import { ProvideLiquidity } from "../modal/ProvideLiquidity";
import { AssignManager } from "../modal/AssignManager";
import { AcceptInvestor } from "../modal/AcceptInvestor";
import { PayOut } from "../modal/PayOut";
import { MakeOffer } from "../modal/MakeOffer";
import { StartIssue } from "../modal/StartIssue";
import { EditOrder } from "../modal/EditOrder";
import { Refunds } from "../modal/ViewRefund";
import { Earnings } from "../modal/Earnings";
import { Allotments } from "../modal/Allotments";
import { LiquidityOffered } from "../modal/LiquidityOffered";
import { OffersList } from "../modal/OffersList";
import { ScheduleSnapshot } from "../modal/ScheduleSnapshot";
import { RescheduleSnapshot } from "../modal/RescheduleSnapshot";
import { UnscheduleSnapshot } from "../modal/UnscheduleSnapshot";
import { ScheduleResolution } from "../modal/ScheduleResolution";
import { SchedulePayout } from "../modal/SchedulePayout";
import { ScheduleInvestorPayout } from "../modal/ScheduleInvestorPayout";
import { SnapshotList } from "../modal/SnapshotList";
import { KYCRejectionCheck } from "../modal/KYCRejectionCheck";
import { FloatingSecurities } from "../modal/FloatingSecurities";
import { InvestorSnapshot } from "../modal/InvestorSnapshot";
import { InvestorVoting } from "../modal/InvestorVoting";
import { BurnInvestorSecurity } from "../modal/BurnInvestorSecurity";
import { FreezeInvestor } from "../modal/FreezeInvestor";
import AddOffer from "../../pages/assetManager/createSecondaryIssue/offer";
import Web3 from "web3";
import "react-toastify/dist/ReactToastify.css";
import { MaxUint256, balancerVault } from "../../utils/constants";
import Response, { signMessage } from "../../utils/response";
import { Swaps } from "@balancer-labs/sdk";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  productCategory,
  networks,
} from "../../utils/constants";
import { toast } from "react-toastify";
import { contractAddress, ERC20, Token } from "@verified-network/verified-sdk";
import ERC20ABI from "../../utils/abi/ERC20.json";
import Security from "../../utils/abi/Security.json";
import MargingPool from "../../utils/abi/MarginTradingPool.json";
import OrderBook from "../../utils/abi/Orderbook.json";
import Vault from "../../utils/abi/Vault.json";
import { ethers } from "ethers";
import config from "../../services/config/homestead.json";
import { PoolType } from "../../pages/poolDetail/balancer/types";
import UiButton from "../button";
import { getWeb3, NETWORKS_INFO } from "../../utils/helpers/networks";
import {
  approveOrRejectApplicant,
  getApplicantMessages,
  getImagesInBase64,
  getSumsubAccessToken,
} from "../../pages/kycManager/kycUtils";
import { SetIssueFee } from "../modal/setIssuingFee";

const axios = require("axios");

const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

const sortByIntegerValue = (a, b, order) => {
  const numA = parseInt(a);
  const numB = parseInt(b);

  if (order === "asc") {
    return numB - numA;
  }

  return numA - numB;
};

const sortByDateValue = (a, b, order) => {
  const numA = new Date(a).getTime();
  const numB = new Date(b).getTime();

  if (order === "asc") {
    return numB - numA;
  }

  return numA - numB;
};

export const shortAddressCheck = (cell) => {
  const lst2 = cell.slice(-5);
  const first4 = cell.slice(0, 6);
  return first4 + "..." + lst2;
};
class UiTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      heading: "",
      modalContent: "",
      loading: false,
      toggle: false,
      recordDate: null,
      balance: null,
      earnings: null,
    };
    this.handleRecordDateChange = this.handleRecordDateChange.bind(this);
  }
  noDataIndication = () => {
    return <p>No data</p>;
  };

  handleRecordDateChange = async (row, snapshot) => {
    const balance = await row.securityTokenContract.methods
      .snapshotBalanceOf(snapshot, row.transferor)
      .call();
    const earnings = await row.securityTokenContract.methods
      .getDistribution(snapshot)
      .call();
    this.setState({
      recordDate: snapshot,
      balance: this.props.web3.utils.fromWei(balance.toString(), "ether"),
      earnings: earnings[1],
    });
  };

  render() {
    const {
      tbodyData,
      thead,
      data,
      columns,
      contract,
      signer,
      platformId,
      chainId,
      web3,
      userRole,
      provider,
      transport,
      account,
      updateTableData,
      isUpdate,
      networkOptions,
      commonProps,
      showFilter,
      history,
    } = this.props;

    const downloadPdf = commonProps?.toPDF;
    const targetRef = commonProps?.targetRef;

    const componentToPrintRef = commonProps?.componentToPrintRef;
    const printPdf = commonProps?.handlePrint;

    const tableColumns = columns ?? thead;
    const tableData = data ?? tbodyData;
    const secondaryIssueManager =
      contractAddress[chainId ?? 5].BalancerSecondaryIssueManager;

    const handleModalHide = () => {
      this.setState({ showModal: !this.state.showModal });
    };

    function tokenFormatter(cell, row) {
      const filter = row.tokens.filter((token) => {
        return cell === token.address;
      });
      return (
        <span>
          <strong>{filter.length ? filter[0].symbol : null}</strong>
        </span>
      );
    }

    function displaySecurityToken(cell, row) {
      return (
        <span>
          <strong>
            {row.securitySymbol ||
              row.marginSecurityName ||
              row.marginSecuritySymbol}
          </strong>
        </span>
      );
    }

    function displayCurrencyToken(cell, row) {
      return (
        <span>
          <strong>
            {row.currencySymbol ||
              row.marginCurrencyName ||
              row.marginCurrencySymbol}
          </strong>
        </span>
      );
    }

    function unixTimeFormatter(cell, row) {
      var date = new Date(Number(cell) * 1000);
      return (
        <span>
          <span>{date.toLocaleDateString("default")}</span>
        </span>
      );
    }

    const updateBalance = (cell, row) => {
      const balance = this.state?.balance;
      return (
        <span>
          <span>{balance ? balance : "Select Record Date"}</span>
        </span>
      );
    };

    const updateEarnings = (cell, row) => {
      const earnings = this.state?.earnings;
      return (
        <span>
          <span>{earnings ? earnings : "Select Record Date"}</span>
        </span>
      );
    };

    function tokenNameBeautify(cell, row) {
      return (
        <span>
          <b>{cell}</b>
        </span>
      );
    }

    function servicerIssueDate(cell, row) {
      const date = new Date(
        Number(
          row.poolType === "PrimaryIssue" ? row.cutoffTime : row.createTime
        ) * 1000
      );
      return <span>{date.toLocaleDateString("default")}</span>;
    }

    const shortIPFSAddress = (cell) => {
      const lst2 = cell.slice(-5);
      const first4 = cell.slice(0, 6);
      return (
        <a
          href={`https://ipfs.io/ipfs/${cell}`}
          target="_blank"
          onClick={(e) => {
            return true;
          }}
        >
          {first4}...{lst2}
        </a>
      );
    };

    const securityLinkRedirect = (cell, row) => {
      return (
        <a
          href={row.securityTokenLink}
          target="_blank"
          onClick={(e) => {
            return true;
          }}
        >
          <b>{cell}</b>
        </a>
      );
    };

    const currencyLinkRedirect = (cell, row) => {
      return (
        <a
          href={row.currencyTokenLink}
          target="_blank"
          onClick={(e) => {
            return true;
          }}
        >
          <b>{cell}</b>
        </a>
      );
    };

    const transferorLinkRedirect = (cell, row) => {
      return (
        <a
          href={row.transferorLink}
          target="_blank"
          onClick={(e) => {
            return true;
          }}
        >
          {cell}
        </a>
      );
    };

    const transfereeLinkRedirect = (cell, row) => {
      return (
        <a
          href={row.transfereeLink}
          target="_blank"
          onClick={(e) => {
            return true;
          }}
        >
          {cell}
        </a>
      );
    };

    const handleIssuerRedirect = (cell, row) => {
      return (
        <a
          href={`${networks[chainId].blockExplorerUrls[0]}/address/${row.IssuerAddress}`}
          target="_blank"
          onClick={(e) => {
            return true;
          }}
        >
          {cell}
        </a>
      );
    };

    const decodeIPFSAddress = (cell, row) => {
      const lst2 = cell.slice(-5);
      const first4 = cell.slice(0, 6);
      return (
        <a
          onClick={async (e) => {
            this.setState({ loading: !this.state.loading });
            const base64Data = await axios
              .get(
                `${config.approvalEndpoint}?imageCID=${cell}&applicantId=${row.applicantId}`
              )
              .then((response) => {
                return response.data.fileData;
              });
            let imageType;
            for (const key in row) {
              if (row[key] === cell) {
                imageType = key;
              }
            }
            const newWindow = window.open();
            if (imageType === "identityURL")
              newWindow.document.write(
                '<iframe width="100%" height="100%" src="data:application/pdf;base64,' +
                  base64Data +
                  '"></iframe>'
              );
            else if (imageType === "selfieURL")
              newWindow.document.write(
                '<img src="data:image/jpeg;base64,' + base64Data + '" />'
              );

            this.setState({ loading: !this.state.loading });
          }}
        >
          {first4}...{lst2}
        </a>
      );
    };

    const defaultSortedBy = [
      {
        dataField: "date",
        order: "desc",
      },
    ];

    const updateModalState = (heading, modalContent) => {
      this.setState({
        heading: heading,
        showModal: !this.state.showModal,
        modalContent: modalContent,
      });
    };

    const addManager = (rowData) => {
      const modalContent = (
        <AddManager data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Add Manager", modalContent);
    };
    const handleStakeOnLido = async (row) => {
      const lidoSubmitAbi = [
        {
          constant: false,
          inputs: [{ name: "_referral", type: "address" }],
          name: "submit",
          outputs: [{ name: "", type: "uint256" }],
          payable: true,
          stateMutability: "payable",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            {
              name: "recipient",
              type: "address",
            },
            {
              name: "amount",
              type: "uint256",
            },
          ],
          name: "transfer",
          outputs: [
            {
              name: "",
              type: "bool",
            },
          ],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
      ];

      const lidoAddress = NETWORKS_INFO[chainId]["lidoAddress"];
      const transferTopic =
        "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef".toLowerCase(); //transfer event topic

      if (lidoAddress && web3 && account) {
        const verifiedRewardAddress =
          NETWORKS_INFO[chainId]["verifiedLidoRewardAddress"];
        if (!verifiedRewardAddress) {
          toast.error(
            "Lido Reward address does not exist for this chain/network",
            ToastOptions
          );
          return;
        }
        this.setState({ loading: true });
        const lidoContract = new web3.eth.Contract(lidoSubmitAbi, lidoAddress);
        await lidoContract.methods
          .submit(verifiedRewardAddress)
          .send({ from: account, value: row.amountInvestedCurrencyFixed })
          .then(async (res) => {
            const transferEvent = Object.values(res.events).find(
              (evnt) => evnt.raw.topics[0].toLowerCase() === transferTopic
            );
            const amountReceivedInBytes = transferEvent.raw.data;
            const amountReceivedFmt = web3.utils.hexToNumberString(
              amountReceivedInBytes
            );
            toast.success("Stake Successful", ToastOptions);
            await lidoContract.methods
              .transfer(row.address, amountReceivedFmt)
              .send({ from: account })
              .then((_res) => {
                this.setState({ loading: false });
                toast.success(
                  "sETH Sent To Investor Succesfully",
                  ToastOptions
                );
              })
              .catch((_err) => {
                this.setState({ loading: false });
                console.error("Transfer Transaction failed: ", _err);
                toast.error("Transfer Transaction failed", ToastOptions);
              });
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.error("Stake Transaction failed: ", err);
            toast.error("Stake Transaction failed", ToastOptions);
          });
      } else {
        toast.error("Lido does not exist on this chain/network", ToastOptions);
        this.setState({ loading: false });
        return;
      }
    };
    const issueLiquidity = (rowData) => {
      const modalContent = (
        <AddLiquidity data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Issue Liquidity", modalContent);
    };
    const provideLiquidity = async (rowData) => {
      const vittaContract = new web3.eth.Contract(
        ERC20ABI,
        contractAddress[chainId].Vitta
      );
      const viitaBalanceUnparsed = await vittaContract.methods
        .balanceOf(account)
        .call();
      const vittaBalance = Response.parseTokenAmount(viitaBalanceUnparsed, 18);
      const modalContent = (
        <ProvideLiquidity
          data={rowData}
          platformId={platformId}
          onModalHide={handleModalHide}
        />
      );
      updateModalState("Provide Liquidity", modalContent);
    };
    const assignManager = (rowData) => {
      const modalContent = (
        <AssignManager data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Assign Manager", modalContent);
    };
    const removeManager = async (rowData) => {
      await contract
        .removeManager(platformId, rowData.managerAddress, networkOptions)
        .then((response) => {
          console.log(response);
        });
    };
    const payOut = (rowData) => {
      const modalContent = (
        <PayOut data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Pay Out", modalContent);
    };
    const collectFees = async (rowData) => {
      if (rowData.option === "security") {
        const platformAddress =
          rowData.poolType === PoolType.PrimaryIssue
            ? contractAddress[chainId].BalancerPrimaryIssueManager
            : rowData.poolType === PoolType.MarginIssue
            ? contractAddress[chainId].BalancerMarginIssueManager
            : contractAddress[chainId].BalancerSecondaryIssueManager;
        if (rowData.poolType === "PrimaryIssue") {
          this.setState({ loading: !this.state.loading });
          try {
            const tx = await contract.getIssuingFeeCollected(
              platformAddress,
              rowData.currency,
              networkOptions
            );
            if (tx.code === "ACTION_REJECTED") {
              toast.error("Transaction rejected by user!", ToastOptions);
            } else if (tx.status === 0) {
              const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
              toast.success(SuccessToast(transactionLink), ToastOptions);
            } else if (tx.status === 1) {
              toast.error("Transaction Failed", ToastOptions);
              console.log("failure: ", tx);
            }
            // await contract.methods
            //   .getIssuingFeeCollected(platformAddress, rowData.currency)
            //   .call({ from: account })
            //   .then((res) => {
            //     console.log("res: ", res);
            //   });
            this.setState({ loading: !this.state.loading });
          } catch (err) {
            let error = { err };
            console.log(error);
            this.setState({ loading: !this.state.loading });
            toast.error(FailureToast(), ToastOptions);
          }
        } else {
          this.setState({ loading: !this.state.loading });
          try {
            const tx = await contract.getTradingFeeCollected(
              platformAddress,
              rowData.currency,
              networkOptions
            );
            if (tx.code === "ACTION_REJECTED") {
              toast.error("Transaction rejected by user!", ToastOptions);
            } else if (tx.status === 0) {
              const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
              toast.success(SuccessToast(transactionLink), ToastOptions);
            } else if (tx.status === 1) {
              toast.error("Transaction Failed", ToastOptions);
              console.log("failure: ", tx);
            }
            // await contract.methods
            //   .getTradingFeeCollected(platformAddress, rowData.currency)
            //   .send({ from: account })
            //   .then((res) => {
            //     console.log("res: ", res);
            //   });
            this.setState({ loading: !this.state.loading });
          } catch (err) {
            let error = { err };
            console.log(error);
            this.setState({ loading: !this.state.loading });
            toast.error(FailureToast(), ToastOptions);
          }
        }
      } else if (rowData.option === "bond") {
        this.setState({ loading: !this.state.loading });
        try {
          const tx = await contract.getLoanFeeCollected(
            rowData.currency,
            networkOptions
          );
          if (tx.code === "ACTION_REJECTED") {
            toast.error("Transaction rejected by user!", ToastOptions);
          } else if (tx.status === 0) {
            const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
            toast.success(SuccessToast(transactionLink), ToastOptions);
          } else if (tx.status === 1) {
            toast.error("Transaction Failed", ToastOptions);
            console.log("failure: ", tx);
          }
          // await contract.methods
          //   .getLoanFeeCollected(rowData.currency)
          //   .call({ from: account })
          //   .then((res) => {
          //     console.log("res: ", res);
          //   });
          this.setState({ loading: !this.state.loading });
        } catch (err) {
          let error = { err };
          console.log(error);
          this.setState({ loading: !this.state.loading });
          toast.error(FailureToast(), ToastOptions);
        }
      } else if (rowData.option === "currency") {
        console.log("rowData", rowData, contract);
        this.setState({ loading: !this.state.loading });
        try {
          const tx = await contract.getPaymentFeeCollected(
            rowData.tokenName,
            networkOptions
          );
          if (tx.code === "ACTION_REJECTED") {
            toast.error("Transaction rejected by user!", ToastOptions);
          } else if (tx.status === 0) {
            const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
            toast.success(SuccessToast(transactionLink), ToastOptions);
          } else if (tx.status === 1) {
            toast.error("Transaction Failed", ToastOptions);
            console.log("failure: ", tx);
          }
          // await contract.methods
          //   .getPaymentFeeCollected(rowData.tokenName)
          //   .call({ from: account })
          //   .then((res) => {
          //     console.log("res: ", res);
          //   });
          this.setState({ loading: !this.state.loading });
        } catch (err) {
          let error = { err };
          console.log(error);
          this.setState({ loading: !this.state.loading });
          toast.error(FailureToast(), ToastOptions);
        }
      }
    };
    const acceeptInvestor = async (rowData) => {
      const modalContent = (
        <AcceptInvestor data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Accept Investor", modalContent);
    };
    const rejectInvestor = async (rowData) => {
      this.setState({ loading: !this.state.loading });
      try {
        const tx = await contract.reject(
          rowData.poolId,
          rowData.address,
          networkOptions
        );
        if (tx.code === "ACTION_REJECTED") {
          toast.error("Transaction rejected by user!", ToastOptions);
        } else {
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
        }
        this.setState({ loading: !this.state.loading });
      } catch (err) {
        let error = { err };
        console.log(error);
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
        this.setState({ loading: !this.state.loading });
        toast.error(FailureToast(transactionLink), ToastOptions);
      }
    };

    const setClientKYCStatus = async (rowData, status) => {
      this.setState({ loading: !this.state.loading });
      try {
        const tx = await contract.KycUpdate(
          rowData.accountAddress,
          rowData.fullName,
          rowData.applicantId,
          rowData.countryName,
          rowData.email,
          status,
          networkOptions
        );
        if (tx.code === "ACTION_REJECTED") {
          toast.error("Transaction rejected by user!", ToastOptions);
        } else {
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
        }
        this.setState({ loading: !this.state.loading });
      } catch (err) {
        let error = { err };
        console.log(error);
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
        this.setState({ loading: !this.state.loading });
        toast.error(FailureToast(transactionLink), ToastOptions);
      }
    };

    const makeCounterOffer = async (rowData) => {
      const modalContent = (
        <MakeOffer data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Make an Offer", modalContent);
    };

    const showLiquidity = async (rowData) => {
      const modalContent = (
        <LiquidityOffered data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Liquidity Offered", modalContent);
    };

    const showOffersList = async (rowData) => {
      const modalContent = (
        <OffersList data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Offers List", modalContent);
    };

    const showEarnings = async (rowData) => {
      const settlements = await Promise.all(
        rowData.map(async (data) => {
          const cashContract = new web3.eth.Contract(ERC20ABI, data.currency);
          const currencyName = await cashContract.methods.symbol().call();
          const currencyDecimals = await cashContract.methods.decimals().call();
          const underwritingFee = Response.parseTokenAmount(
            data.underwritingFee,
            currencyDecimals
          );
          const subscription = Response.parseTokenAmount(
            data.subscription,
            currencyDecimals
          );
          return {
            ...data,
            currencyName,
            underwritingFee,
            subscription,
          };
        })
      );
      const modalContent = (
        <Earnings data={settlements} onModalHide={handleModalHide} />
      );
      updateModalState("Earnings", modalContent);
    };

    const startIssue = (rowData) => {
      const modalContent = (
        <StartIssue data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Start Issue", modalContent);
    };

    const setIssuingFee = async (rowData) => {
      const modalContent = (
        <SetIssueFee data={rowData} onModalHide={handleModalHide} />
      );

      updateModalState("Set Issuing Fee", modalContent);
    };

    const viewRefunds = (rowData) => {
      const modalContent = (
        <Refunds data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Refund Details", modalContent);
    };
    const viewAllotments = (rowData) => {
      const modalContent = (
        <Allotments data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Allotment Details", modalContent);
    };
    const settleIssue = async (rowData) => {
      console.log("id....: ", rowData);
      this.setState({ loading: !this.state.loading });
      try {
        await contract
          .settle(rowData.id, networkOptions)
          .then((res) => {
            if (res?.status === 0) {
              toast.success(SuccessToast("", "Issue Settled"), ToastOptions);
            }
            if (res?.status === 1) {
              toast.error(FailureToast("", "Transaction Failed"), ToastOptions);
            }
          })
          .catch(() => {
            toast.error(FailureToast("", "Transaction Failed"), ToastOptions);
          });
        this.setState({ loading: !this.state.loading });
      } catch (e) {
        this.setState({ loading: !this.state.loading });
        console.log(e);
        toast.error(FailureToast("", "Transaction Failed"), ToastOptions);
      }
    };
    const closeIssueCall = async (rowData) => {
      this.setState({ loading: !this.state.loading });
      try {
        console.log("option: ", networkOptions);
        await contract
          .close(rowData.security, networkOptions)
          .then((res) => {
            if (res?.status === 0) {
              toast.success(SuccessToast("", "Issue Closed"), ToastOptions);
            }
            if (res?.status === 1) {
              toast.error(FailureToast("", "Transaction Failed"), ToastOptions);
            }
          })
          .catch(() => {
            toast.error(FailureToast("", "Transaction Failed"), ToastOptions);
          });
        this.setState({ loading: !this.state.loading });
      } catch (err) {
        console.log("EErr", err);
        this.setState({ loading: !this.state.loading });
        toast.error(FailureToast("", "Transaction Failed"), ToastOptions);
      }
    };
    const scheduleSnapshot = async (rowData) => {
      const modalContent = (
        <ScheduleSnapshot data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Schedule snapshot", modalContent);
    };
    const rejectionReasonCheck = async (rowData) => {
      const applicantId = rowData.applicantId;
      const response = await axios
        .get(`${config.sumsubEndpoint}?applicantId=${applicantId}`)
        .then((response) => {
          return response.data;
        });

      const modalContent = (
        <KYCRejectionCheck
          data={rowData}
          response={response}
          onModalHide={handleModalHide}
        />
      );
      updateModalState("Rejection Reason", modalContent);
    };
    const recheckApplication = async (rowData) => {
      const applicantId = rowData.applicantId;
      this.setState({ loading: !this.state.loading });
      const response = await axios
        .get(
          `${config.approvalEndpoint}?applicantId=${applicantId}&status=recheck`
        )
        .then((response) => {
          return response.data;
        });
      console.log("response", response);
      if (response.response.ok) {
        this.setState({ loading: !this.state.loading });
        toast.success(
          SuccessToast("", "Application recheck initiated!!!"),
          ToastOptions
        );
      } else {
        this.setState({ loading: !this.state.loading });
        toast.error(
          FailureToast("", "Application recheck failed!!!"),
          ToastOptions
        );
      }
    };

    const rescheduleSnapshot = async (rowData) => {
      const securityTokenContract = new web3.eth.Contract(
        ERC20ABI,
        rowData.security
      );
      const snapShots = await securityTokenContract.methods
        .getNextSnapshots()
        .call();
      const modalContent = (
        <RescheduleSnapshot
          data={rowData}
          snapShots={snapShots}
          onModalHide={handleModalHide}
        />
      );
      updateModalState("Reschedule snapshot", modalContent);
    };
    const unscheduleSnapshot = async (rowData) => {
      const securityTokenContract = new web3.eth.Contract(
        ERC20ABI,
        rowData.security
      );
      const snapShots = await securityTokenContract.methods
        .getNextSnapshots()
        .call();
      const modalContent = (
        <UnscheduleSnapshot
          data={rowData}
          snapShots={snapShots}
          onModalHide={handleModalHide}
        />
      );
      updateModalState("Unschedule snapshot", modalContent);
    };
    const scheduleResolution = async (rowData) => {
      const securityTokenContract = new web3.eth.Contract(
        ERC20ABI,
        rowData.security
      );
      const snapShots = await securityTokenContract.methods
        .getNextSnapshots()
        .call();
      const modalContent = (
        <ScheduleResolution
          data={rowData}
          snapShots={snapShots}
          onModalHide={handleModalHide}
        />
      );
      updateModalState("Schedule resolution", modalContent);
    };
    const schedulePayout = async (rowData) => {
      const securityTokenContract = new web3.eth.Contract(
        ERC20ABI,
        rowData.security
      );
      const snapShots = await securityTokenContract.methods
        .getNextSnapshots()
        .call();
      const modalContent = (
        <SchedulePayout
          data={rowData}
          snapShots={snapShots}
          onModalHide={handleModalHide}
        />
      );
      updateModalState("Schedule payout", modalContent);
    };
    const issueSecondary = async (rowData) => {
      const modalContent = (
        <AddOffer
          data={rowData}
          existingIssue={true}
          onIssuedModalHide={handleModalHide}
        />
      );
      updateModalState("Issue Secondary", modalContent);
    };
    const withdrawSubscription = async (rowData) => {
      // const modalContent = <AddOffer data={rowData} existingIssue={true} onIssuedModalHide={handleModalHide} />;
      // updateModalState("Withdraw subscription", modalContent);
    };
    const scheduleInvestorPayout = async (rowData) => {
      const securityTokenContract = new web3.eth.Contract(
        ERC20ABI,
        rowData.securityAddress
      );
      const snapShots = await securityTokenContract.methods
        .getNextSnapshots()
        .call();
      const modalContent = (
        <ScheduleInvestorPayout
          data={rowData}
          snapShots={snapShots}
          onModalHide={handleModalHide}
        />
      );
      updateModalState("Schedule investor payout", modalContent);
    };
    const getScheduledSnapshots = async (rowData) => {
      const securityTokenContract = new web3.eth.Contract(
        ERC20ABI,
        rowData.security
      );
      const snapShots = await securityTokenContract.methods
        .getNextSnapshots()
        .call();
      const modalContent = (
        <SnapshotList
          data={rowData}
          snapShots={snapShots}
          onModalHide={handleModalHide}
        />
      );
      updateModalState("Snapshot List", modalContent);
    };
    const extinguishSecurities = async (rowData) => {
      const securityTokenContract = new web3.eth.Contract(
        ERC20ABI,
        rowData.security
      );
      let dateTime = Math.floor(new Date().getTime() / 1000).toString();
      const snapshotTotalSupply = await securityTokenContract.methods
        .snapshotTotalSupply(dateTime)
        .call();
      if (Number(snapshotTotalSupply) !== 0) {
        this.setState({ loading: !this.state.loading });
        try {
          await securityTokenContract.methods.burnAll().send({ from: account });
          toast.success(SuccessToast("", "Burnt all token"), ToastOptions);
          this.setState({ loading: !this.state.loading });
        } catch (e) {
          this.setState({ loading: !this.state.loading });
          toast.error("Transaction rejected by user!", ToastOptions);
          console.log("Error", e);
        }
      } else {
        toast.error("No float available", ToastOptions);
      }
    };
    const getFloatingSecurities = async (rowData) => {
      const modalContent = (
        <FloatingSecurities data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Total Supply", modalContent);
    };
    const getInvestorSnapshot = async (rowData) => {
      const modalContent = (
        <InvestorSnapshot data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Securities held", modalContent);
    };
    const burnSecurityInvestor = async (rowData) => {
      const modalContent = (
        <BurnInvestorSecurity data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Burn Security", modalContent);
    };
    const freezeInvestor = async (rowData, freeze) => {
      const securityTokenContract = new web3.eth.Contract(
        ERC20ABI,
        rowData.securityAddress
      );
      if (freeze) {
        const modalContent = (
          <FreezeInvestor data={rowData} onModalHide={handleModalHide} />
        );
        updateModalState("Freeze", modalContent);
      } else {
        try {
          await securityTokenContract.methods
            .unfreeze(rowData.transferor)
            .call();
        } catch (e) {
          console.log(e);
        }
      }
    };
    const pauseSecurity = async (rowData, pause) => {
      const securityTokenContract = new web3.eth.Contract(
        ERC20ABI,
        rowData.security
      );
      if (pause) {
        this.setState({ loading: !this.state.loading });
        try {
          await securityTokenContract.methods.pause().send({ from: account });
          toast.success(SuccessToast("", "Issue paused!"), ToastOptions);
          this.setState({ loading: !this.state.loading });
          updateTableData(!isUpdate);
        } catch (e) {
          this.setState({ loading: !this.state.loading });
          console.log(e);
          toast.error("Transaction rejected by user!", ToastOptions);
        }
      } else {
        this.setState({ loading: !this.state.loading });
        try {
          await securityTokenContract.methods.unpause().send({ from: account });
          toast.success(SuccessToast("", "Issue Unpaused!"), ToastOptions);
          this.setState({ loading: !this.state.loading });
        } catch (e) {
          this.setState({ loading: !this.state.loading });
          console.log(e);
          toast.error("Transaction rejected by user!", ToastOptions);
        }
      }
    };

    const homeCallAction = (cell, row, rowIndex, formatExtraData) => {
      if (row.poolType === "PrimaryIssue") {
        return (
          <DropdownButton id="dropdown-basic-button" title={"Action"}>
            {!row.verifiedWalletData?.subscriptionsClosed.length ? (
              <Dropdown.Item
                onClick={() => {
                  closeIssueCall(row);
                }}
              >
                Close
              </Dropdown.Item>
            ) : null}
          </DropdownButton>
        );
      }
    };

    const handleReclaimCollateral = async (bondTokenAddress) => {
      if (bondTokenAddress) {
        commonProps.updateIsLoading(true);
        const bondERC20Contract = new ERC20(signer, bondTokenAddress);
        const bondTokenContract = new Token(signer, bondTokenAddress);
        const issuerBalance = await bondERC20Contract
          .balanceOf(account)
          .then((res) => {
            return res?.response?.result[0];
          });
        if (issuerBalance > 0) {
          await bondTokenContract
            .transferFrom(account, bondTokenAddress, issuerBalance.toString())
            .then((res) => {
              if (res?.status === 0) {
                console.log(
                  "Successful transfer from transaction with hash: ",
                  res?.response?.hash
                );
                toast.success("Bond Reclaimed succesfully", ToastOptions);
                //toast here
              } else {
                res && res.message
                  ? console.error("Error from transferFrom: ", res.message)
                  : //Todo: toast here
                    console.error(
                      "Error from transferFrom: Transaction Failed"
                    );
                //Todo: toast here
                toast.error("Transaction failed", ToastOptions);
              }
              commonProps.updateIsLoading(false);
            });
        } else {
          console.error("Bond Token balance is 0");
          toast.error("Insufficient Bond Balance", ToastOptions);
          commonProps.updateIsLoading(false);
        }
      } else {
        console.error("Bond token Address does not exist");
        toast.error("Transaction failed", ToastOptions);
      }
    };

    const borrowerAction = (cell, row, rowIndex) => {
      return (
        <DropdownButton id="dropdown-basic-button" title={"Action"}>
          {Number(row.SoldValue) > 0 && (
            <Dropdown.Item
              onClick={() => {
                commonProps.updateAsset(row.BondTokenAddress);
                commonProps.updateActionType("Borrow");
                commonProps.updateModalView(2);
                commonProps.updateShowModal(true);
              }}
            >
              Borrow
            </Dropdown.Item>
          )}

          {Number(row.Borrowed) > 0 && (
            <Dropdown.Item
              onClick={() => {
                console.log("row: ", row);
                commonProps.updateAsset(row.BondTokenAddress);
                commonProps.updateBondToken(row.Asset);
                commonProps.updateActionType("Repay Loan");
                commonProps.updateModalView(2);
                commonProps.updateShowModal(true);
              }}
            >
              Repay Loan
            </Dropdown.Item>
          )}

          {Number(row.CollateralPosted) > Number(row.SoldValue) && (
            <Dropdown.Item
              onClick={async () => {
                await handleReclaimCollateral(row.BondTokenAddress);
              }}
            >
              Reclaim Collateral
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => {
              commonProps.updateIssuerDetails({
                address: row.IssuerAddress,
                name: row.Issuer,
                country: row.IssuerCountry,
                borrowed: row.Borrowed,
                repaid: row.Repaid,
                totalIssues: row.IssuerTotalIssues,
                totalIssuesBorrowed: row.IssuerTotalIssuesBorrowed,
                totalIssuesRepaid: row.IssuerTotalIssuesRepaid,
              });
              commonProps.updateModalView(3);
              commonProps.updateShowModal(true);
            }}
          >
            Issuer Details
          </Dropdown.Item>
        </DropdownButton>
      );
    };

    const handleLiquidateCollateral = async (bondTokenAddress) => {
      if (bondTokenAddress) {
        commonProps.updateIsLoading(true);
        const bondERC20Contract = new ERC20(signer, bondTokenAddress);
        const bondTokenContract = new Token(signer, bondTokenAddress);
        const investorBalance = await bondERC20Contract
          .balanceOf(account)
          .then((res) => {
            console.log("res:", res.response.result);
            return res.response.result[0];
          });
        if (Number(investorBalance) > 0) {
          commonProps.updateIsLoading(true);
          const tokenDecimals = await bondERC20Contract
            .decimals()
            .then((res) => {
              return Number(res.response.result);
            });
          console.log("balance: ", investorBalance.toString());
          await bondTokenContract
            .transferFrom(account, bondTokenAddress, investorBalance)
            .then((res) => {
              if (
                res &&
                res.status === 0 &&
                res.response &&
                res.response.hash
              ) {
                console.log(
                  "Successful transfer from transaction with hash: ",
                  res.response.hash
                );
                toast.success("Bond Liquidated succesfully", ToastOptions);
                //toast here
              } else {
                res && res.message
                  ? console.error("Error from transferFrom: ", res.message)
                  : //Todo: toast here
                    console.error(
                      "Error from transferFrom: Transaction Failed"
                    );
                //Todo: toast here
                toast.error("Transaction failed", ToastOptions);
              }
              commonProps.updateIsLoading(false);
            });
        } else {
          console.error("Bond Token balance is 0");
          toast.error("Insufficient Bond Balance", ToastOptions);
          commonProps.updateIsLoading(false);
        }
      } else {
        console.error("Bond token Address does not exist");
        toast.error("Transaction failed", ToastOptions);
      }
    };

    const lenderAction = (cell, row, rowIndex) => {
      return (
        <DropdownButton id="dropdown-basic-button" title={"Action"}>
          <Dropdown.Item
            onClick={() => {
              commonProps.updateAsset(row.BondTokenAddress);
              commonProps.updateBondToken(row.Asset);
              commonProps.updateActionType("Provide Collateral");
              commonProps.updateModalView(2);
              commonProps.updateShowModal(true);
            }}
          >
            Provide Collateral
          </Dropdown.Item>

          <Dropdown.Item
            onClick={async () => {
              // console.log("bond: ", row.BondTokenAddress);
              await handleLiquidateCollateral(row.BondTokenAddress);
            }}
          >
            Liquidate Collateral
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              commonProps.updateIssuerDetails({
                address: row.IssuerAddress,
                name: row.Issuer,
                country: row.IssuerCountry,
                borrowed: row.Borrowed,
                repaid: row.Repaid,
                totalIssues: row.IssuerTotalIssues,
                totalIssuesBorrowed: row.IssuerTotalIssuesBorrowed,
                totalIssuesRepaid: row.IssuerTotalIssuesRepaid,
              });
              commonProps.updateModalView(3);
              commonProps.updateShowModal(true);
            }}
          >
            Issuer Details
          </Dropdown.Item>
        </DropdownButton>
      );
    };

    const managerEarnings = (cell, row, rowIndex, formatExtraData) => {
      if (row.verifiedWalletData?.primarySettlements) {
        return (
          <DropdownButton id="dropdown-basic-button" title={"Action"}>
            <Dropdown.Item
              key="providers"
              onClick={() => {
                showEarnings(row.verifiedWalletData?.primarySettlements);
              }}
            >
              Show earnings
            </Dropdown.Item>
          </DropdownButton>
        );
      }
    };

    const adminIssueCallAction = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            collectFees(row);
          }}
        >
          Collect Fees
        </Button>
      );
    };

    const displayOfferDetails = (row) => {
      const elements = [];
      if (!row.activeIssue) {
        elements.push(
          <Dropdown.Item
            key="offer"
            onClick={() => {
              makeCounterOffer(row);
            }}
          >
            Make an Offer
          </Dropdown.Item>
        );
      }
      if (row.liquidityProviders.length) {
        elements.push(
          <Dropdown.Item
            key="offered"
            onClick={() => {
              showLiquidity(row);
            }}
          >
            View liquidity provided
          </Dropdown.Item>
        );
      }
      if (row.liquidityOffered.length) {
        elements.push(
          <Dropdown.Item
            key="providers"
            onClick={() => {
              showOffersList(row);
            }}
          >
            Show other offers
          </Dropdown.Item>
        );
      }
      return (
        <>
          <DropdownButton id="dropdown-basic-button" title={"Action"}>
            {elements}
          </DropdownButton>
        </>
      );
    };

    const offerCallAction = (cell, row, rowIndex, formatExtraData) => {
      if (userRole === "DP") {
        return (
          <>
            <DropdownButton id="dropdown-basic-button" title={"Action"}>
              <Dropdown.Item
                onClick={async () => {
                  await setIssuingFee(row);
                }}
              >
                Set Issue Fee
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  startIssue(row);
                }}
              >
                Start
              </Dropdown.Item>
            </DropdownButton>
          </>
        );
      } else {
        if (!row.subscriptionsClosed.length) {
          return displayOfferDetails(row);
        }
      }
    };

    const primaryInvestorCallAction = (
      cell,
      row,
      rowIndex,
      formatExtraData
    ) => {
      if (!row.poolClosed) {
        return null;
      } else if (
        row.allotments?.length ||
        row.refunds?.length ||
        row.poolSettled
      ) {
        return (
          <DropdownButton id="dropdown-basic-button" title={"Action"}>
            {row.allotments?.length ? (
              <Dropdown.Item
                onClick={() => {
                  viewAllotments(row);
                }}
              >
                View Allotments
              </Dropdown.Item>
            ) : null}
            {row.refunds?.length ? (
              <Dropdown.Item
                onClick={() => {
                  viewRefunds(row);
                }}
              >
                View Refunds
              </Dropdown.Item>
            ) : null}
            {row.frozen ? (
              <Dropdown.Item
                onClick={() => {
                  freezeInvestor(row, false);
                }}
              >
                Unfreeze
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={() => {
                  freezeInvestor(row, true);
                }}
              >
                Freeze
              </Dropdown.Item>
            )}
            <Dropdown.Item
              onClick={() => {
                burnSecurityInvestor(row);
              }}
            >
              Burn
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                scheduleInvestorPayout(row);
              }}
            >
              Schedule payout
            </Dropdown.Item>
          </DropdownButton>
        );
      } else {
        return (
          <DropdownButton id="dropdown-basic-button" title={"Action"}>
            <Dropdown.Item
              onClick={() => {
                acceeptInvestor(row);
              }}
            >
              Accept
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                rejectInvestor(row);
              }}
            >
              Reject
            </Dropdown.Item>
          </DropdownButton>
        );
      }
    };

    const secondaryInvestorCallAction = (
      cell,
      row,
      rowIndex,
      formatExtraData
    ) => {
      return (
        <DropdownButton id="dropdown-basic-button" title={"Action"}>
          {row.frozen ? (
            <Dropdown.Item
              onClick={() => {
                freezeInvestor(row, false);
              }}
            >
              Unfreeze
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              onClick={() => {
                freezeInvestor(row, true);
              }}
            >
              Freeze
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => {
              burnSecurityInvestor(row);
            }}
          >
            Burn
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              scheduleInvestorPayout(row);
            }}
          >
            Schedule payout
          </Dropdown.Item>
        </DropdownButton>
      );
    };

    const platformCallAction = (cell, row, rowIndex, formatExtraData) => {
      return (
        <DropdownButton id="dropdown-basic-button" title={"Action"}>
          <Dropdown.Item
            onClick={() => {
              addManager(row);
            }}
          >
            Add Manager
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              payOut(row);
            }}
          >
            Pay Out
          </Dropdown.Item>
        </DropdownButton>
      );
    };

    const platformDetailCallAction = (cell, row, rowIndex, formatExtraData) => {
      return (
        <DropdownButton id="dropdown-basic-button" title={"Action"}>
          <Dropdown.Item
            onClick={() => {
              provideLiquidity(row);
            }}
          >
            Provide Liquidity
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              removeManager(row);
            }}
          >
            Remove Manager
          </Dropdown.Item>
        </DropdownButton>
      );
    };

    const removeRole = async (rowData) => {
      try {
        this.setState({ loading: !this.state.loading });
        const hashData = await signMessage(signer);
        const tx = await contract.removeRole(
          NULL_ADDRESS,
          rowData.address,
          rowData.country,
          rowData.role,
          hashData.messageHash,
          hashData.v,
          hashData.r,
          hashData.s,
          networkOptions
        );
        if (tx.code === "ACTION_REJECTED") {
          toast.error("Transaction rejected by user!", ToastOptions);
        } else {
          toast.success(
            SuccessToast("", "Role removed successfully"),
            ToastOptions
          );
        }
        this.setState({ loading: !this.state.loading });
      } catch (e) {
        this.setState({ loading: !this.state.loading });
        console.log(e);
        toast.error(FailureToast("", "Transaction Failed"), ToastOptions);
      }
    };

    const resolutionVoting = async (rowData) => {
      const modalContent = (
        <InvestorVoting data={rowData} onModalHide={handleModalHide} />
      );
      updateModalState("Cast Vote", modalContent);
    };

    const callSwap = async (rowData) => {
      const {
        poolId,
        tokensList,
        userData,
        assestINIndex,
        assestOUTIndex,
        amount,
        approvalAmount,
        managerApprovalAmount,
        approvalContract,
        managerApprovalContract,
        limitArr,
      } = rowData;
      const encodedBatchSwapData = Swaps.encodeBatchSwap({
        kind: 0,
        swaps: [
          {
            poolId: poolId,
            assetInIndex: assestINIndex,
            assetOutIndex: assestOUTIndex,
            amount: amount,
            userData: userData,
          },
        ],
        assets: tokensList,
        funds: {
          fromInternalBalance: false,
          recipient: account,
          sender: account,
          toInternalBalance: false,
        },
        limits: limitArr,
        deadline: "999999999999999999", // Infinity
      });

      const tx = {
        from: account,
        to: balancerVault,
        data: encodedBatchSwapData,
        gasLimit: "2000000",
        ...(chainId === 137 ? networkOptions : {}),
      };
      this.setState({ loading: !this.state.loading });
      const approvalAllowance = Number(
        await approvalContract.methods.allowance(account, balancerVault).call()
      );
      if (approvalAllowance < Number(approvalAmount)) {
        await approvalContract.methods
          .approve(balancerVault, approvalAmount)
          .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
          .then(async (res) => {});
      }

      // Code for Approval to secondaryIssueManager to reject back tokens
      const ManagerApprovalAllowance = Number(
        await managerApprovalContract.methods
          .allowance(account, secondaryIssueManager)
          .call()
      );
      if (ManagerApprovalAllowance < Number(managerApprovalAmount)) {
        await managerApprovalContract.methods
          .approve(secondaryIssueManager, managerApprovalAmount.toString())
          .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
          .then(async (res) => {});
      }

      let receivingTokenSymbol = await managerApprovalContract.methods
        .symbol()
        .call();
      const receivingTokenDecimals = await managerApprovalContract.methods
        .decimals()
        .call();
      const balanceReceivingToken = await managerApprovalContract.methods
        .balanceOf(account)
        .call();

      receivingTokenSymbol = receivingTokenSymbol.replace(/\0/g, "");

      let transactionData = await provider
        .getSigner(account)
        .sendTransaction(tx);
      console.log(transactionData);
      try {
        const pendingTransaction = await transactionData.wait();
        console.log(
          "Transaction Mined!!! Join Pool Vault.",
          pendingTransaction
        );
        // Code to add receiving token
        if (
          Number(balanceReceivingToken) <= 0 &&
          provider &&
          provider?.request
        ) {
          await provider
            .request({
              method: "wallet_watchAsset",
              params: {
                type: "ERC20",
                options: {
                  address: managerApprovalContract._address,
                  symbol: receivingTokenSymbol.substring(0, 10),
                  decimals: receivingTokenDecimals,
                },
              },
            })
            .catch(() => {
              this.setState({ loading: !this.state.loading });
            });
        }
        this.setState({ loading: false });
        toast.success(SuccessToast("", "Transaction Success!"), ToastOptions);
        // window.location.reload();
      } catch (error) {
        this.setState({ loading: false });
        toast.error("Transaction Failed!", ToastOptions);
        console.log("Failure!", tx);
        return;
      }
    };

    const editTrade = async (rowData) => {
      if (transport) {
        const web3 = getWeb3(transport);
        const currencyTokenContract = new web3.eth.Contract(
          ERC20ABI,
          rowData.currency
        );
        let currencyBalance = await currencyTokenContract.methods
          .balanceOf(account)
          .call();
        const currencyDecimals = await currencyTokenContract.methods
          .decimals()
          .call();
        const securityTokenContract = new web3.eth.Contract(
          ERC20ABI,
          rowData.security
        );
        const securityDecimals = await securityTokenContract.methods
          .decimals()
          .call();
        let securityBalance = await securityTokenContract.methods
          .balanceOf(account)
          .call();
        currencyBalance = Response.parseTokenAmount(
          currencyBalance,
          currencyDecimals
        );
        securityBalance = Response.parseTokenAmount(
          securityBalance,
          securityDecimals
        );
        const modalContent = (
          <EditOrder
            data={{ ...rowData, currencyBalance, securityBalance }}
            onModalHide={handleModalHide}
          />
        );
        updateModalState("Edit Order", modalContent);
      } else {
        console.log("no transport...");
      }
    };

    const closeSecondaryIssue = async (rowData, networkOptions) => {
      this.setState({ loading: !this.state.loading });
      try {
        const tx = await contract.close(rowData.id, networkOptions);
        if (tx.code === "ACTION_REJECTED") {
          toast.error("Transaction rejected by user!", ToastOptions);
        } else {
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
        }
        this.setState({ loading: !this.state.loading });
      } catch (err) {
        let error = { err };
        console.log(error);
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
        this.setState({ loading: !this.state.loading });
        toast.error(FailureToast(transactionLink), ToastOptions);
      }
    };

    const closeMarginIssue = async (security) => {
      this.setState({ loading: true });
      try {
        // console.log("seec: ", security, "cont: ", contract);
        const tx = await contract.close(security);
        console.log("tx: ", tx);
        if (tx.code === "ACTION_REJECTED") {
          toast.error("Transaction rejected by user!", ToastOptions);
        } else {
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
        }
        this.setState({ loading: false });
      } catch (err) {
        let error = { err };
        console.log(error);
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
        this.setState({ loading: false });
        toast.error(FailureToast(transactionLink), ToastOptions);
      }
    };

    const withdrawCollateral = async (rowData) => {
      if (transport) {
        this.state.loading = true;
        const web3 = getWeb3(transport);
        const currencyTokenContract = new web3.eth.Contract(
          ERC20ABI,
          rowData.currencyAddress
        );
        const currencyDecimals = await currencyTokenContract.methods
          .decimals()
          .call();
        const security = "";
        const amount = ethers.utils.parseUnits(
          rowData.balance.toString(),
          Number(currencyDecimals)
        );
        await contract
          .withdraw(security, rowData.currencyAddress, amount.toString())
          .then((res) => {
            if (res?.status === 0) {
              this.state.loading = false;
              toast.success(SuccessToast(), ToastOptions);
            }
          })
          .catch((err) => {
            this.state.loading = false;
            toast.error(FailureToast(), ToastOptions);
            console.log("withdraw failed with error: ", err);
          });
      } else {
        console.log("no transport...");
      }
    };

    const viewOrderBook = (row) => {
      this.props.history.push(`/pool/${row.poolType}/${row.id || row.address}`);
    };

    const cancelTrade = async (rowData) => {
      if (transport) {
        const web3 = getWeb3(transport);
        const {
          orderType,
          security,
          currency,
          poolId,
          poolAddress,
          orderRef,
          amount,
        } = rowData;
        const tokensList = [poolAddress, security, currency];
        const abiCoder = new ethers.utils.AbiCoder();
        const limitArr = new Array(tokensList.length).fill(0);
        limitArr[tokensList.indexOf(poolAddress)] = ethers.utils
          .parseEther(amount.toString())
          .toString();
        const assetOutAddress = orderType === "Sell" ? security : currency;
        const encodedBatchSwapData = Swaps.encodeBatchSwap({
          kind: 0,
          swaps: [
            {
              poolId: poolId,
              assetInIndex: tokensList.indexOf(poolAddress),
              assetOutIndex: tokensList.indexOf(assetOutAddress),
              amount: ethers.utils.parseEther(amount.toString()),
              userData: abiCoder.encode(["bytes32", "uint"], [orderRef, 0]),
            },
          ],
          assets: tokensList,
          funds: {
            fromInternalBalance: false,
            recipient: account,
            sender: account,
            toInternalBalance: false,
          },
          limits: limitArr,
          deadline: "999999999999999999", // Infinity
        });

        const tx = {
          from: account,
          to: balancerVault,
          data: encodedBatchSwapData,
          gasLimit: "8721975",
        };

        const sendingTokenContract = new web3.eth.Contract(
          ERC20ABI,
          poolAddress
        );
        this.setState({ loading: !this.state.loading });
        await sendingTokenContract.methods
          .approve(
            balancerVault,
            ethers.utils.parseEther(amount.toString()).toString()
          )
          .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
          .then(async (res) => {});
        try {
          let transactionData = await provider
            .getSigner(account)
            .sendTransaction(tx);
          console.log(transactionData);
          const pendingTransaction = await transactionData.wait();
          if (pendingTransaction?.transactionHash) {
            console.log(
              "Transaction Mined!!! Join Pool Vault.",
              pendingTransaction
            );
            const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${pendingTransaction.transactionHash}`;
            toast.success(SuccessToast(transactionLink), ToastOptions);
          } else {
            toast.error("Transaction Failed!", ToastOptions);
          }
          this.setState({ loading: !this.state.loading });
        } catch (err) {
          let error = { err };
          console.log(error);
          this.setState({ loading: !this.state.loading });
          toast.error(FailureToast(), ToastOptions);
        }
      }
    };

    const handleCancelTrade = async (rowData) => {
      if (transport) {
        const web3 = getWeb3(transport);
        const vaultContract = new web3.eth.Contract(Vault.abi, balancerVault);
        const poolContract = new web3.eth.Contract(
          MargingPool.abi,
          rowData.poolAddress
        );
        const amountToSend = web3.utils.toWei(rowData.amount, "ether");
        let tokenOut;
        rowData.orderType === "Sell"
          ? (tokenOut = rowData.security)
          : (tokenOut = rowData.currency);
        const poolId = await poolContract.methods.getPoolId().call();
        this.setState({ loading: true });
        let isApproved;
        await new web3.eth.Contract(ERC20ABI, rowData.poolAddress).methods
          .approve(balancerVault, amountToSend)
          .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
          .then((res) => {
            isApproved = true;
            const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${res.transactionHash}`;
            toast.success(
              SuccessToast(transactionLink, "Token approved succesfully"),
              ToastOptions
            );
          })
          .catch((err) => {
            this.setState({ loading: false });
            err.message.includes("User denied transaction")
              ? toast.error("User Rejected Transaction", ToastOptions)
              : toast.error(`Transactin to approve token failed`, ToastOptions);
          });
        if (isApproved) {
          await vaultContract.methods
            .swap(
              {
                poolId: poolId,
                kind: 0,
                assetIn: rowData.poolAddress,
                assetOut: tokenOut,
                amount: amountToSend,
                userData: web3.eth.abi.encodeParameters(
                  ["bytes32", "uint256"],
                  [rowData.orderRef, 0n]
                ),
              },
              {
                sender: account,
                fromInternalBalance: false,
                recipient: account,
                toInternalBalance: false,
              },
              0n,
              MaxUint256
            )
            .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
            .then((res) => {
              this.setState({ loading: false });
              const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${res.transactionHash}`;
              toast.success(
                SuccessToast(transactionLink, "Order cancelled succesfully"),
                ToastOptions
              );
            })
            .catch((err) => {
              this.setState({ loading: false });
              err.message.includes("User denied transaction")
                ? toast.error("User Rejected Transaction", ToastOptions)
                : toast.error(
                    `Transactin to cancel order failed`,
                    ToastOptions
                  );
            });
        }
      }
    };

    const handleClaimTrade = async (data) => {
      const {
        poolId,
        tokenIn,
        oppositeToken,
        tokenOutSymbol,
        tokenOutDecimals,
        poolAddress,
        units,
        qtyRaw,
        curAmount,
        orderType,
        tradeAmount,
        date,
      } = data;
      if (transport) {
        const web3 = getWeb3(transport);
        const poolTokenContract = new web3.eth.Contract(ERC20ABI, poolAddress);
        this.setState({ loading: true });
        let isApproved = false;
        let amt;
        let approvalAmt;
        orderType.toLowerCase() === "sell"
          ? (amt = units)
          : (amt = tradeAmount);
        orderType.toLowerCase() === "sell"
          ? (approvalAmt = qtyRaw)
          : (approvalAmt = curAmount);
        //TODO: change approve amount to 18 decimals??
        const poolTokenAllowance = Number(
          await poolTokenContract.methods
            .allowance(account, balancerVault)
            .call()
        );
        if (poolTokenAllowance < Number(amt)) {
          await poolTokenContract.methods
            .approve(balancerVault, amt)
            .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
            .then((res) => {
              isApproved = true;
            })
            .catch((err) => {
              this.setState({ loading: false });
              err.message.includes("User denied transaction")
                ? toast.error("User Rejected Transaction", ToastOptions)
                : err.message.includes("was not mined within 50 blocks")
                ? toast.warn(
                    "Approved transaction taking longer than expected please wait...",
                    ToastOptions
                  )
                : toast.error(
                    `Transactin to approve ${tokenOutSymbol} token failed`,
                    ToastOptions
                  );
            });
        } else {
          isApproved = true;
        }

        if (isApproved) {
          const oppositeTokenBalance = Number(
            await new web3.eth.Contract(ERC20ABI, oppositeToken).methods
              .balanceOf(account)
              .call()
          );
          const tokenList = [poolAddress, tokenIn, oppositeToken];
          const assetInIndex = 0;
          const assetOutIndex = 2;
          const userData = web3.eth.abi.encodeParameters(
            ["bytes32", "uint256"],
            [
              ethers.utils.formatBytes32String(""), //empty string to triger claim
              date, //tp is timestamp of trade
            ]
          );
          let limitArr = new Array(3).fill(0n);
          limitArr[assetInIndex] = units; //update limit for assest in
          const encodedBatchSwapData = Swaps.encodeBatchSwap({
            kind: 0,
            swaps: [
              {
                poolId: poolId,
                assetInIndex: assetInIndex,
                assetOutIndex: assetOutIndex,
                amount: amt,
                userData: userData,
              },
            ],
            assets: tokenList,
            funds: {
              fromInternalBalance: false,
              recipient: account,
              sender: account,
              toInternalBalance: false,
            },
            limits: limitArr,
            deadline: "999999999999999999", // Infinity
          });
          const tx = {
            from: account,
            to: balancerVault,
            data: encodedBatchSwapData,
            gasLimit: "8721975",
            ...(chainId === 137 ? networkOptions : {}),
          };
          try {
            const transactionData = await provider
              .getSigner(account)
              .sendTransaction(tx);
            const pendingTransaction = await transactionData.wait();
            if (pendingTransaction?.transactionHash) {
              if (oppositeTokenBalance <= 0 && provider && provider?.request) {
                await provider.request({
                  method: "wallet_watchAsset",
                  params: {
                    type: "ERC20",
                    options: {
                      address: oppositeToken,
                      symbol: tokenOutSymbol,
                      decimals: tokenOutDecimals,
                    },
                  },
                });
              }
              this.setState({ loading: false });
              const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${pendingTransaction.transactionHash}`;
              toast.success(
                SuccessToast(transactionLink, "Token claimed Succesfully"),
                ToastOptions
              );
            }
          } catch (err) {
            this.setState({ loading: false });
            err.message.includes("user rejected transaction")
              ? toast.error("User Rejected Transaction", ToastOptions)
              : toast.error(`Transactin to claim token failed`, ToastOptions);
          }
        }
      }
    };

    const claimTrade = (cell, row) => {
      if (row.status === "Confirm") {
        return (
          <span>
            <b>Settled</b>
          </span>
        );
      } else if (row.status === "Reject") {
        return (
          <span>
            <b>Failed</b>
          </span>
        );
      } else if (row.status === "Claimed") {
        return (
          <span>
            <b>Claimed</b>
          </span>
        );
      } else {
        return row.claimTrade ? (
          <Button
            onClick={() => {
              if (row.poolType === "MarginIssue") {
                handleClaimTrade(row);
              } else {
                callSwap(row);
              }
            }}
          >
            Claim
          </Button>
        ) : (
          <span>
            <b>Claimed</b>
          </span>
        );
      }
    };

    const voteResolution = (cell, row) => {
      return row.isVotable === "Yes" && !row.votingDisabled ? (
        <Button
          onClick={() => {
            resolutionVoting(row);
          }}
        >
          Vote
        </Button>
      ) : null;
    };

    const setKYCStatus = (cell, row) => {
      return (
        <DropdownButton id="dropdown-basic-button" title={"Action"}>
          <Dropdown.Item
            onClick={() => {
              rejectionReasonCheck(row);
            }}
          >
            View Reason
          </Dropdown.Item>
          {row.applicantStatus === "Rejected" ? (
            <Dropdown.Item
              onClick={() => {
                recheckApplication(row);
              }}
            >
              Recheck application
            </Dropdown.Item>
          ) : null}
          <Dropdown.Item
            onClick={() => {
              setClientKYCStatus(row, "2");
            }}
          >
            Reject
          </Dropdown.Item>
        </DropdownButton>
      );
    };

    const modifyTradeAction = (cell, row, rowIndex, formatExtraData) => {
      if (row.isPrimary) {
        return (
          <span>
            <b>{row.status}</b>
          </span>
        );
      } else if (row.status === "Cancelled") {
        return (
          <span>
            <b>Cancelled</b>
          </span>
        );
      } else if (row.tradeMatched) {
        return (
          <span>
            <b>Matched</b>
          </span>
        );
      } else if (!row.tradeMatched) {
        return (
          <DropdownButton id="dropdown-basic-button" title={"Action"}>
            {!row.marketOrder ? (
              <Dropdown.Item
                onClick={() => {
                  editTrade(row);
                }}
              >
                Edit
              </Dropdown.Item>
            ) : null}
            <Dropdown.Item
              onClick={() => {
                cancelTrade(row);
              }}
            >
              Cancel
            </Dropdown.Item>
          </DropdownButton>
        );
      }
    };

    const collateralAction = (cell, row, rowIndex, formatExtraData) => {
      return (
        <DropdownButton id="dropdown-basic-button" title={"Action"}>
          <Dropdown.Item
            onClick={() => {
              editTrade(row);
            }}
          >
            Withdraw
          </Dropdown.Item>
        </DropdownButton>
      );
    };

    const kycAction = (cell, row, rowIndex, formatExtraData) => {
      return (
        <DropdownButton id="dropdown-basic-button" title={"Action"}>
          <Dropdown.Item
            onClick={async () => {
              commonProps.updateQuestionaireDocsLoading(true);
              commonProps.updateSelectedUser(row);
              commonProps.updateShowModal(true);
              commonProps.updateModalView(2);
              await getImagesInBase64(
                `${config.approvalEndpoint}?applicantId=${row.applicantId}&listImages=true`
              ).then((res) => {
                commonProps.updateApplicantDoc(res.id);
                commonProps.updateApplicantSelfie(res.selfie);
              });

              await getImagesInBase64(
                `${config.approvalEndpoint}?applicantId=${row.applicantId}&listQuestionnairesDoc=true`
              ).then((res) => {
                if (res && Object.keys(res).length > 0) {
                  commonProps.updateApplicantQuestionnaireDocs(res);
                } else {
                  commonProps.updateApplicantQuestionnaireDocs(null);
                }
                commonProps.updateQuestionaireDocsLoading(false);
              });
            }}
          >
            View Applicant
          </Dropdown.Item>
          {!row.isProcessed && (
            <>
              <Dropdown.Item
                onClick={async () => {
                  commonProps.updateGeneralLoading(true);
                  await getApplicantMessages(row.email)
                    .then((res) => {
                      commonProps.updateGeneralLoading(false);
                      if (res) {
                        commonProps.updateApplicantMessages(res);
                        commonProps.updateShowModal(true);
                        commonProps.updateModalView(3);
                      } else {
                        toast.error(
                          "Error while fetching applicant's messages. Try again later",
                          ToastOptions
                        );
                      }
                    })
                    .catch((err) => {
                      toast.error(
                        "Error while fetching applicant's messages",
                        ToastOptions
                      );
                    });
                }}
              >
                View Messages
              </Dropdown.Item>
              <Dropdown.Item
                onClick={async () => {
                  commonProps.updateGeneralLoading(true);
                  const abiCoder = new ethers.utils.AbiCoder();
                  await approveOrRejectApplicant(
                    row.applicantId,
                    row.inspectionId,
                    abiCoder.encode(
                      ["string", "string"],
                      [row.accountAddress, String(chainId)]
                    ),
                    {
                      reviewAnswer: "GREEN",
                    }
                  ).then((res) => {
                    commonProps.updateGeneralLoading(false);
                    res
                      ? toast.success(
                          "Applicant Approved Succesfully",
                          ToastOptions
                        )
                      : toast.error("Applicant Approval Failed", ToastOptions);
                  });
                }}
              >
                Approve
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  commonProps.updateShowModal(true);
                  commonProps.updateSelectedUser(row);
                  commonProps.updateModalView(1);
                }}
              >
                Send Message
              </Dropdown.Item>
              <Dropdown.Item
                onClick={async () => {
                  commonProps.updateGeneralLoading(true);
                  const abiCoder = new ethers.utils.AbiCoder();
                  await approveOrRejectApplicant(
                    row.applicantId,
                    row.inspectionId,
                    abiCoder.encode(
                      ["string", "string"],
                      [row.accountAddress, String(chainId)]
                    ),
                    {
                      reviewAnswer: "RED",
                    }
                  ).then((res) => {
                    commonProps.updateGeneralLoading(false);
                    res
                      ? toast.success(
                          "Applicant Rejected Succesfully",
                          ToastOptions
                        )
                      : toast.error("Applicant Rejection Failed", ToastOptions);
                  });
                }}
              >
                Reject
              </Dropdown.Item>
            </>
          )}
          {row.isProcessed && (
            <Dropdown.Item
              onClick={async () => {
                commonProps.updateChangeContent(false);
                commonProps.updateSelectedUser(row);
                commonProps.updateShowModal(true);
                commonProps.updateModalView(4);
              }}
            >
              Block User
            </Dropdown.Item>
          )}
        </DropdownButton>
      );
    };

    const tokenCallAction = (cell, row, rowIndex, formatExtraData) => {
      // console.log("row: ", row);
      return (
        <DropdownButton id="dropdown-basic-button" title={"Action"}>
          {row.amountInvested > row.amountIssued ? (
            <Dropdown.Item
              onClick={() => {
                issueLiquidity(row);
              }}
            >
              Issue Liquidity
            </Dropdown.Item>
          ) : null}
          {row.tokenAddress.toLowerCase() ===
            ethers.constants.AddressZero.toLowerCase() && (
            <Dropdown.Item
              onClick={() => {
                handleStakeOnLido(row);
              }}
            >
              Stake On Lido
            </Dropdown.Item>
          )}
          {row.role !== "" ? (
            <Dropdown.Item
              onClick={() => {
                removeRole(row);
              }}
            >
              Remove Role
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              onClick={() => {
                assignManager(row);
              }}
            >
              Assign Manager
            </Dropdown.Item>
          )}
        </DropdownButton>
      );
    };

    const secondaryCallAction = (cell, row, rowIndex, formatExtraData) => {
      const isIntermediaryOrDP =
        userRole === "intermediary" || userRole === "DP";
      const isPrimaryIssue = row.poolType === "PrimaryIssue";
      const isIssueManager =
        row.verifiedWalletData?.issueManager.toLowerCase() ===
        account.toLowerCase();

      if (isIntermediaryOrDP) {
        return (
          <DropdownButton id="dropdown-basic-button" title={"Action"}>
            {isIntermediaryOrDP &&
            !row.verifiedWalletData?.primarySettlements.length &&
            isPrimaryIssue ? (
              <Dropdown.Item onClick={() => settleIssue(row)}>
                Settle
              </Dropdown.Item>
            ) : null}
            {isIntermediaryOrDP &&
            row.verifiedWalletData?.primarySettlements.length &&
            isPrimaryIssue &&
            isIssueManager ? (
              <>
                <Dropdown.Item onClick={() => issueSecondary(row)}>
                  Issue secondary
                </Dropdown.Item>
                {row.verifiedWalletData?.isFiat ? (
                  <Dropdown.Item onClick={() => withdrawSubscription(row)}>
                    Withdraw subscription
                  </Dropdown.Item>
                ) : null}
              </>
            ) : null}
            <Dropdown.Item
              onClick={() => {
                scheduleSnapshot(row);
              }}
            >
              Schedule snapshot
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                unscheduleSnapshot(row);
              }}
            >
              Unschedule snapshot
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                rescheduleSnapshot(row);
              }}
            >
              Reschedule snapshot
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                getScheduledSnapshots(row);
              }}
            >
              Get scheduled snapshot
            </Dropdown.Item>
            {row.securityPaused ? (
              <Dropdown.Item
                onClick={() => {
                  pauseSecurity(row, false);
                }}
              >
                Unpause
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={() => {
                  pauseSecurity(row, true);
                }}
              >
                Pause
              </Dropdown.Item>
            )}
            <Dropdown.Item
              onClick={() => {
                extinguishSecurities(row);
              }}
            >
              Extinguish
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                getFloatingSecurities(row);
              }}
            >
              Get float
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                scheduleResolution(row);
              }}
            >
              Schedule resolution
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                schedulePayout(row);
              }}
            >
              Schedule payout
            </Dropdown.Item>
          </DropdownButton>
        );
      } else if (userRole === "AM") {
        return (
          <DropdownButton id="dropdown-basic-button" title={"Action"}>
            <Dropdown.Item
              onClick={() => {
                viewOrderBook(row);
              }}
            >
              View Orderbook
            </Dropdown.Item>
          </DropdownButton>
        );
      }
    };

    const transactionCallAction = (cell, row, rowIndex, formatExtraData) => {
      return (
        <DropdownButton id="dropdown-basic-button" title={"Action"}>
          <Dropdown.Item
            onClick={() => {
              closeSecondaryIssue(row, networkOptions);
            }}
          >
            Stop
          </Dropdown.Item>
        </DropdownButton>
      );
    };

    const scheduledRecordDate = (cell, row, rowIndex, formatExtraData) => {
      return (
        <DropdownButton
          id="dropdown-basic-button"
          title={
            this.state.recordDate
              ? format(
                  new Date(parseInt(this.state.recordDate) * 1000),
                  "yyyy-MM-dd hh:mm a"
                )
              : "Select Time"
          }
        >
          {row.snapShots.length ? (
            row.snapShots.map((snapshot) => (
              <Dropdown.Item
                key={snapshot}
                onClick={(e) => this.handleRecordDateChange(row, snapshot)}
              >
                {format(
                  new Date(parseInt(snapshot) * 1000),
                  "yyyy-MM-dd hh:mm a"
                )}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item>No resolution scheduled</Dropdown.Item>
          )}
        </DropdownButton>
      );
    };

    const displayMarginCallActions = (cell, row) => {
      return (
        <DropdownButton id="dropdown-basic-button" title="Action">
          <Dropdown.Item
            onClick={() => {
              commonProps.updateShowModal(true);
              commonProps.updateColumnSelected({
                security: row.securityToken,
                currency: row.currency,
                currencyName: row.marginCurrencyName,
                currencyDecimals: row.marginCurrencyDecimals,
                tokensPair: row.marginTokensPair,
              });
              commonProps.updateModalView(1);
            }}
          >
            Send Collateral
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              commonProps.updateShowModal(true);
              commonProps.updateColumnSelected({
                security: row.securityToken,
                currency: row.currency,
                currencyName: row.marginCurrencyName,
                currencyDecimals: row.marginCurrencyDecimals,
                tokensPair: row.marginTokensPair,
              });
              commonProps.updateModalView(3);
            }}
          >
            Settle Accounts
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              commonProps.updateShowModal(true);
              commonProps.updateColumnSelected({
                security: row.securityToken,
                currency: row.currency,
                currencyName: row.marginCurrencyName,
                currencyDecimals: row.marginCurrencyDecimals,
                tokensPair: row.marginTokensPair,
              });
              commonProps.updateModalView(2);
            }}
          >
            Transfer Profit
          </Dropdown.Item>
          <Dropdown.Item
            onClick={async () => {
              await closeMarginIssue(row.securityToken);
            }}
          >
            Close
          </Dropdown.Item>
        </DropdownButton>
      );
    };

    const displayMarginMangerButton = (cell, row) => {
      if (row.marginManageAction) {
        return (
          <div>
            <Button
              onClick={() => {
                commonProps.updateSecurity(row.security);
                commonProps.updateCurrency(row.currency);
                commonProps.updateProductCategory(row.securityCategory);
                commonProps.updateSecuritySymbol(row.securitySymbol);
                commonProps.updateCurrencySymbol(row.currencySymbol);
                commonProps.updateShowModal(true);
                commonProps.updateModalView(4);
              }}
            >
              Issue Product
            </Button>
          </div>
        );
      } else {
        return (
          <span>
            <b>Product Issued</b>
          </span>
        );
      }
    };

    const productCategoryList = productCategory.reduce((acc, val, index) => {
      acc[val] = val;
      return acc;
    }, {});

    const numberFixed = (cell, row) => {
      return <span>{cell.toFixed(2)}</span>;
    };
    const addressCheck = [
      "address",
      "owner",
      "offered",
      "offeredBy",
      "managerAddress",
      "platformAddress",
      "accountAddress",
    ];
    const dateCheck = [
      "cutoffTime",
      "date",
      "createTime",
      "recordDate",
      "nextRecordDate",
    ];
    const tokenNamespretty = ["tokenName", "currencyName", "securityName"];
    const numberCheck = ["qty", "traded"];
    const decodeIpfsAddress = ["selfieURL", "identityURL"];

    return (
      <>
        {this.state.loading ? <Loader /> : null}
        {showFilter && (
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div className="w-100 text-left">
              <DropdownButton id="dropdown-basic-button" title="Filter">
                <Dropdown.Item
                  onClick={() => {
                    commonProps.updateTableData(null);
                  }}
                >
                  All
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    commonProps.updateModalView(10);
                    commonProps.updateShowModal(true);
                  }}
                >
                  Custom
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <div className="w-100 text-right">
              <DropdownButton id="dropdown-basic-button" title="Actions">
                <Dropdown.Item
                  onClick={() => {
                    downloadPdf();
                  }}
                >
                  Download
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    printPdf();
                  }}
                >
                  Print
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        )}
        <div ref={componentToPrintRef}>
          <div ref={targetRef}>
            <BootstrapTable
              keyField="id"
              data={tableData}
              key={this.state.balance}
              columns={tableColumns.map((element, index) => {
                element.dataField = element.dataField ?? element.val;
                element.text = element.text ?? element.label;
                if (element.val === "security" || element.val === "currency") {
                  element.formatter = tokenFormatter;
                } else if (element.val === "currencySymbolAsLink") {
                  element.formatter = currencyLinkRedirect;
                } else if (element.val === "securitySymbolAsLink") {
                  element.formatter = securityLinkRedirect;
                } else if (element.val === "transferorNameAsLink") {
                  element.formatter = transferorLinkRedirect;
                } else if (element.val === "transfereeNameAsLink") {
                  element.formatter = transfereeLinkRedirect;
                } else if (element.val === "securitySymbol") {
                  element.formatter = displaySecurityToken;
                } else if (element.val === "currencySymbol") {
                  element.formatter = displayCurrencyToken;
                } else if (element.val === "marginSecurityName") {
                  element.formatter = displaySecurityToken;
                } else if (element.val === "marginCurrencyName") {
                  element.formatter = displayCurrencyToken;
                } else if (element.val === "marginSecuritySymbol") {
                  element.formatter = displaySecurityToken;
                } else if (element.val === "marginCurrencySymbol") {
                  element.formatter = displayCurrencyToken;
                } else if (dateCheck.includes(element.val)) {
                  element.formatter = unixTimeFormatter;
                  element.sort = true;
                } else if (element.val === "offeringDocs") {
                  element.formatter = shortIPFSAddress;
                } else if (element.val === "IssuingDocs") {
                  element.formatter = shortIPFSAddress;
                } else if (element.val === "Issuer") {
                  element.formatter = handleIssuerRedirect;
                } else if (decodeIpfsAddress.includes(element.val)) {
                  element.formatter = decodeIPFSAddress;
                } else if (addressCheck.includes(element.val)) {
                  element.formatter = shortAddressCheck;
                } else if (element.val === "amount") {
                  element.formatter = (cell) => Response.parseWeiToEther(cell);
                } else if (element.val === "minimumPrice") {
                  element.formatter = (cell) => parseFloat(cell).toFixed(2);
                } else if (element.val === "homeCallAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = homeCallAction;
                } else if (element.val === "borrowerAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = borrowerAction;
                } else if (element.val === "lenderAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = lenderAction;
                } else if (element.val === "closedIssuesAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = secondaryCallAction;
                } else if (element.val === "managerEarnings") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = managerEarnings;
                } else if (element.val === "voteResolution") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = voteResolution;
                } else if (element.val === "adminIssueCallAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = adminIssueCallAction;
                } else if (element.val === "primaryInvestorCallAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = primaryInvestorCallAction;
                } else if (element.val === "secondaryInvestorCallAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = secondaryInvestorCallAction;
                } else if (element.val === "platformCallAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = platformCallAction;
                } else if (element.val === "platformDetailCallAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = platformDetailCallAction;
                } else if (element.val === "tokenCallAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = tokenCallAction;
                } else if (element.val === "offerCallAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = offerCallAction;
                } else if (element.val === "secondaryCallAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = secondaryCallAction;
                } else if (element.val === "transactionCallAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = transactionCallAction;
                } else if (tokenNamespretty.includes(element.val)) {
                  element.formatter = tokenNameBeautify;
                } else if (numberCheck.includes(element.val)) {
                  element.formatter = numberFixed;
                } else if (element.val === "servicerIssueDate") {
                  element.formatter = servicerIssueDate;
                } else if (element.val === "marginCallAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = displayMarginCallActions;
                } else if (element.val === "marginManageAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = displayMarginMangerButton;
                } else if (element.val === "claimTrade") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = claimTrade;
                } else if (element.val === "modifyTrade") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = modifyTradeAction;
                } else if (element.val === "collateralAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = collateralAction;
                } else if (element.val === "kycAction") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = kycAction;
                } else if (element.val === "scheduledRecordDate") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = scheduledRecordDate;
                } else if (element.val === "updateBalance") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = updateBalance;
                } else if (element.val === "updateEarnings") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = updateEarnings;
                } else if (element.val === "setKYCStatus") {
                  element.events = {
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  };
                  element.formatter = setKYCStatus;
                } else if (element.val === "productCategory") {
                  element.filter = selectFilter({
                    placeholder: "Filter Products",
                    className: "categoryButton",
                    options: productCategoryList,
                  });
                }
                return element;
              })}
              bootstrap4
              pagination={paginationFactory()}
              noDataIndication={this.noDataIndication}
              wrapperClasses="table-responsive"
              rowClasses="cursor-pointer"
              defaultSorted={defaultSortedBy}
              filter={filterFactory()}
              {...this.props}
            />
          </div>
        </div>

        <VerticallyModal
          showModal={this.state.showModal}
          modalOnHide={handleModalHide}
          modalSize={"lg"}
          modalHeading={
            <h2>
              <b>{this.state.heading}</b>
            </h2>
          }
          withFooter={false}
        >
          {this.state.modalContent}
        </VerticallyModal>
      </>
    );
  }
}

export { sortByIntegerValue, sortByDateValue };
export default UiTable;
