import React, { useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import HomePage from "./pages/primaries";
import PoolDetail from "./pages/poolDetail";
import PrimaryInvestorDetail from "./pages/assetManager/primaries/primaryInvestorDetail";
import ClosedInvestorDetail from "./pages/assetManager/primaries/closedInvestorDetail";
import SecondaryInvestorDetail from "./pages/assetManager/secondaries/secondaryInvestorDetail";
import PlatformDetail from "./pages/servicer/platforms/platformDetail";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./styles/common.css";
import Sidebar from "./components/common/Sidebar";
import ErrorBoundary from "./components/common/ErrorBoundary";
import UnavailablePage from "./components/common/Unavailable";
import CommonHeader from "./components/common/CommonHeader";
import { Buffer } from "buffer";
import Secondaries from "./pages/secondaries";
import Portfolio from "./pages/portfolio";
import Distributions from "./pages/corporate_action/distributions";
import Resolutions from "./pages/corporate_action/resolutions";
import BuyLiquidity from "./pages/verifiedLiquidity/buyLiquidity";
import Trades from "./pages/accounts/trades";
import Orders from "./pages/accounts/orders";
import AdminTokens from "./pages/servicer/tokens";
import AdminIssues from "./pages/servicer/issues";
import AdminPlatform from "./pages/servicer/platforms";
import RevenueShare from "./pages/servicer/revenueShare";
import AdminConfiguration from "./pages/servicer/configuration";
import ManagerOffers from "./pages/assetManager/offers";
import ManagerPrimary from "./pages/assetManager/primaries/primaryIssues";
import ManagerSecondary from "./pages/assetManager/secondaries/secondaryIssues";
import IssueRequests from "./pages/custodian/issue_requests";
import ApprovalRequests from "./pages/custodian/approval_requests";
import SecondaryResolutions from "./pages/assetManager/secondaries/resolutions";
import PrimaryResolutions from "./pages/assetManager/primaries/resolutions";
import ActiveIssues from "./pages/assetManager/primaries/active.js";
import ClosedIssues from "./pages/assetManager/primaries/closed.js";
import ApprovedKYC from "./pages/kycManager/approvals";
import ProcessedKYC from "./pages/kycManager/processed";
import SecondaryTransactions from "./pages/assetManager/secondaries/secondaryTransactions.js";
import { useGlobal } from "./context/globalContext";
import MarginProducts from "./pages/marginProducts/index.js";
import MarginIssuesManager from "./pages/marginProducts/marginIssue.js";
import ManageMarginIssues from "./pages/marginProducts/manageIssue.js";
import Collaterals from "./pages/collateral/index.js";
import Pnls from "./pages/pnl/index.js";
import MarginTransaction from "./pages/marginProducts/marginTransaction.js";
import BorrowPage from "./pages/borrow/index.js";
import LendPage from "./pages/lend/index.js";

window.Buffer = window.Buffer || Buffer;

export default function AppComponent() {
  const { login, userRole, loggedIn, kycStatus, userType, userCountry } =
    useGlobal();
  login();

  // console.log("userRole: ", userRole);
  return (
    <Router>
      <CommonHeader />
      <Sidebar
        group="investor"
        main={
          <ErrorBoundary>
            <Switch>
              {userRole == "Admin" ? (
                <>
                  <Switch>
                    <Route exact path="/" render={AdminIssues} />
                    <Route exact path="/issues" render={AdminIssues} />
                    <Route exact path="/tokens" render={AdminTokens} />
                    <Route exact path="/platforms" render={AdminPlatform} />
                    <Route
                      exact
                      path="/platform/:name/:platform_id"
                      render={PlatformDetail}
                    />
                    <Route exact path="/revenue-shares" render={RevenueShare} />
                    <Route
                      exact
                      path="/configuration"
                      render={AdminConfiguration}
                    />
                    <Route component={UnavailablePage} />
                  </Switch>
                </>
              ) : userRole == "AM" || userRole == "DP" ? (
                <>
                  <Switch>
                    <Route exact path="/" render={ManagerOffers} />
                    <Route exact path="/offers" render={ManagerOffers} />
                    <Route
                      exact
                      path="/pool/:type/:pool_id"
                      render={PoolDetail}
                    />
                    <Route
                      exact
                      path="/primary_issues"
                      render={ManagerPrimary}
                    />
                    <Route
                      exact
                      path="/secondary_issues"
                      render={ManagerSecondary}
                    />
                    <Route
                      exact
                      path="/primary_resolutions"
                      render={PrimaryResolutions}
                    />
                    <Route
                      exact
                      path="/secondary_resolutions"
                      render={SecondaryResolutions}
                    />
                    <Route exact path="/active_issues" render={ActiveIssues} />
                    <Route exact path="/closed_issues" render={ClosedIssues} />
                    <Route exact path="/buy-liquidity" render={BuyLiquidity} />
                    <Route
                      exact
                      path="/distributions"
                      render={(props) => (
                        <Distributions loggedIn={loggedIn} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/secondary_transactions"
                      render={SecondaryTransactions}
                    />
                    <Route
                      exact
                      path="/active_issues/primary_investors/:type/:securityAddress"
                      render={PrimaryInvestorDetail}
                    />
                    <Route
                      exact
                      path="/closed_issues/primary_investors/:type/:securityAddress"
                      render={ClosedInvestorDetail}
                    />
                    <Route
                      exact
                      path="/:category/secondary_investors/:type/:securityAddress"
                      render={SecondaryInvestorDetail}
                    />
                    {userRole === "DP" && (
                      <>
                        <Route
                          exact
                          path="/marginissues"
                          render={(props) => (
                            <ManageMarginIssues
                              loggedIn={loggedIn}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/managemarginissues"
                          render={(props) => (
                            <MarginIssuesManager
                              loggedIn={loggedIn}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/margintransactions"
                          render={(props) => (
                            <MarginTransaction loggedIn={loggedIn} {...props} />
                          )}
                        />
                      </>
                    )}
                    <Route component={UnavailablePage} />
                  </Switch>
                </>
              ) : userRole == "Custodian" ? (
                <>
                  <Switch>
                    <Route exact path="/" render={IssueRequests} />
                    <Route
                      exact
                      path="/issue_requests"
                      render={IssueRequests}
                    />
                    <Route
                      exact
                      path="/withdrawl_requests"
                      render={ApprovalRequests}
                    />
                    <Route exact path="/buy-liquidity" render={BuyLiquidity} />
                    <Route component={UnavailablePage} />
                  </Switch>
                </>
              ) : userRole == "KYCAML" ? (
                <>
                  <Switch>
                    <Route exact path="/" render={ApprovedKYC} />
                    <Route exact path="/kyc_approvals" render={ApprovedKYC} />
                    <Route exact path="/kyc_processed" render={ProcessedKYC} />
                    <Route component={UnavailablePage} />
                  </Switch>
                </>
              ) : userRole == "investor" || "intermediary" ? (
                <>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={(props) => (
                        <HomePage loggedIn={loggedIn} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/secondaries"
                      render={(props) => (
                        <Secondaries loggedIn={loggedIn} {...props} />
                      )}
                    />

                    <Route
                      exact
                      path="/marginproducts"
                      render={(props) => (
                        <MarginProducts loggedIn={loggedIn} {...props} />
                      )}
                    />
                    {/* <Route exact path="/borrow" render={BorrowPage} />
                    <Route exact path="/lend" render={LendPage} /> */}
                    <Route
                      exact
                      path="/trades"
                      render={(props) => (
                        <Trades loggedIn={loggedIn} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/pending-orders"
                      render={(props) => (
                        <Orders loggedIn={loggedIn} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/portfolio"
                      render={(props) => (
                        <Portfolio loggedIn={loggedIn} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/collaterals"
                      render={(props) => (
                        <Collaterals loggedIn={loggedIn} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/pnl"
                      render={(props) => (
                        <Pnls loggedIn={loggedIn} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/pool/:type/:pool_id"
                      render={(props) => (
                        <PoolDetail loggedIn={loggedIn} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/resolutions"
                      render={(props) => (
                        <Resolutions loggedIn={loggedIn} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/distributions"
                      render={(props) => (
                        <Distributions loggedIn={loggedIn} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/buy-liquidity"
                      render={(props) => (
                        <BuyLiquidity loggedIn={loggedIn} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/configuration"
                      render={AdminConfiguration}
                    />
                    {userRole == "intermediary" && (
                      <>
                        <Route
                          exact
                          path="/primary_issues"
                          render={ManagerPrimary}
                        />
                        {/* <Route
                          exact
                          path="/secondary_issues"
                          render={ManagerSecondary}
                        /> */}
                        <Route
                          exact
                          path="/primary_resolutions"
                          render={PrimaryResolutions}
                        />
                        {/* <Route
                          exact
                          path="/secondary_resolutions"
                          render={SecondaryResolutions}
                        /> */}
                        <Route
                          exact
                          path="/active_issues"
                          render={ActiveIssues}
                        />
                        <Route
                          exact
                          path="/closed_issues"
                          render={ClosedIssues}
                        />
                        <Route
                          exact
                          path="/active_issues/primary_investors/:type/:securityAddress"
                          render={PrimaryInvestorDetail}
                        />
                        <Route
                          exact
                          path="/closed_issues/primary_investors/:type/:securityAddress"
                          render={ClosedInvestorDetail}
                        />
                        <Route
                          exact
                          path="/:category/secondary_investors/:type/:securityAddress"
                          render={SecondaryInvestorDetail}
                        />
                        {/* <Route
                          exact
                          path="/secondary_transactions"
                          render={SecondaryTransactions}
                        /> */}
                      </>
                    )}
                    <Route component={UnavailablePage} />
                  </Switch>
                </>
              ) : (
                <></>
              )}
            </Switch>
          </ErrorBoundary>
        }
      />
    </Router>
  );
}
