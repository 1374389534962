import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { NETWORKS_INFO } from "../utils/helpers/networks";

export const kyberSubgraphClient = (chainId) =>
  new ApolloClient({
    link: new HttpLink({
      uri: NETWORKS_INFO[chainId].kyberSubgraphUrl,
    }),
    cache: new InMemoryCache(),
    shouldBatch: true,
  });

export const balancerSubgraphClient = (chainId) =>
  new ApolloClient({
    link: new HttpLink({
      uri: NETWORKS_INFO[chainId].balancerSubgraphUrl,
    }),
    cache: new InMemoryCache(),
    shouldBatch: true,
  });

export const verifiedWalletSubgraphClient = (chainId) => {
  if (chainId) {
    return new ApolloClient({
      link: new HttpLink({
        uri: NETWORKS_INFO[chainId].verifiedWalletSubgraphUrl,
      }),
      cache: new InMemoryCache(),
      shouldBatch: true,
    });
  } else {
    return null;
  }
};
