import { useState, useRef } from "react";
import Loader from "../../../components/loader";
import UiButton from "../../../components/button";
import TextInput from "../../../components/textinput/TextInput";
import { Button, Row, Col } from "react-bootstrap";
import UiTable from "../../../components/table/Table";

import {
  useInvestorData,
  useNetworkOptions,
} from "../../../context/contractDataLoaderService";
import VerticallyModal from "../../../components/modal/VerticallyModal";
import {
  Liquidity,
  Client,
  contractAddress,
} from "@verified-network/verified-sdk";
import Web3 from "web3";
import { ethers, providers } from "ethers";
import ERC20ABI from "../../../utils/abi/ERC20.json";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";

function TokenDetails(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  let { data, loading } = useInvestorData();
  const [showModal, setShowModal] = useState(false);
  const [tokenAddress, setTokenAddress] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions();

  let liquidityContract, clientContract;

  if (provider && provider.getSigner && chainId && address) {
    liquidityContract = new Liquidity(
      provider.getSigner(address),
      contractAddress[chainId].Liquidity
    );
    clientContract = new Client(
      provider.getSigner(address),
      contractAddress[chainId].Client
    );
  }

  const handleModalHide = () => {
    setShowModal(false);
    setTokenAddress("");
  };

  const addToken = async () => {
    if (!web3.utils.isAddress(tokenAddress)) {
      alert("Enter proper token address");
      return;
    }
    setLoadingModal(true);
    let tokenName;
    if (tokenAddress !== ethers.constants.AddressZero) {
      const currencyTokenContract = new web3.eth.Contract(
        ERC20ABI,
        tokenAddress
      );
      tokenName = await currencyTokenContract.methods.name().call();
    } else {
      if (tokenAddress === ethers.constants.AddressZero) {
        tokenName = networks[chainId].nativeCurrency.name;
      }
    }

    try {
      const tx = await liquidityContract.supportTokens(
        tokenAddress,
        tokenName,
        { gasLimit: "2000000" }
      );
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        setChangeContent(true);
        modalContent.current.innerHTML = "";
      }
      setLoadingModal(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `{${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoadingModal(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };
  let headers = [
    { label: "Investor Wallet", val: "address" },
    { label: "Name", val: "investorName" },
    { label: "Country", val: "investorCountry" },
    { label: "Token Invested", val: "tokenName" },
    { label: "Amount Invested", val: "amountInvested" },
    { label: "Amount Issued", val: "amountIssued" },
    { label: "Call Action", val: "tokenCallAction" },
  ];
  return (
    <>
      {loading || loadingModal ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-50">
            <h3 className="text-left">Liquidity providers</h3>
          </div>
          <div className="w-50 text-right">
            <Button
              onClick={() => {
                setShowModal(true);
              }}
            >
              Add Token
            </Button>
          </div>
        </div>
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={data || []}
            hover
            bordered={false}
            signer={signer}
            ower={account}
            networkOptions={networkOptions}
            contract={clientContract}
            chainId={chainId}
            account={account}
            web3={web3}
          />
        </div>
      </section>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={<h3>Token Details</h3>}
        withFooter={false}
      >
        {changeContent && (
          <UiButton
            onClick={() => {
              setShowModal(false);
              setChangeContent(false);
            }}
            buttonVariant="primary"
            buttonClass="SignUpButton flex-1 ml-0"
            buttonText="Click to close this form&nbsp;&rarr;"
            type="submit"
          />
        )}
        <div className="d-grid gap-2" ref={modalContent}>
          <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={4}>
                <b>Token Address</b>
              </Col>
              <Col className="pl-0" xs={6}>
                <TextInput
                  placeholder="Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col className="pl-0" xs={4} />
              <Col className="pl-0" xs={4}>
                <UiButton
                  onClick={() => {
                    addToken();
                  }}
                  buttonVariant="primary"
                  buttonClass="SignUpButton flex-1 ml-0"
                  buttonText="Add Token"
                  type="submit"
                />
                <Col className="pl-0" xs={4} />
              </Col>
            </Row>
          </Row>
        </div>
      </VerticallyModal>
    </>
  );
}

export default function AdminTokens(props) {
  return <TokenDetails {...props} />;
}
