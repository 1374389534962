import React, { useState } from "react";
import Loader from "../../../components/loader";
import { useBalancerPoolsData } from "../../../context/balancerPoolData";
import { PoolType } from "../../poolDetail/balancer/types";
import Web3 from "web3";

import { useGlobal } from "../../../context/globalContext";
import UiTable from "../../../components/table/Table";
import UiButton from "../../../components/button";
import {
  PrimaryIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";
import { useSupportedTokens } from "../../../context/contractDataLoaderService";
import AddDetails from "../../../pages/custodian/details";
import VerticallyModal from "../../../components/modal/VerticallyModal";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function ServicerHome(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }

  const { userRole, isUpdate, setIsUpdate } = useGlobal();
  let { data, loading } = useBalancerPoolsData(PoolType.PrimaryIssue, isUpdate);
  let { data: secondaryPools } = useBalancerPoolsData(PoolType.SecondaryIssue);
  const [showModal, setShowModal] = useState(false);
  let { data: supportedTokens } = useSupportedTokens();

  if (userRole === "DP" || userRole === "intermediary") {
    const secondaryPoolSecurities = secondaryPools.map((pool) =>
      pool?.security?.toLowerCase()
    );
    data = data.filter(
      (pool) =>
        pool?.verifiedWalletData?.issueManager?.toLowerCase() ===
          account.toLowerCase() &&
        pool?.verifiedWalletData?.subscriptionsClosed?.length
    );
    data = data.map((pool) => {
      if (secondaryPoolSecurities.includes(pool?.security?.toLowerCase())) {
        return {
          ...pool,
          issuedSecondary: true,
          isFiat: supportedTokens.find(
            (token) =>
              token?.tokenAddress?.toLowerCase() ===
              pool?.currency?.toLowerCase()
          ).isFiat,
        };
      } else {
        return {
          ...pool,
          isFiat: supportedTokens.find(
            (token) =>
              token?.tokenAddress?.toLowerCase() ===
              pool?.currency?.toLowerCase()
          ).isFiat,
        };
      }
    });
  } else if (userRole == "AM")
    data = data.filter(
      (pool) =>
        pool?.verifiedWalletData?.liquidityProviders?.find(
          (obj) => obj.owner.id.toLowerCase() === account.toLowerCase()
        ) && pool?.verifiedWalletData?.subscriptionsClosed.length
    );

  let primaryIssueManagerContract;
  if (provider && provider.getSigner && chainId && address) {
    const BalancerPrimaryIssueManager =
      contractAddress[chainId].BalancerPrimaryIssueManager;
    if (BalancerPrimaryIssueManager) {
      primaryIssueManagerContract = new PrimaryIssueManager(
        provider.getSigner(address),
        BalancerPrimaryIssueManager,
        "balancer"
      );
    }
  }

  let headers = [
    { label: "Security", val: "security" },
    { label: "Company", val: "companyName" },
    { label: "Currency", val: "currency" },
    { label: "Close Date", val: "cutoffTime" },
    userRole == "DP" || userRole == "intermediary"
      ? { label: "Call Action", val: "closedIssuesAction" }
      : { label: "Call Action", val: "managerEarnings" },
  ];

  const options = {
    onClick: (event, row) => {
      onInvest(row);
    },
  };
  const onInvest = (row) => {
    props.history.push(
      `/closed_issues/primary_investors/${row.poolType}/${row.security}`
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-100">
            <h3 className="text-left">Closed Issues</h3>
          </div>
          {userRole != "AM" && (
            <div className="w-50 text-right">
              <UiButton
                onClick={() => {
                  setShowModal(true);
                }}
                buttonVariant="primary"
                buttonClass="flex-1 ml-0"
                buttonText="Link Bank Account"
                type="submit"
              />
            </div>
          )}
        </div>
        <div className="w-100">
          <div className="pools-table width-100">
            <UiTable
              thead={headers}
              tbodyData={data || []}
              hover
              bordered={false}
              userRole={userRole}
              rowEvents={options}
              chainId={chainId}
              web3={web3}
              account={account}
              signer={signer}
              useGlobal={useGlobal}
              isUpdate
              updateTableData={setIsUpdate}
              contract={primaryIssueManagerContract}
            />
          </div>
        </div>
      </section>
      <VerticallyModal
        key="showDetails"
        showModal={showModal}
        modalOnHide={() => {
          setShowModal(false);
        }}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>Create Custody Account</b>
          </h2>
        }
        withFooter={false}
      >
        <AddDetails isCustodian={false} />
      </VerticallyModal>
    </>
  );
}

export default function ClosedIssues(props) {
  return <ServicerHome {...props} />;
}
