import React, { useState } from "react";
import Loader from "../../components/loader";
import UiTable from "../../components/table/Table";

import Web3 from "web3";
import {
  useClientKYCData,
  useNetworkOptions,
} from "../../context/contractDataLoaderService";
import { Client, contractAddress } from "@verified-network/verified-sdk";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";
import { useGlobal } from "../../context/globalContext";
import VerticallyModal from "../../components/modal/VerticallyModal";
import TextInput from "../../components/textinput/TextInput";
import UiButton from "../../components/button";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import {
  changeApllicantName,
  getFileSizeInMB,
  sendMessageToApplicant,
} from "./kycUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
} from "../../utils/constants";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import Select from "react-select";

function KYCList(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }

  let { data: kycList, loading: kycLoading } = useClientKYCData(
    false,
    true,
    ""
  );

  const [showModal, setShowModal] = useState(false);
  const [modalView, setModalView] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [kycMessage, setKycMessage] = useState("");
  const [kycMessageSubject, setKycMessageSubject] = useState("");
  const [newApplicantName, setNewApplicantName] = useState(null);
  const [messageApplicantName, setMessageApplicantName] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [applicantDoc, setApplicantDoc] = useState();
  const [applicantSelfie, setApplicantSelfie] = useState();
  const [applicantQuestionnaireDocs, setApplicantQuestionnaireDocs] =
    useState();
  const [questionaireDocsLoading, setQuestionaireDocsLoading] = useState(false);
  const [applicantMessages, setApplicantMessages] = useState([]);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [filterContent, setFilterContent] = useState(null);
  const [value, onChange] = useState(new Date());
  const [value2, onChange2] = useState(new Date());
  const [newData, setNewData] = useState(null);
  let message, heading;

  const handleModalHide = () => {
    setModalView(0);
    setShowModal(false);
    setNewApplicantName(null);
    setMessageApplicantName(null);
    setShowSaveButton(false);
    setFilterContent(null);
    setApplicantDoc();
    setApplicantSelfie();
    setApplicantQuestionnaireDocs();
  };

  const commonProps = {
    modalView,
    updateModalView: setModalView,
    showModal,
    updateShowModal: setShowModal,
    selectedUser,
    updateSelectedUser: setSelectedUser,
    applicantDoc,
    updateApplicantDoc: setApplicantDoc,
    newApplicantName,
    applicantSelfie,
    updateApplicantSelfie: setApplicantSelfie,
    updateApplicantMessages: setApplicantMessages,
    generalLoading,
    updateGeneralLoading: setGeneralLoading,
    updateQuestionaireDocsLoading: setQuestionaireDocsLoading,
    updateApplicantQuestionnaireDocs: setApplicantQuestionnaireDocs,
  };
  const { data: networkOptions } = useNetworkOptions();
  const headers = [
    { label: "Date", val: "createdAt" },
    { label: "Wallet address", val: "fullAddress" },
    { label: "Name", val: "fullName" },
    { label: "Applicant ID", val: "applicantId" },
    { label: "Country", val: "countryName" },
    { label: "Staus", val: "applicantStatus" },
    // { label: "Photo/Video KYC", val: "selfieURL" },
    // { label: "Indentity KYC", val: "identityURL" },
    { label: "Actions", val: "kycAction" },
  ];

  const processedData = kycList?.filter((kyc) => {
    if (kyc && kyc.status !== 3) {
      return kyc;
    }
  });

  if (modalView == 1) {
    heading = "Send Kyc Message";
    message = (
      <div className="d-grid gap-2">
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Email</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Applicant email"
                disabled={true}
                value={selectedUser ? selectedUser["email"] : ""}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Name</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Applicant name"
                onChange={(e) => setMessageApplicantName(e.target.value)}
                value={
                  selectedUser && messageApplicantName === null
                    ? selectedUser["fullName"]
                    : messageApplicantName
                }
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Subject</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <textarea
                onChange={(e) => setKycMessageSubject(e.target.value)}
                name="KycMessageSubject"
                style={{
                  minHeight: "50px",
                  width: "300px",
                }}
              >
                {kycMessageSubject}
              </textarea>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Message</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {/* <MessageInput
                style={{
                  height: "100px",
                }}
                placeholder="Message"
                onChange={(e) => setKycMessage(e.target.value)}
                value={kycMessage}
              /> */}
              <textarea
                onChange={(e) => setKycMessage(e.target.value)}
                name="kycMessage"
                style={{
                  minHeight: "190px",
                  width: "400px",
                }}
              >
                {kycMessage}
              </textarea>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={async () => {
                  if (kycMessage === "") {
                    toast.error("Message can not be empty", ToastOptions);
                    return;
                  }
                  if (kycMessageSubject === "") {
                    toast.error(
                      "Message subject can not be empty",
                      ToastOptions
                    );
                    return;
                  }
                  if (!selectedUser || selectedUser["email"].length < 1) {
                    toast.error(
                      "Error while getting applicant's email try again later",
                      ToastOptions
                    );
                    return;
                  }
                  setGeneralLoading(true);
                  await sendMessageToApplicant(
                    selectedUser["email"],
                    null,
                    kycMessageSubject,
                    kycMessage
                  ).then((res) => {
                    setGeneralLoading(false);
                    res
                      ? toast.success("Message sent succesfully", ToastOptions)
                      : toast.error(
                          "Error while sending message",
                          ToastOptions
                        );
                  });
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Send Message"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    );
  } else if (modalView == 2) {
    heading = "Apllicant Portal";
    message = (
      <div className="d-grid gap-2">
        {loader ? <Loader /> : null}
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Id</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {selectedUser ? selectedUser["applicantId"] : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Address</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {selectedUser ? selectedUser["accountAddress"] : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Name</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Applicant's name(firstname lastname)"
                value={
                  selectedUser && !showSaveButton && newApplicantName === null
                    ? selectedUser["fullName"]
                    : showSaveButton
                    ? newApplicantName
                    : ""
                }
                onChange={(e) => {
                  setNewApplicantName(e.target.value);
                  e.target.value !== ""
                    ? setShowSaveButton(true)
                    : setShowSaveButton(false);
                }}
              />
              {showSaveButton && (
                <UiButton
                  onClick={async () => {
                    const nameSplit = newApplicantName.split(" ");
                    if (nameSplit.length < 2) {
                      toast.error(
                        "Appllicant's name must include first and last name",
                        ToastOptions
                      );
                      return;
                    }
                    setLoader(true);
                    const firstName =
                      nameSplit.length > 0 ? nameSplit[0] : newApplicantName;
                    const middleName =
                      nameSplit.length > 2
                        ? nameSplit[nameSplit.length - 2]
                        : "";
                    const lastName =
                      nameSplit.length > 1
                        ? nameSplit[nameSplit.length - 1]
                        : "";
                    await changeApllicantName(
                      selectedUser["applicantId"],
                      firstName,
                      lastName,
                      middleName
                    ).then((res) => {
                      setLoader(false);
                      res
                        ? toast.success(
                            "Appllicant's name changed succesfully",
                            ToastOptions
                          )
                        : toast.error(
                            "Appllicant's name change request failed",
                            ToastOptions
                          );
                      if (res) {
                        setShowSaveButton(false);
                      }
                    });
                  }}
                  buttonVariant="primary"
                  buttonClass="SignUpButton flex-1 ml-0"
                  buttonText="Save Changes"
                  type="submit"
                />
              )}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Email</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {selectedUser ? selectedUser["email"] : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Country</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {selectedUser ? selectedUser["countryName"] : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Investor Type</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {selectedUser && selectedUser["applicantData"]["questionnaires"]
                ? selectedUser["applicantData"]["questionnaires"][0][
                    "sections"
                  ]["documentsPerInvestor"]["items"]["selectInvestorType"][
                    "value"
                  ]
                : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Exter User Id</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {selectedUser ? selectedUser["externalUserId"] : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Status</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {selectedUser ? selectedUser["applicantStatus"] : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col
              xs={{ span: 3, offset: 2 }}
              className="text-left d-flex flex-column gap-2"
            >
              <b>Applicant ID</b>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  const pdfTab = window.open("", "_blank");
                  pdfTab.document.write(
                    `
                         <html>
                            <head>
                              <title>
                                Applicant ID
                              </title>
                            </head>
                            <body>
                            <div className="d-flex flex-coloumn justify-content-center align-item-center w-100 h-100">
                            <div className="justify-content-center align-items-center w-50 h-50">
                              <img
                                src="data:image/jfif;base64,${applicantDoc}"
                                width="100%"
                                height="100%"
                                alt="Applicant's document"
                              />
                              </div>
                            </div>
                              
                            </body>
                          </html></Row>
                         `
                  );
                  pdfTab.document.close();
                }}
                style={{ color: "blue" }}
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                Open Image
              </a>
            </Col>
            <Col className="pl-0" xs={5}>
              {applicantDoc && (
                <img
                  src={`data:image/jfif;base64,${applicantDoc}`}
                  width="200px"
                  height="180px"
                  alt="Applicant's document"
                />
              )}
              {!applicantDoc && (
                <img
                  src=""
                  width="200px"
                  height="180px"
                  alt="Applicant's Document Loading..."
                />
              )}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col
              xs={{ span: 3, offset: 2 }}
              className="text-left d-flex flex-column gap-2"
            >
              <b>Applicant Selfie</b>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  const pdfTab = window.open("", "_blank");
                  pdfTab.document.write(
                    `
                         <html>
                            <head>
                              <title>
                                Applicant Selfie
                              </title>
                            </head>
                            <body>
                            <div className="d-flex flex-coloumn justify-content-center align-items-center w-100 h-100">
                            <div className="justify-content-center align-items-center w-50 h-50">
                              <img
                              className="justify-content-center align-items-center"
                                src="data:image/jfif;base64,${applicantSelfie}"
                                width="100%"
                                height="100%"
                                alt="Applicant's document"
                              />
                              </div>
                            </div>
                            </body>
                          </html></Row>
                         `
                  );
                  pdfTab.document.close();
                }}
                style={{ color: "blue" }}
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                Open Image
              </a>
            </Col>
            <Col className="pl-0" xs={5}>
              {applicantSelfie && (
                <img
                  src={`data:image/jfif;base64,${applicantSelfie}`}
                  width="150px"
                  height="100px"
                  alt="Applicant's document"
                />
              )}
              {!applicantSelfie && (
                <img
                  src=""
                  width="150px"
                  height="100px"
                  alt="Applicant's Selfie Loading..."
                />
              )}
            </Col>
          </Row>
          <hr />
          <b className="text-center w-100 mt-3">Basic Questionaire</b>
          {selectedUser &&
            selectedUser["applicantData"]["questionnaires"] &&
            Object.keys(
              selectedUser["applicantData"]["questionnaires"][0]["sections"][
                "individualInvestorTy"
              ]["items"]
            ).map((key, idx, arr) => (
              <>
                {selectedUser["applicantData"]["questionnaires"][0]["sections"][
                  "individualInvestorTy"
                ]["items"][`${key}`]["value"] && (
                  <Row
                    key={idx}
                    className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center"
                  >
                    <Col xs={{ span: 3, offset: 2 }} className="text-left">
                      <p>{key}</p>
                    </Col>
                    <Col className="pl-0" xs={5}>
                      <b>
                        {
                          selectedUser["applicantData"]["questionnaires"][0][
                            "sections"
                          ]["individualInvestorTy"]["items"][`${key}`]["value"]
                        }
                      </b>
                    </Col>
                  </Row>
                )}
              </>
            ))}
          <hr />
          <b className="text-center w-100 mt-3">Additional Questionaire</b>
          {selectedUser &&
            selectedUser["applicantData"]["questionnaires"] &&
            Object.keys(
              selectedUser["applicantData"]["questionnaires"][0]["sections"][
                "documentsPerInvestor"
              ]["items"]
            ).map((key, idx, arr) => (
              <>
                {selectedUser["applicantData"]["questionnaires"][0]["sections"][
                  "documentsPerInvestor"
                ]["items"][`${key}`]["value"] && (
                  <Row
                    key={idx}
                    className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center"
                  >
                    <Col xs={{ span: 3, offset: 2 }} className="text-left">
                      <p>{key}</p>
                    </Col>
                    <Col className="pl-0" xs={5}>
                      <b>
                        {
                          selectedUser["applicantData"]["questionnaires"][0][
                            "sections"
                          ]["documentsPerInvestor"]["items"][`${key}`]["value"]
                        }
                      </b>
                    </Col>
                  </Row>
                )}
              </>
            ))}

          <hr />
          {!questionaireDocsLoading && !applicantQuestionnaireDocs && (
            <b className="text-center w-100 mt-3">
              No Questionaire Document Found
            </b>
          )}
          {!questionaireDocsLoading && applicantQuestionnaireDocs && (
            <b className="text-center w-100 mt-3">Questionaire Documents</b>
          )}
          {questionaireDocsLoading && (
            <b className="text-center w-100 mt-3">
              Questionaire Documents Loading May Take Some Time...
            </b>
          )}

          {applicantQuestionnaireDocs && (
            <>
              {applicantQuestionnaireDocs.aml && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col
                    xs={{ span: 3, offset: 2 }}
                    className="text-left d-flex flex-column gap-2"
                  >
                    <b>
                      Certified AML policy and procedures of the company /
                      Original AML letter
                    </b>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        const pdfTab = window.open("", "_blank");
                        pdfTab.document.write(
                          `
                         <html>
                            <head>
                              <title>
                                Certified AML policy and procedures of the
                                company / Original AML letter
                              </title>
                            </head>
                            <body>
                              <iframe
                                width="100%"
                                height="100%"
                                src="data:application/pdf;base64,${applicantQuestionnaireDocs.aml}"
                                frameborder="0"
                              ></iframe>
                            </body>
                          </html></Row>
                         `
                        );
                        pdfTab.document.close();
                      }}
                      style={{ color: "blue" }}
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open File
                    </a>
                  </Col>
                  <Col className="pl-0" xs={5}>
                    {getFileSizeInMB(applicantQuestionnaireDocs.aml) > 5 && (
                      <p>File is too large. Click Open File to view</p>
                    )}
                    {getFileSizeInMB(applicantQuestionnaireDocs.aml) <= 5 && (
                      <iframe
                        src={`data:application/pdf;base64,${applicantQuestionnaireDocs.aml}`}
                        frameborder="0"
                      ></iframe>
                    )}
                  </Col>
                </Row>
              )}

              {applicantQuestionnaireDocs.ownership && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col
                    cl
                    xs={{ span: 3, offset: 2 }}
                    className="text-left d-flex flex-column gap-2"
                  >
                    <b>Ownership and Control Structure Chart</b>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        const pdfTab = window.open("", "_blank");
                        pdfTab.document.write(
                          `
                         <html>
                            <head>
                              <title>
                                Ownership and Control Structure Chart
                              </title>
                            </head>
                            <body>
                              <iframe
                                width="100%"
                                height="100%"
                                src="data:application/pdf;base64,${applicantQuestionnaireDocs.ownership}"
                                frameborder="0"
                              ></iframe>
                            </body>
                          </html></Row>
                         `
                        );
                        pdfTab.document.close();
                      }}
                      style={{ color: "blue" }}
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open File
                    </a>
                  </Col>
                  <Col className="pl-0" xs={5}>
                    {getFileSizeInMB(applicantQuestionnaireDocs.ownership) >
                      5 && <p>File is too large. Click Open File to view</p>}
                    {getFileSizeInMB(applicantQuestionnaireDocs.ownership) <=
                      5 && (
                      <iframe
                        src={`data:application/pdf;base64,${applicantQuestionnaireDocs.ownership}`}
                        frameborder="0"
                      ></iframe>
                    )}
                  </Col>
                </Row>
              )}

              {applicantQuestionnaireDocs.articles.length > 0 && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col
                    xs={{ span: 3, offset: 2 }}
                    className="text-left d-flex flex-column gap-2"
                  >
                    <b>Articles and / or Memorandum of Association</b>

                    {applicantQuestionnaireDocs.articles.map((art) => (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          const pdfTab = window.open("", "_blank");
                          pdfTab.document.write(
                            `
                       <html>
                          <head>
                            <title>
                              Articles and / or Memorandum of Association
                            </title>
                          </head>
                          <body>
                            <iframe
                              width="100%"
                              height="100%"
                              src="data:application/pdf;base64,${art}"
                              frameborder="0"
                            ></iframe>
                          </body>
                        </html></Row>
                       `
                          );
                          pdfTab.document.close();
                        }}
                        style={{ color: "blue" }}
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open File
                      </a>
                    ))}
                  </Col>
                  <Col className="pl-0" xs={5}>
                    {applicantQuestionnaireDocs.articles.map((art, idx) => (
                      <>
                        {getFileSizeInMB(art) > 5 && (
                          <p>File is too large. Click Open File to view</p>
                        )}
                        {getFileSizeInMB(art) <= 5 && (
                          <iframe
                            key={idx}
                            src={`data:application/pdf;base64,${art}`}
                            frameborder="0"
                          ></iframe>
                        )}
                      </>
                    ))}
                  </Col>
                </Row>
              )}

              {applicantQuestionnaireDocs.fatca && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col
                    xs={{ span: 3, offset: 2 }}
                    className="text-left d-flex flex-column gap-2"
                  >
                    <b>
                      FATCA-CRS-Self-Certification form for Controlling Person
                    </b>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        const pdfTab = window.open("", "_blank");
                        pdfTab.document.write(
                          `
                         <html>
                            <head>
                              <title>
                                FATCA-CRS-Self-Certification form for Controlling Person
                              </title>
                            </head>
                            <body>
                              <iframe
                                width="100%"
                                height="100%"
                                src="data:application/pdf;base64,${applicantQuestionnaireDocs.fatca}"
                                frameborder="0"
                              ></iframe>
                            </body>
                          </html></Row>
                         `
                        );
                        pdfTab.document.close();
                      }}
                      style={{ color: "blue" }}
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open File
                    </a>
                  </Col>
                  <Col className="pl-0" xs={5}>
                    {getFileSizeInMB(applicantQuestionnaireDocs.fatca) > 5 && (
                      <p>File is too large. Click Open File to view</p>
                    )}
                    {getFileSizeInMB(applicantQuestionnaireDocs.fatca) <= 5 && (
                      <iframe
                        src={`data:application/pdf;base64,${applicantQuestionnaireDocs.fatca}`}
                        frameborder="0"
                      ></iframe>
                    )}
                  </Col>
                </Row>
              )}

              {applicantQuestionnaireDocs.annualReports.length > 0 && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col
                    xs={{ span: 3, offset: 2 }}
                    className="text-left d-flex flex-column gap-2"
                  >
                    <b>Latest audited annual report/financial statements</b>
                    {applicantQuestionnaireDocs.annualReports.map((rpt) => (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          const pdfTab = window.open("", "_blank");
                          pdfTab.document.write(
                            `
                       <html>
                          <head>
                            <title>
                              Latest audited annual report/financial statements
                            </title>
                          </head>
                          <body>
                            <iframe
                              width="100%"
                              height="100%"
                              src="data:application/pdf;base64,${rpt}"
                              frameborder="0"
                            ></iframe>
                          </body>
                        </html></Row>
                       `
                          );
                          pdfTab.document.close();
                        }}
                        style={{ color: "blue" }}
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open File
                      </a>
                    ))}
                  </Col>
                  <Col className="pl-0" xs={5}>
                    {applicantQuestionnaireDocs.annualReports.map(
                      (report, idx) => (
                        <>
                          {getFileSizeInMB(report) > 5 && (
                            <p>File is too large. Click Open File to view</p>
                          )}
                          {getFileSizeInMB(report) <= 5 && (
                            <iframe
                              key={idx}
                              src={`data:application/pdf;base64,${report}`}
                              frameborder="0"
                            ></iframe>
                          )}
                        </>
                      )
                    )}
                  </Col>
                </Row>
              )}

              {applicantQuestionnaireDocs.certifiedIds.length > 0 && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col
                    xs={{ span: 3, offset: 2 }}
                    className="text-left d-flex flex-column gap-2"
                  >
                    <b>
                      Certified and Valid IDs of the Directors / Authorised
                      signatories / Beneficial Owners / Controlling Persons
                    </b>

                    {applicantQuestionnaireDocs.certifiedIds.map((cnt) => (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          const pdfTab = window.open("", "_blank");
                          pdfTab.document.write(
                            `
                         <html>
                            <head>
                              <title>
                                Certified and Valid IDs of the Directors / Authorised
                                signatories / Beneficial Owners / Controlling Persons
                              </title>
                            </head>
                            <body>
                              <iframe
                                width="100%"
                                height="100%"
                                src="data:application/pdf;base64,${cnt}"
                                frameborder="0"
                              ></iframe>
                            </body>
                          </html></Row>
                         `
                          );
                          pdfTab.document.close();
                        }}
                        style={{ color: "blue" }}
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open File
                      </a>
                    ))}
                  </Col>
                  <Col className="pl-0" xs={5}>
                    {applicantQuestionnaireDocs.certifiedIds.map((cnt) => (
                      <>
                        {getFileSizeInMB(cnt) > 5 && (
                          <p>File is too large. Click Open File to view</p>
                        )}
                        {getFileSizeInMB(cnt) <= 5 && (
                          <iframe
                            src={`data:application/pdf;base64,${cnt}`}
                            frameborder="0"
                          ></iframe>
                        )}
                      </>
                    ))}
                  </Col>
                </Row>
              )}

              {applicantQuestionnaireDocs.formW8B && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col
                    xs={{ span: 3, offset: 2 }}
                    className="text-left d-flex flex-column gap-2"
                  >
                    <b>Form W-8BEN-E</b>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        const pdfTab = window.open("", "_blank");
                        pdfTab.document.write(
                          `
                         <html>
                            <head>
                              <title>
                                Form W-8BEN-E
                              </title>
                            </head>
                            <body>
                              <iframe
                                width="100%"
                                height="100%"
                                src="data:application/pdf;base64,${applicantQuestionnaireDocs.formW8B}"
                                frameborder="0"
                              ></iframe>
                            </body>
                          </html></Row>
                         `
                        );
                        pdfTab.document.close();
                      }}
                      style={{ color: "blue" }}
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open File
                    </a>
                  </Col>
                  <Col className="pl-0" xs={5}>
                    {getFileSizeInMB(applicantQuestionnaireDocs.formW8B) >
                      5 && <p>File is too large. Click Open File to view</p>}
                    {getFileSizeInMB(applicantQuestionnaireDocs.formW8B) <=
                      5 && (
                      <iframe
                        src={`data:application/pdf;base64,${applicantQuestionnaireDocs.formW8B}`}
                        frameborder="0"
                      ></iframe>
                    )}
                  </Col>
                </Row>
              )}

              {applicantQuestionnaireDocs.beneficiary && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col
                    xs={{ span: 3, offset: 2 }}
                    className="text-left d-flex flex-column gap-2"
                  >
                    <b>Ultimate Beneficial Owner (UBO) Declaration (If any)</b>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        const pdfTab = window.open("", "_blank");
                        pdfTab.document.write(
                          `
                         <html>
                            <head>
                              <title>
                                Ultimate Beneficial Owner (UBO) Declaration (If any)
                              </title>
                            </head>
                            <body>
                              <iframe
                                width="100%"
                                height="100%"
                                src="data:application/pdf;base64,${applicantQuestionnaireDocs.beneficiary}"
                                frameborder="0"
                              ></iframe>
                            </body>
                          </html></Row>
                         `
                        );
                        pdfTab.document.close();
                      }}
                      style={{ color: "blue" }}
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open File
                    </a>
                  </Col>
                  <Col className="pl-0" xs={5}>
                    {getFileSizeInMB(applicantQuestionnaireDocs.beneficiary) >
                      5 && <p>File is too large. Click Open File to view</p>}
                    {getFileSizeInMB(applicantQuestionnaireDocs.beneficiary) <=
                      5 && (
                      <iframe
                        src={`data:application/pdf;base64,${applicantQuestionnaireDocs.beneficiary}`}
                        frameborder="0"
                      ></iframe>
                    )}
                  </Col>
                </Row>
              )}

              {applicantQuestionnaireDocs.regulation && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col
                    xs={{ span: 3, offset: 2 }}
                    className="text-left d-flex flex-column gap-2"
                  >
                    <b>Proof of regulation</b>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        const pdfTab = window.open("", "_blank");
                        pdfTab.document.write(
                          `
                         <html>
                            <head>
                              <title>
                                Proof of regulation
                              </title>
                            </head>
                            <body>
                              <iframe
                                width="100%"
                                height="100%"
                                src="data:application/pdf;base64,${applicantQuestionnaireDocs.regulation}"
                                frameborder="0"
                              ></iframe>
                            </body>
                          </html></Row>
                         `
                        );
                        pdfTab.document.close();
                      }}
                      style={{ color: "blue" }}
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open File
                    </a>
                  </Col>
                  <Col className="pl-0" xs={5}>
                    {getFileSizeInMB(applicantQuestionnaireDocs.regulation) >
                      5 && <p>File is too large. Click Open File to view</p>}
                    {getFileSizeInMB(applicantQuestionnaireDocs.regulation) <=
                      5 && (
                      <iframe
                        src={`data:application/pdf;base64,${applicantQuestionnaireDocs.regulation}`}
                        frameborder="0"
                      ></iframe>
                    )}
                  </Col>
                </Row>
              )}

              {applicantQuestionnaireDocs.registerOfDirectors && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col
                    xs={{ span: 3, offset: 2 }}
                    className="text-left d-flex flex-column gap-2"
                  >
                    <b>Register of Directors</b>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        const pdfTab = window.open("", "_blank");
                        pdfTab.document.write(
                          `
                         <html>
                            <head>
                              <title>
                                Register of Directors
                              </title>
                            </head>
                            <body>
                              <iframe
                                width="100%"
                                height="100%"
                                src="data:application/pdf;base64,${applicantQuestionnaireDocs.registerOfDirectors}"
                                frameborder="0"
                              ></iframe>
                            </body>
                          </html></Row>
                         `
                        );
                        pdfTab.document.close();
                      }}
                      style={{ color: "blue" }}
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open File
                    </a>
                  </Col>
                  <Col className="pl-0" xs={5}>
                    {getFileSizeInMB(
                      applicantQuestionnaireDocs.registerOfDirectors
                    ) > 5 && <p>File is too large. Click Open File to view</p>}
                    {getFileSizeInMB(
                      applicantQuestionnaireDocs.registerOfDirectors
                    ) <= 5 && (
                      <iframe
                        src={`data:application/pdf;base64,${applicantQuestionnaireDocs.registerOfDirectors}`}
                        frameborder="0"
                      ></iframe>
                    )}
                  </Col>
                </Row>
              )}

              {applicantQuestionnaireDocs.authorisedSignatory && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col
                    xs={{ span: 3, offset: 2 }}
                    className="text-left d-flex flex-column gap-2"
                  >
                    <b>Authorised Signatory List</b>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        const pdfTab = window.open(
                          `data:application/pdf;base64,${applicantQuestionnaireDocs.authorisedSignatory}`,
                          "_blank"
                        );
                        pdfTab.document.write(
                          `
                         <html>
                            <head>
                              <title>
                                Authorised Signatory List
                              </title>
                            </head>
                            <body>
                              <iframe
                                width="100%"
                                height="100%"
                                src="data:application/pdf;base64,${applicantQuestionnaireDocs.authorisedSignatory}"
                                frameborder="0"
                              ></iframe>
                            </body>
                          </html></Row>
                         `
                        );
                        pdfTab.document.close();
                      }}
                      style={{ color: "blue" }}
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open File
                    </a>
                  </Col>
                  <Col className="pl-0" xs={5}>
                    {getFileSizeInMB(
                      applicantQuestionnaireDocs.authorisedSignatory
                    ) > 5 && <p>File is too large. Click Open File to view</p>}
                    {getFileSizeInMB(
                      applicantQuestionnaireDocs.authorisedSignatory
                    ) <= 5 && (
                      <iframe
                        id="ss"
                        src={`data:application/pdf;base64,${applicantQuestionnaireDocs.authorisedSignatory}`}
                        frameborder="0"
                      ></iframe>
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}

          {/* <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {}}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Approve"
                type="submit"
              />
            </Col>
          </Row> */}
        </Row>
      </div>
    );
  } else if (modalView === 7) {
    heading = `Filter Applicants By ${filterType}`;
    message = (
      <Row className="ml-1 align-items-center">
        <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
          <Col xs={{ span: 3, offset: 2 }} className="text-left">
            {filterType.toLowerCase() === "name" && <b>Name</b>}
            {filterType.toLowerCase() === "status" && <b>Status</b>}
            {filterType.toLowerCase() === "date" && <b>From Date</b>}
          </Col>
          <Col className="pl-0" xs={5}>
            {filterType.toLowerCase() === "name" && (
              <TextInput
                placeholder="Applicant name"
                onChange={(e) => setFilterContent(e.target.value)}
                value={filterContent}
              />
            )}
            {filterType.toLowerCase() === "status" && (
              <Select
                options={[
                  {
                    value: "1",
                    label: "1 (Pending)",
                  },
                  {
                    value: "2",
                    label: "2 (Rejected)",
                  },
                  // {
                  //   value: "3",
                  //   label: "3 (Accepted)",
                  // },
                ]}
                isMulti={false}
                value={filterContent}
                onChange={(selected) => {
                  setFilterContent(selected);
                }}
              />
            )}
            {filterType.toLowerCase() === "date" && (
              <DateTimePicker onChange={onChange} value={value} />
            )}
          </Col>
        </Row>
        {filterType.toLowerCase() === "date" && (
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>To Date</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DateTimePicker onChange={onChange2} value={value2} />
            </Col>
          </Row>
        )}

        <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
          <Col className="pl-0">
            <UiButton
              onClick={async () => {
                if (filterType.toLowerCase() === "name") {
                  const newData = processedData?.filter((data) => {
                    if (
                      data &&
                      data.fullName
                        .toLowerCase()
                        .includes(filterContent.toLowerCase())
                    ) {
                      return data;
                    }
                  });
                  setNewData(newData);
                }
                if (filterType.toLowerCase() === "date") {
                  const newData = processedData?.filter((data) => {
                    if (
                      data &&
                      Number(data.createdTimeRaw) >=
                        Number(Math.floor(value.getTime() / 1000)) &&
                      Number(data.createdTimeRaw) <=
                        Number(Math.floor(value2.getTime() / 1000))
                    ) {
                      return data;
                    }
                  });

                  setNewData(newData);
                }
                if (filterType.toLowerCase() === "status") {
                  const newData = processedData?.filter((data) => {
                    if (
                      data &&
                      Number(data.status) === Number(filterContent.value)
                    ) {
                      return data;
                    }
                  });

                  setNewData(newData);
                }
                setShowModal(false);
                setModalView(0);
                setFilterContent(null);
              }}
              buttonVariant="primary"
              buttonClass="SignUpButton flex-1 ml-0"
              buttonText="Filter"
              type="submit"
            />
          </Col>
        </Row>
      </Row>
    );
  }

  let clientContract;
  if (provider && provider.getSigner && chainId && address) {
    clientContract = new Client(
      provider.getSigner(address),
      contractAddress[chainId].Client
    );
  }
  return (
    <>
      {kycLoading || generalLoading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="w-100">
          <div className="mb-3 d-flex flex-column justify-content-between w-100 align-items-flex-start">
            <h3 className="w-50 text-left">Pending KYC</h3>
            <div className="d-flex flex-row justify-content-between w-100 align-items-flex-start">
              <div className="w-100 text-left">
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    filterType === "" ? "Filter" : `Filtered By ${filterType}`
                  }
                >
                  {filterType !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        setFilterType("");
                        setFilterContent(null);
                        setNewData(null);
                      }}
                    >
                      Clear Filter
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType("Name");
                      setModalView(7);
                      setShowModal(true);
                    }}
                  >
                    By name
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType("Date");
                      setModalView(7);
                      setShowModal(true);
                    }}
                  >
                    By date
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType("Status");
                      setModalView(7);
                      setShowModal(true);
                    }}
                  >
                    By status
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>
          <div className="pools-table width-100">
            {!newData && (
              <UiTable
                thead={headers}
                tbodyData={processedData ?? []}
                hover
                provider={provider}
                transport={transport}
                account={account}
                chainId={chainId}
                commonProps={commonProps}
                networkOptions={networkOptions}
                contract={clientContract}
                bordered={false}
              />
            )}
            {newData && (
              <UiTable
                thead={headers}
                tbodyData={newData}
                hover
                provider={provider}
                transport={transport}
                account={account}
                chainId={chainId}
                bordered={false}
                commonProps={commonProps}
              />
            )}
          </div>
        </div>
      </section>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>{heading}</b>
          </h2>
        }
        withFooter={false}
      >
        {message}
      </VerticallyModal>
    </>
  );
}

export default function ApprovedKYC(props) {
  return <KYCList {...props} />;
}
