import { useState } from "react";
import Loader from "../../../components/loader";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Secondary from "./secondary";
import VerticallyModal from "../../../components/modal/VerticallyModal";
import UiButton from "../../../components/button";
import { Button, Row, Col } from "react-bootstrap";
import AddIssue from "../createSecondaryIssue/issue";
import AddSecondaryBalance from "../createSecondaryIssue/balance";
import AddOffer from "../createSecondaryIssue/offer";
import { useGlobal } from "../../../context/globalContext";

function ServicerFacing(props) {
  const { userRole } = useGlobal();
  const [showModal, setShowModal] = useState(false);
  const [showExistingModal, setShowExistingModal] = useState(false);
  const [modalView, setModalView] = useState(1);
  const [existingModalView, setExistingModalView] = useState(2);

  const handleModalHide = () => {
    setModalView(1);
    setShowModal(false);
  };
  const handleExistingModalHide = () => {
    setShowExistingModal(false);
    setExistingModalView(2);
  };

  const [security, setSecurity] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isinValue, setIsinValue] = useState("");
  const [currency, setCurrency] = useState("Select Currency");
  const [investorAddress, setInvestorAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [productType, setProductType] = useState("Select type");
  const [productCategory, setProductCategory] = useState("Select category");
  const [intermediary, setIntermediary] = useState();

  const commonProps = {
    companyName,
    updateCompanyName: setCompanyName,
    isinValue,
    updateIsinValue: setIsinValue,
    security,
    updateSecurity: setSecurity,
    currency,
    intermediary,
    updateIntermediary: setIntermediary,
    updateCurrency: setCurrency,
    investorAddress,
    updateInvestorAddress: setInvestorAddress,
    productType,
    updateProductType: setProductType,
    amount,
    updateAmount: setAmount,
    productCategory,
    updateProductCategory: setProductCategory,
    modalView,
    updateModalView: setModalView,
    onModalHide: handleModalHide,
    existingModalView,
    updateExistingModalView: setExistingModalView,
    onIssuedModalHide: handleExistingModalHide,
  };

  let message;
  let heading;
  let existingMessage;
  let existingHeading;

  if (modalView === 1) {
    heading = "New Secondary Issue";
    message = <AddIssue {...commonProps} />;
  } else if (modalView === 2) {
    heading = "Add Balance";
    message = <AddSecondaryBalance {...commonProps} />;
  } else if (modalView === 3) {
    heading = "Add Issue";
    message = <AddOffer {...commonProps} />;
  }

  if (existingModalView === 2) {
    existingHeading = "Add Balance For Existing Security";
    existingMessage = (
      <AddSecondaryBalance existingIssue={true} {...commonProps} />
    );
  } else if (existingModalView === 3) {
    existingHeading = "Add Issue";
    existingMessage = <AddOffer existingIssue={true} {...commonProps} />;
  }

  return (
    <>
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-100">
            <h3 className="text-left">Secondary Issues</h3>
          </div>
          {userRole === "DP" && (
            <div className="w-100 text-right">
              <DropdownButton id="dropdown-basic-button" title="Issue new">
                <Dropdown.Item
                  onClick={() => {
                    setExistingModalView(2);
                    setShowExistingModal(true);
                  }}
                >
                  Use existing security token
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setModalView(1);
                    setShowModal(true);
                  }}
                >
                  Create new security token
                </Dropdown.Item>
              </DropdownButton>
            </div>
          )}
        </div>
        <div className="w-100">
          <Secondary {...props} />
        </div>
      </section>
      <VerticallyModal
        key="existingConnectProvider"
        showModal={showExistingModal}
        modalOnHide={handleExistingModalHide}
        modalSize={existingModalView === 0 ? "md" : "lg"}
        modalHeading={
          <h2>
            <b>{existingHeading}</b>
          </h2>
        }
        withFooter={false}
      >
        {existingMessage}
      </VerticallyModal>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={modalView === 0 ? "md" : "lg"}
        modalHeading={
          <h2>
            <b>{heading}</b>
          </h2>
        }
        withFooter={false}
      >
        {message}
      </VerticallyModal>
    </>
  );
}

export default function ManagerSecondary(props) {
  return <ServicerFacing {...props} />;
}
