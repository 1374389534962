import React, { useState } from "react";
import Loader from "../../../components/loader";
import { PoolType } from "../../poolDetail/balancer/types";
import Web3 from "web3";

import { useGlobal } from "../../../context/globalContext";
import { useResolutionsData } from "../../../context/contractDataLoaderService";
import UiTable from "../../../components/table/Table";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function ServicerHome(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { data, loading } = useResolutionsData("PrimaryIssue");
  const { userRole } = useGlobal();

  let headers = [
    { label: "Record date", val: "date" },
    { label: "Security", val: "securityTokenSymbol" },
    { label: "Resolution", val: "offeringDocs" },
    { label: "Total votes", val: "totalVotes" },
    { label: "Total positives", val: "totalPositives" },
  ];

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-100">
            <h3 className="text-left">Primary resolutions</h3>
          </div>
        </div>
        <div className="w-100">
          <div className="pools-table width-100">
            <UiTable
              thead={headers}
              tbodyData={data || []}
              hover
              bordered={false}
              userRole={userRole}
              chainId={chainId}
              web3={web3}
              account={account}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default function PrimaryResolutions(props) {
  return <ServicerHome {...props} />;
}
