import Loader from "../../../components/loader";
import { useBalancerPoolsData } from "../../../context/balancerPoolData";
import {
  useFetchMarginOrders,
  useMatchedTrades,
  usePendingOrderData,
  useTradeDetails,
} from "../../../context/contractDataLoaderService";
import OrdersTable from "./orders_table";
import WalletConnect from "../../../components/common/walletConnect/index";
import { PoolType } from "../../poolDetail/balancer/types";

import { useEffect, useState } from "react";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function PendingOrders(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [allOrders, setAllOrders] = useState([]);
  const [generalLoading, setGeneralLoading] = useState(true);
  let { data: marginPoolsData, loading: marginLoading } = useBalancerPoolsData(
    PoolType.MarginIssue
  );
  let { marginOrders: marginOrdersData, loading: marginOrdersLoading } =
    useFetchMarginOrders(marginPoolsData);

  let { data: primaryTradeDetails, loading: tradeDetailsLoading } =
    useTradeDetails();
  let { data: secondaryPoolsData, loading: secondaryLoading } =
    useBalancerPoolsData(PoolType.SecondaryIssue);

  let { pendingOrdersData } = usePendingOrderData(secondaryPoolsData);

  let formattedData = primaryTradeDetails
    ? [...marginOrdersData, ...pendingOrdersData, ...primaryTradeDetails]
    : [...marginOrdersData, ...pendingOrdersData];

  return (
    <>
      {tradeDetailsLoading || marginOrdersLoading || secondaryLoading ? (
        <Loader />
      ) : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="w-100">
          <h3 className="mb-3 d-flex">Orders</h3>
          <OrdersTable
            {...props}
            data={formattedData || []}
            account={account}
            provider={provider}
            transport={transport}
            chainId={chainId}
          />
        </div>
      </section>
      <WalletConnect loggedIn={props.loggedIn} />
    </>
  );
}

export default function Orders(props) {
  return <PendingOrders {...props} />;
}
