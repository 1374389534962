import React, { useRef, useState } from "react";
import Loader from "../../components/loader";
import WalletConnect from "../../components/common/walletConnect/index";
import { PoolType } from "../poolDetail/balancer/types";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import MarginIssueTable from "../../components/table/MarginIssueTable";
import UiTable from "../../components/table/Table";
import TextInput from "../../components/textinput/TextInput";
import VerticallyModal from "../../components/modal/VerticallyModal";
import UiButton from "../../components/button";
import {
  MarginIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";

import { toast } from "react-toastify";
import {
  OneYearAsSeconds,
  SuccessToast,
  ToastOptions,
  networks,
} from "../../utils/constants";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { useGetMarginSecurities } from "../../context/contractDataLoaderService";
import KYCModal from "../../components/common/kycModal";
import AddSecondaryBalance from "../primaries/balance";
import CreateProduct from "./marginProduct";
import { useAccount, useConnectorClient } from "wagmi";
import {
  getProvider,
  getProviderNetwork,
  getWeb3,
} from "../../utils/helpers/networks";

function ManageIssue(props) {
  const { data: marginProducts, loading } = useGetMarginSecurities();
  const [showKYCModal, setShowKYCModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const modalContent = useRef(null);
  const [modalView, setModalView] = useState(0);
  const [accessToken, setAccessToken] = useState("");
  const [productCategory, setProductCategory] = useState("CFD");
  const [currency, setCurrency] = useState("");
  const [security, setSecurity] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [securitySymbol, setSecuritySymbol] = useState("");
  const [issuedSecurityModal, setIssuedSecurityModal] = useState(false);

  const commonProps = {
    modalView,
    updateModalView: setModalView,
    showModal,
    updateShowModal: setShowModal,
    showKYCModal,
    updateShowKYCModal: setShowKYCModal,
    productCategory,
    updateProductCategory: setProductCategory,
    currency,
    updateCurrency: setCurrency,
    security,
    updateSecurity: setSecurity,
    currencySymbol,
    updateCurrencySymbol: setCurrencySymbol,
    securitySymbol,
    updateSecuritySymbol: setSecuritySymbol,
    issuedSecurityModal,
    setIssuedSecurityModal,
  };

  const headers = [
    { label: "Security", val: "securitySymbol" },
    { label: "Security Category", val: "securityCategory" },
    { label: "Currency", val: "currencySymbol" },
    { label: "Call Action", val: "marginManageAction" },
  ];

  const handleModalHide = () => {
    setModalView(0);
    setShowModal(false);
  };

  let message, heading;
  if (modalView === 4) {
    heading = "Issue Product";
    message = <CreateProduct existingIssue={false} {...commonProps} />;
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-100">
            <h3 className="text-left">Margin Products</h3>
          </div>
        </div>
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={marginProducts || []}
            hover
            bordered={false}
            commonProps={commonProps}
            isUpdate
            chainId={props.chainId}
          />
        </div>
      </section>
      <WalletConnect loggedIn={props.loggedIn} />
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>{heading}</b>
          </h2>
        }
        withFooter={false}
      >
        {message}
      </VerticallyModal>
      {showKYCModal && (
        <KYCModal
          accessToken={accessToken}
          setshowKYCModal={setShowKYCModal}
          showKYCModal={showKYCModal}
        />
      )}
    </>
  );
}

export default function ManageMarginIssues(props) {
  return <ManageIssue {...props} />;
}
