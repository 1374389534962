import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "../../components/loader";
import UiButton from "../../components/button";
import TextInput from "../../components/textinput/TextInput";
import {
  SecuritiesFactory,
  contractAddress,
} from "@verified-network/verified-sdk";
import Web3 from "web3";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import Select from "react-select";
import {
  useSupportedTokens,
  useIntermediaryList,
} from "../../context/contractDataLoaderService";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  NULL_ADDRESS,
  countryList,
  getCountryName,
  getCountryCode,
  productCategory,
  networks,
  MarginIssueCategoryMarkers,
  countryDetails,
  supportedCountries,
} from "../../utils/constants";
import { shortAddress } from "../../components/common/CommonHeader";
import { useGlobal, fetchSumsubAccessToken } from "../../context/globalContext";
import { toast } from "react-toastify";
import { ethers, providers } from "ethers";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { getKycStatus } from "../../utils/helpers";

function ExistingIssue(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { kycStatus } = useGlobal();
  const [custodian, setCustodian] = useState(null);
  const productType = ["Qualified", "Retail"];
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  let { data: intermediaryList } = useIntermediaryList(country);
  const [restrictedCountries, setRestrictedCountries] = useState([]);
  const [showCustodian, setShowCustodian] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const { data: custodianList } = useIntermediaryList(country, "Custodian");
  const abiCoder = new ethers.utils.AbiCoder();
  let marginIntermediaryList = [];

  const handleModalHide = () => {
    setShowModal(false);
  };

  let { data } = useSupportedTokens();
  let factoryContract;

  if (provider && provider.getSigner && chainId && address) {
    factoryContract = new SecuritiesFactory(
      provider.getSigner(address),
      contractAddress[chainId].SecuritiesFactory
    );
  }
  useEffect(() => {
    if (props.intermediary) {
      if (props.intermediary.address.toLowerCase() === account.toLowerCase())
        setShowCustodian(true);
      else setShowCustodian(false);
    }
  }, [props.intermediary]);

  if (intermediaryList && intermediaryList.length > 0) {
    marginIntermediaryList = intermediaryList.filter(
      (manager) => manager.address.toLowerCase() !== account.toLowerCase()
    );
  }

  const addExistingIssue = async () => {
    const userKycStatus = await getKycStatus(address, chainId, signer);
    if (userKycStatus !== 3) {
      const data = await fetchSumsubAccessToken(account, chainId);
      console.log("return");
      props.setShowModal(false);
      props.setshowKYCModal(true);
      props.setAccessToken(data.accessToken);
      return;
    }
    if (!custodian && showCustodian) {
      toast.error(
        FailureToast("", "No custodian present for country selected!"),
        ToastOptions
      );
      return;
    }
    if (!web3.utils.isAddress(props.companyName)) {
      toast.error(
        FailureToast("", "Enter correct security address"),
        ToastOptions
      );
      return;
    }
    if (companyName.length == 0) {
      toast.error(FailureToast("", "Enter Company name"), ToastOptions);
      return;
    } else if (companyName.length > 32) {
      toast.error(
        FailureToast("", "Keep company name length < 32"),
        ToastOptions
      );
      return;
    } else if (props.isinValue.length == 0) {
      toast.error(FailureToast("", "Enter company symbol"), ToastOptions);
      return;
    } else if (props.productType == "Select type") {
      toast.error(FailureToast("", "Select product type"), ToastOptions);
      return;
    } else if (props.productCategory == "Select category") {
      toast.error(FailureToast("", "Select product category"), ToastOptions);
      return;
    } else if (country.length == 0) {
      toast.error(FailureToast("", "Select issuer country"), ToastOptions);
      return;
    } else if (!props.intermediary) {
      toast.error(FailureToast("", "Select issue manager"), ToastOptions);
      return;
    }

    let securityTokenAddress, currencyTokenAddress;
    if (props.existingIssue) {
      securityTokenAddress = props.companyName;
      currencyTokenAddress = props.currency;
    }
    let selectedCurrency;

    selectedCurrency = data.filter(
      (token) => token.tokenName === props.currency
    );
    const productCategory = ethers.utils.formatBytes32String(
      props.productCategory
    );
    const issuingCountry = ethers.utils.formatBytes32String(country);
    const restrictedCountryList = restrictedCountries.map((countryName) => {
      if (countryName.code !== "") {
        return ethers.utils.formatBytes32String(countryName.code);
      }
    });

    const restrictedCountryListEncoded = abiCoder.encode(
      ["bytes32[]"],
      [restrictedCountryList]
    );
    // console.log("props", props);
    setLoading(true);
    try {
      const tx = await factoryContract.issueSecurity(
        securityTokenAddress,
        productCategory,
        companyName,
        props.isinValue,
        selectedCurrency[0].tokenAddress,
        account,
        props.intermediary.address,
        restrictedCountryListEncoded,
        issuingCountry,
        props.productType == "Qualified" ? 1 : 0,
        { gasLimit: "2000000" }
      );
      const response = await factoryContract.getIssues();
      const securityAddress = response.response.result.at(-1);
      console.log("securityAddress", securityAddress);
      props.updateSecurity(securityAddress);

      if (showCustodian) {
        const custodianTX = await factoryContract.setCustodian(
          securityAddress,
          account,
          custodian,
          { gasLimit: "2000000" }
        );
        if (custodianTX.code === "ACTION_REJECTED") {
          toast.error("Transaction rejected by user!", ToastOptions);
        } else {
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
          props.setIssuedModalView(2);
        }
      }
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        if (!MarginIssueCategoryMarkers.includes(props.productCategory)) {
          props.setIssuedModalView(2);
        } else {
          props.setIssuedModalView(5);
        }
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="d-grid gap-2">
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Security Token Address</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Address"
                required
                onChange={(e) => props.updateCompanyName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Security Name</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Name"
                value={companyName}
                required
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Security Symbol</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Security token symbol"
                value={props.isinValue}
                required
                onChange={(e) => props.updateIsinValue(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Currency to pair</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton id="dropdown-basic-button" title={props.currency}>
                {data?.map((token) => (
                  <Dropdown.Item
                    onClick={(e) => props.updateCurrency(token?.tokenName)}
                  >
                    {token?.tokenName}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Target client</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={props.productType}
              >
                {productType?.map((type, id) => (
                  <Dropdown.Item
                    key={id}
                    onClick={(e) => props.updateProductType(type)}
                  >
                    {type}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Product Category</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={props.productCategory}
              >
                {productCategory?.map((type) => (
                  <Dropdown.Item
                    onClick={(e) => props.updateProductCategory(type)}
                  >
                    {type}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Country</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <Select
                options={supportedCountries.map((country, id) => {
                  return {
                    value: country.name,
                    label: country.name,
                    code: country.code,
                  };
                })}
                isMulti={false}
                value={countryName}
                onChange={(selected) => {
                  setCountry(selected.code);
                  setCountryName(selected.name);
                }}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Restricted Countries</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <Select
                options={countryDetails.map((cont) => {
                  if (cont.code.toLowerCase() !== country.toLowerCase()) {
                    return {
                      value: cont.name,
                      label: cont.name,
                      code: cont.code,
                    };
                  } else {
                    return {
                      value: "",
                      label: "",
                      code: "",
                    };
                  }
                })}
                isMulti
                value={restrictedCountries}
                onChange={(selected) => setRestrictedCountries(selected)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Issue manager</b>
            </Col>
            {MarginIssueCategoryMarkers.includes(props.productCategory) && (
              <Col className="pl-0" xs={5}>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    props.intermediary
                      ? shortAddress(props.intermediary.address)
                      : "Select issue manager"
                  }
                >
                  {marginIntermediaryList?.map((manager) => (
                    <Dropdown.Item
                      key={manager.address}
                      onClick={(e) => props.updateIntermediary(manager)}
                    >
                      <b>{manager.name}</b>&nbsp;(
                      {shortAddress(manager.address)})
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            )}
            {!MarginIssueCategoryMarkers.includes(props.productCategory) && (
              <Col className="pl-0" xs={5}>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    props.intermediary
                      ? shortAddress(props.intermediary.address)
                      : "Select issue manager"
                  }
                >
                  {intermediaryList?.map((manager) => (
                    <Dropdown.Item
                      key={manager.address}
                      onClick={(e) => props.updateIntermediary(manager)}
                    >
                      <b>{manager.name}</b>&nbsp;(
                      {shortAddress(manager.address)})
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            )}
          </Row>
          {showCustodian && (
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 3, offset: 2 }} className="text-left">
                <b>Custodian</b>
              </Col>
              <Col className="pl-0" xs={5}>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    custodianList
                      ? custodianList.length
                        ? custodian
                          ? shortAddress(custodian)
                          : "Select custodian"
                        : "Select custodian"
                      : null
                  }
                >
                  {custodianList ? (
                    custodianList.length ? (
                      custodianList?.map((manager) => (
                        <Dropdown.Item
                          key={manager.address}
                          onClick={(e) => setCustodian(manager.address)}
                        >
                          <b>{manager.name}</b>&nbsp;(
                          {shortAddress(manager.address)})
                        </Dropdown.Item>
                      ))
                    ) : (
                      <span className="p-2">
                        {country ? "No custodian present" : "Select a country"}
                      </span>
                    )
                  ) : null}
                </DropdownButton>
              </Col>
            </Row>
          )}
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0" xs={4} />
            <Col className="pl-0" xs={4}>
              <UiButton
                onClick={() => {
                  addExistingIssue();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Create"
                type="submit"
              />
              <Col className="pl-0" xs={4} />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export default function AddExistingIssue(props) {
  return <ExistingIssue {...props} />;
}
