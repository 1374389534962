import React from "react";
import Loader from "../../components/loader";
import { useBalancerPoolsData } from "../../context/balancerPoolData";
import PoolsTable from "../poolDetail/PoolsTable";
import WalletConnect from "../../components/common/walletConnect/index";
import { PoolType } from "../poolDetail/balancer/types";
import { useGlobal } from "../../context/globalContext";

function SecondariesPage(props) {
  let { data: balancerPools, loading } = useBalancerPoolsData(
    PoolType.SecondaryIssue
  );

  const { userType, userCountry, userCountryCode } = useGlobal();
  balancerPools = balancerPools.filter(
    (pool) =>
      !pool.restrictedCountries?.includes(userCountryCode) &&
      pool.verifiedWalletData &&
      !pool.verifiedWalletData?.subscriptionsClosed.length
  );

  // console.log("listtt: ", balancerPools);

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-100">
            <h3 className="text-left">Secondaries</h3>
          </div>
        </div>
        <PoolsTable {...props} data={balancerPools || []} />
      </section>
      <WalletConnect loggedIn={props.loggedIn} />
    </>
  );
}

export default function Secondaries(props) {
  return <SecondariesPage {...props} />;
}
