import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Loader from "../../components/loader";
import Web3 from "web3";
import { useLocation } from "react-router-dom";
import UiButton from "../../components/button";
import {
  Liquidity,
  TokenContract,
  contractAddress,
} from "@verified-network/verified-sdk";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
} from "../../utils/constants";
import "react-pro-sidebar/dist/css/styles.css";
import "../../styles/sidebar.css";
import { useGlobal } from "../../context/globalContext";
import config from "../../services/config/homestead.json";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProvider,
  getProviderNetwork,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

function SidebarMenu(props) {
  const { address, isConnected, chainId, isConnecting } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { login, userRole, isSecondaryManager } = useGlobal();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const { toggled, collapsed, handleToggleSidebar } = props;
  const redirectTo = (url) => {
    props.history.push(url);
  };

  const redirectToNewPage = (ipfsLink) => {
    window.open(ipfsLink, "_blank");
  };

  return (
    <>
      <ToastContainer />
      {loading ? <Loader /> : null}
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="lg"
        onToggle={handleToggleSidebar}
        className="card mt-0 pt-0"
        style={{ backgroundColor: "white" }}
      >
        <SidebarContent>
          <Menu>
            {userRole == "Admin" ? (
              <>
                <MenuItem
                  onClick={() => redirectTo("/issues")}
                  className={
                    location.pathname === "/issues" ? "selectedNetwork" : null
                  }
                >
                  Fees
                </MenuItem>
                <MenuItem
                  onClick={() => redirectTo("/tokens")}
                  className={
                    location.pathname === "/tokens" ? "selectedNetwork" : null
                  }
                >
                  Liquidity providers
                </MenuItem>
                <MenuItem
                  onClick={() => redirectTo("/platforms")}
                  className={
                    location.pathname === "/platforms"
                      ? "selectedNetwork"
                      : null
                  }
                >
                  Platforms
                </MenuItem>
                <MenuItem
                  onClick={() => redirectTo("/revenue-shares")}
                  className={
                    location.pathname === "/revenue-shares"
                      ? "selectedNetwork"
                      : null
                  }
                >
                  Revenue shares
                </MenuItem>
                <MenuItem
                  onClick={() => redirectTo("/configuration")}
                  className={
                    location.pathname === "/configuration"
                      ? "selectedNetwork"
                      : null
                  }
                >
                  Configuration
                </MenuItem>
              </>
            ) : userRole == "AM" ? (
              <>
                <SubMenu title="Underwrite Liquidity">
                  <MenuItem
                    onClick={() => redirectTo("/buy-liquidity")}
                    className={
                      location.pathname === "/buy-liquidity"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Provide underwriting liquidity
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/distributions")}
                    className={
                      location.pathname === "/distributions"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Distributions
                  </MenuItem>
                </SubMenu>

                <SubMenu title="Primary Issues">
                  <MenuItem
                    onClick={() => redirectTo("/offers")}
                    className={
                      location.pathname === "/offers" ? "selectedNetwork" : null
                    }
                  >
                    Offers
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/closed_issues")}
                    className={
                      location.pathname === "/closed_issues"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Earnings
                  </MenuItem>
                </SubMenu>
                <SubMenu title="Secondary Issues">
                  <MenuItem
                    onClick={() => redirectTo("/secondary_issues")}
                    className={
                      location.pathname === "/secondary_issues"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Issues
                  </MenuItem>
                </SubMenu>
              </>
            ) : userRole == "DP" ? (
              <>
                <MenuItem
                  onClick={() => redirectTo("/offers")}
                  className={
                    location.pathname === "/offers" ? "selectedNetwork" : null
                  }
                >
                  Offers
                </MenuItem>
                <SubMenu title="Primary Issues">
                  <MenuItem
                    onClick={() => redirectTo("/active_issues")}
                    className={
                      location.pathname === "/active_issues"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Active Issues
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/closed_issues")}
                    className={
                      location.pathname === "/closed_issues"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Closed Issues
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/primary_resolutions")}
                    className={
                      location.pathname === "/primary_resolutions"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Resolutions
                  </MenuItem>
                </SubMenu>
                <SubMenu title="Secondary Issues">
                  <MenuItem
                    onClick={() => redirectTo("/secondary_issues")}
                    className={
                      location.pathname === "/secondary_issues"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Issues
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/secondary_transactions")}
                    className={
                      location.pathname === "/secondary_transactions"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Transactions
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/secondary_resolutions")}
                    className={
                      location.pathname === "/secondary_resolutions"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Resolutions
                  </MenuItem>
                </SubMenu>
                <SubMenu title="Margin Issues">
                  <MenuItem
                    onClick={() => redirectTo("/marginissues")}
                    className={
                      location.pathname === "/marginissues"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Issues
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/managemarginissues")}
                    className={
                      location.pathname === "/managemarginissues"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Manage Issues
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/margintransactions")}
                    className={
                      location.pathname === "/margintransactions"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Transactions
                  </MenuItem>
                </SubMenu>
                <SubMenu title="Underwrite Liquidity">
                  <MenuItem
                    onClick={() => redirectTo("/buy-liquidity")}
                    className={
                      location.pathname === "/buy-liquidity"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Provide underwriting liquidity
                  </MenuItem>
                </SubMenu>
              </>
            ) : userRole == "Custodian" ? (
              <>
                <MenuItem
                  onClick={() => redirectTo("/issue_requests")}
                  className={
                    location.pathname === "/issue_requests"
                      ? "selectedNetwork"
                      : null
                  }
                >
                  Issue Requests
                </MenuItem>
                <MenuItem
                  onClick={() => redirectTo("/withdrawl_requests")}
                  className={
                    location.pathname === "/approval_requests"
                      ? "selectedNetwork"
                      : null
                  }
                >
                  Withdrawal Requests
                </MenuItem>
              </>
            ) : userRole == "KYCAML" ? (
              <>
                <MenuItem
                  onClick={() => redirectTo("/kyc_approvals")}
                  className={
                    location.pathname === "/kyc_approvals"
                      ? "selectedNetwork"
                      : null
                  }
                >
                  Approvals
                </MenuItem>
                <MenuItem
                  onClick={() => redirectTo("/kyc_processed")}
                  className={
                    location.pathname === "/kyc_processed"
                      ? "selectedNetwork"
                      : null
                  }
                >
                  Processed
                </MenuItem>
              </>
            ) : userRole == "investor" || "intermediary" ? (
              <>
                {userRole == "intermediary" ? (
                  <>
                    <SubMenu title="Primaries">
                      <MenuItem
                        onClick={() => redirectTo("/")}
                        className={
                          location.pathname === "/" ? "selectedNetwork" : null
                        }
                      >
                        Issues
                      </MenuItem>
                      <SubMenu title="Manage Issues">
                        {/*<MenuItem onClick={() =>  redirectTo("/offers")} className={location.pathname === '/offers' ? "selectedNetwork" : null}>
                            Offers
                          </MenuItem>*/}
                        <MenuItem
                          onClick={() => redirectTo("/active_issues")}
                          className={
                            location.pathname === "/active_issues"
                              ? "selectedNetwork"
                              : null
                          }
                        >
                          Active Issues
                        </MenuItem>
                        <MenuItem
                          onClick={() => redirectTo("/closed_issues")}
                          className={
                            location.pathname === "/closed_issues"
                              ? "selectedNetwork"
                              : null
                          }
                        >
                          Closed Issues
                        </MenuItem>
                        <MenuItem
                          onClick={() => redirectTo("/primary_resolutions")}
                          className={
                            location.pathname === "/primary_resolutions"
                              ? "selectedNetwork"
                              : null
                          }
                        >
                          Resolutions
                        </MenuItem>
                      </SubMenu>
                    </SubMenu>
                    {/* <SubMenu title="Secondaries">
                      <MenuItem
                        onClick={() => redirectTo("/secondaries")}
                        className={
                          location.pathname === "/secondaries"
                            ? "selectedNetwork"
                            : null
                        }
                      >
                        Issues
                      </MenuItem>
                      {isSecondaryManager && (
                        <SubMenu title="Manage Issues">
                          <MenuItem
                            onClick={() => redirectTo("/secondary_issues")}
                            className={
                              location.pathname === "/secondary_issues"
                                ? "selectedNetwork"
                                : null
                            }
                          >
                            Issues
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              redirectTo("/secondary_transactions")
                            }
                            className={
                              location.pathname === "/secondary_transactions"
                                ? "selectedNetwork"
                                : null
                            }
                          >
                            Transactions
                          </MenuItem>
                          <MenuItem
                            onClick={() => redirectTo("/secondary_resolutions")}
                            className={
                              location.pathname === "/secondary_resolutions"
                                ? "selectedNetwork"
                                : null
                            }
                          >
                            Resolutions
                          </MenuItem>
                        </SubMenu>
                      )}
                    </SubMenu> */}
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => redirectTo("/")}
                      className={
                        location.pathname === "/" ? "selectedNetwork" : null
                      }
                    >
                      New Issues
                    </MenuItem>
                    <MenuItem
                      onClick={() => redirectTo("/secondaries")}
                      className={
                        location.pathname === "/secondaries"
                          ? "selectedNetwork"
                          : null
                      }
                    >
                      Secondaries
                    </MenuItem>
                    <MenuItem
                      onClick={() => redirectTo("/marginproducts")}
                      className={
                        location.pathname === "/marginproducts"
                          ? "selectedNetwork"
                          : null
                      }
                    >
                      Margin traded products
                    </MenuItem>
                  </>
                )}
                <SubMenu title="Order Book">
                  <MenuItem
                    onClick={() => redirectTo("/trades")}
                    className={
                      location.pathname === "/trades" ? "selectedNetwork" : null
                    }
                  >
                    Trades
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/pending-orders")}
                    className={
                      location.pathname === "/pending-orders"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Orders
                  </MenuItem>
                </SubMenu>
                <SubMenu title="Portfolio">
                  <MenuItem
                    onClick={() => redirectTo("/portfolio")}
                    className={
                      location.pathname === "/portfolio"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Account Statement
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/collaterals")}
                    className={
                      location.pathname === "/collaterals"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Collateral
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/pnl")}
                    className={
                      location.pathname === "/pnl" ? "selectedNetwork" : null
                    }
                  >
                    Profit and Loss
                  </MenuItem>
                </SubMenu>
                <SubMenu title="Corporate Actions">
                  <MenuItem
                    onClick={() => redirectTo("/resolutions")}
                    className={
                      location.pathname === "/resolutions"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Resolutions
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/distributions")}
                    className={
                      location.pathname === "/distributions"
                        ? "selectedNetwork"
                        : null
                    }
                  >
                    Distributions
                  </MenuItem>
                </SubMenu>
                <MenuItem
                  onClick={() => redirectTo("/buy-liquidity")}
                  className={
                    location.pathname === "/buy-liquidity"
                      ? "selectedNetwork"
                      : null
                  }
                >
                  Provide underwriting liquidity
                </MenuItem>

                {/* <SubMenu title="Loans">
                  <MenuItem
                    onClick={() => redirectTo("/borrow")}
                    className={
                      location.pathname === "/borrow" ? "selectedNetwork" : null
                    }
                  >
                    Borrow
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectTo("/lend")}
                    className={
                      location.pathname === "/lend" ? "selectedNetwork" : null
                    }
                  >
                    Lend
                  </MenuItem>
                </SubMenu> */}
              </>
            ) : (
              <></>
            )}
          </Menu>
        </SidebarContent>
      </ProSidebar>
      <div className="askHelp">
        <UiButton
          onClick={() => {
            setShowModal(!showModal);
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText={
            showModal ? <span aria-hidden="true">&times;</span> : "Need help?"
          }
          type="submit"
        />
      </div>
      {showModal && (
        <div className="iframe-container">
          <iframe
            src={config.discordLink}
            width="350"
            height="500"
            allowtransparency="true"
            frameborder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </div>
      )}
    </>
  );
}

export default withRouter(SidebarMenu);
