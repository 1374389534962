import { providers } from "ethers";
import Web3 from "web3";

export const ChainId = {
  MAINNET: 1,
  GOERLI: 5,
  BINANCE: 56,
  GNOSIS: 100,
  POLYGON: 137,
  ARBITUM: 42161,
  SEPOLIA: 11155111,
  BASE: 8453,
  BASESEPOLIA: 84532,
};

export const NETWORKS_INFO = {
  [ChainId.MAINNET]: {
    chainId: ChainId.MAINNET,
    name: "Ethereum",
    urlKey: "ethereum",
    dmmSwapUrl: "https://kyberswap.com/",
    subgraphName: "dynamic-amm/dynamic-amm",
    balancerSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/vault-mainnet/version/latest",
    verifiedWalletSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/wallet-mainnet/version/latest",
    kyberSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    subgraphUrls: [
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    ],
    subgraphBlockUrl:
      "https://api.thegraph.com/subgraphs/name/dynamic-amm/ethereum-blocks-ethereum",
    etherscanUrl: "https://etherscan.io",
    kncAddress: "0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202",
    wethAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    defaultStartTime: 1620201600,
    nativeTokenSymbol: "ETH",
    nativeTokenWrappedName: "Ether (Wrapped)",
    etherscanLinkText: "Etherscan",
    tokensListUrl:
      "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/tokenLists/ethereum.tokenlist.json",
    factoryAddress: "0x833e4083B7ae46CeA85695c4f7ed25CDAd8886dE",
  },
  [ChainId.GOERLI]: {
    chainId: ChainId.GOERLI,
    name: "Goerli",
    urlKey: "ethereum",
    dmmSwapUrl: "https://kyberswap.com/",
    subgraphName: "dynamic-amm/dynamic-amm",
    balancerSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/verified-network/balancer",
    verifiedWalletSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/verified-network/payments",
    kyberSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    subgraphUrls: [
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    ],
    subgraphBlockUrl:
      "https://api.thegraph.com/subgraphs/name/dynamic-amm/ethereum-blocks-ethereum",
    etherscanUrl: "https://etherscan.io",
    kncAddress: "0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202",
    wethAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    defaultStartTime: 1620201600,
    nativeTokenSymbol: "ETH",
    nativeTokenWrappedName: "Ether (Wrapped)",
    etherscanLinkText: "Etherscan",
    tokensListUrl:
      "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/tokenLists/ethereum.tokenlist.json",
    factoryAddress: "0x833e4083B7ae46CeA85695c4f7ed25CDAd8886dE",
  },
  [ChainId.BINANCE]: {
    chainId: ChainId.BINANCE,
    name: "BINANCE",
    urlKey: "ethereum",
    dmmSwapUrl: "https://kyberswap.com/",
    subgraphName: "dynamic-amm/dynamic-amm",
    balancerSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/verified-network/vault-bsc",
    verifiedWalletSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/verified-network/wallet-bsc",
    kyberSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    etherscanUrl: "https://etherscan.io",
    kncAddress: "0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202",
    wethAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    defaultStartTime: 1620201600,
    nativeTokenSymbol: "ETH",
    nativeTokenWrappedName: "Ether (Wrapped)",
    etherscanLinkText: "Etherscan",
    tokensListUrl:
      "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/tokenLists/ethereum.tokenlist.json",
    factoryAddress: "0x833e4083B7ae46CeA85695c4f7ed25CDAd8886dE",
  },
  [ChainId.GNOSIS]: {
    chainId: ChainId.GNOSIS,
    name: "GNOSIS",
    urlKey: "ethereum",
    dmmSwapUrl: "https://kyberswap.com/",
    subgraphName: "dynamic-amm/dynamic-amm",
    balancerSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/vault-gnosis/version/latest",
    verifiedWalletSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/wallet-gnosis/version/latest",
    kyberSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    etherscanUrl: "https://etherscan.io",
    kncAddress: "0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202",
    wethAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    defaultStartTime: 1620201600,
    nativeTokenSymbol: "XDAI",
    nativeTokenWrappedName: "Ether (Wrapped)",
    etherscanLinkText: "Etherscan",
    tokensListUrl:
      "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/tokenLists/ethereum.tokenlist.json",
    factoryAddress: "0x833e4083B7ae46CeA85695c4f7ed25CDAd8886dE",
  },
  [ChainId.BASE]: {
    chainId: ChainId.BASE,
    name: "Base Mainnet",
    urlKey: "ethereum",
    dmmSwapUrl: "https://kyberswap.com/",
    subgraphName: "dynamic-amm/dynamic-amm",
    balancerSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/vault-base/version/latest",
    verifiedWalletSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/wallet-base/version/latest",
    kyberSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    subgraphUrls: [
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    ],
    etherscanUrl: "https://basescan.org",
    kncAddress: "0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202",
    wethAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    defaultStartTime: 1620201600,
    nativeTokenSymbol: "ETH",
    nativeTokenWrappedName: "Ether (Wrapped)",
    etherscanLinkText: "Basescan",
    tokensListUrl:
      "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/tokenLists/ethereum.tokenlist.json",
    factoryAddress: "0x833e4083B7ae46CeA85695c4f7ed25CDAd8886dE",
  },
  [ChainId.BASESEPOLIA]: {
    chainId: ChainId.BASESEPOLIA,
    name: "Base Sepolia",
    urlKey: "ethereum",
    dmmSwapUrl: "https://kyberswap.com/",
    subgraphName: "dynamic-amm/dynamic-amm",
    balancerSubgraphUrl: "",
    verifiedWalletSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/wallet-arbitrum/version/latest",
    kyberSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    subgraphUrls: [
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    ],
    etherscanUrl: "https://sepolia.basescan.org",
    kncAddress: "0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202",
    wethAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    defaultStartTime: 1620201600,
    nativeTokenSymbol: "ETH",
    nativeTokenWrappedName: "Ether (Wrapped)",
    etherscanLinkText: "Basescan",
    tokensListUrl:
      "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/tokenLists/ethereum.tokenlist.json",
    factoryAddress: "0x833e4083B7ae46CeA85695c4f7ed25CDAd8886dE",
  },
  [ChainId.POLYGON]: {
    chainId: ChainId.POLYGON,
    name: "Polygon",
    urlKey: "ethereum",
    dmmSwapUrl: "https://kyberswap.com/",
    subgraphName: "dynamic-amm/dynamic-amm",
    balancerSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/verified-network/vault-matic",
    verifiedWalletSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/verified-network/wallet-matic",
    kyberSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    etherscanUrl: "https://etherscan.io",
    kncAddress: "0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202",
    wethAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    defaultStartTime: 1620201600,
    nativeTokenSymbol: "ETH",
    nativeTokenWrappedName: "Ether (Wrapped)",
    etherscanLinkText: "Etherscan",
    tokensListUrl:
      "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/tokenLists/ethereum.tokenlist.json",
    factoryAddress: "0x833e4083B7ae46CeA85695c4f7ed25CDAd8886dE",
  },
  [ChainId.ARBITUM]: {
    chainId: ChainId.ARBITUM,
    name: "ARBITUM",
    urlKey: "ethereum",
    dmmSwapUrl: "https://kyberswap.com/",
    subgraphName: "dynamic-amm/dynamic-amm",
    balancerSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/verified-network/vault-arbitrum",
    verifiedWalletSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/verified-network/wallet-arbitrum",
    kyberSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    subgraphUrls: [
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    ],
    etherscanUrl: "https://etherscan.io",
    kncAddress: "0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202",
    wethAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    defaultStartTime: 1620201600,
    nativeTokenSymbol: "ETH",
    nativeTokenWrappedName: "Ether (Wrapped)",
    etherscanLinkText: "Etherscan",
    tokensListUrl:
      "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/tokenLists/ethereum.tokenlist.json",
    factoryAddress: "0x833e4083B7ae46CeA85695c4f7ed25CDAd8886dE",
  },
  [ChainId.SEPOLIA]: {
    chainId: ChainId.SEPOLIA,
    name: "SEPOLIA",
    urlKey: "ethereum",
    dmmSwapUrl: "https://kyberswap.com/",
    subgraphName: "dynamic-amm/dynamic-amm",
    balancerSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/test-vault/version/latest",
    verifiedWalletSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/test-wallet/version/latest",
    kyberSubgraphUrl:
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    subgraphUrls: [
      "https://api.thegraph.com/subgraphs/name/kybernetwork/kyberswap-exchange-ethereum",
    ],
    etherscanUrl: "https://etherscan.io",
    kncAddress: "0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202",
    wethAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    defaultStartTime: 1620201600,
    nativeTokenSymbol: "ETH",
    nativeTokenWrappedName: "Ether (Wrapped)",
    etherscanLinkText: "Etherscan",
    tokensListUrl:
      "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/tokenLists/ethereum.tokenlist.json",
    factoryAddress: "0x833e4083B7ae46CeA85695c4f7ed25CDAd8886dE",
    lidoAddress: "0x3e3FE7dBc6B4C189E7128855dD526361c49b40Af",
    verifiedLidoRewardAddress: "0x19836182a3786CD592523cAB7445325be26c3334",
  },
};

export const getProviderNetwork = (chain) => {
  return {
    chainId: chain?.id,
    name: chain?.name,
    ensAddress: chain?.contracts?.ensRegistry?.address,
  };
};

export const getProvider = (transport, network) => {
  return new providers.Web3Provider(transport, network);
};

export const getWeb3 = (transport) => {
  if (transport.type === "fallback") {
    return new Web3(transport.transports[0].value?.url);
  }
  return new Web3(transport);
};
