import { useEffect, useState } from "react";

import { verifiedWalletSubgraphClient } from "../adapters";
import {
  GET_VERIFIED_SECURITY,
  GET_VERIFIED_SECURITIES,
  GET_VERIFIED_PLATFORMS,
  GET_VERIFIED_USERS,
  GET_VERIFIED_USER,
  GET_VERIFIED_CURRENCIES,
  GET_VERIFIED_RWAS,
} from "../utils/queries/walletQueries";
import { useAccount, useConnectorClient } from "wagmi";

export const getVerifiedSecurityByAddress = async (client, address) => {
  let data = [];
  if (client) {
    try {
      let result = await client.query({
        query: GET_VERIFIED_SECURITY,
        fetchPolicy: "cache-first",
        variables: {
          id: address ?? "0x",
        },
      });
      data = result;
    } catch (e) {
      console.log("getWalletData catch", { e });
      console.log(e);
      return [];
    }
  }

  return data;
};
export function useVerifiedSecurity(securityAddress, isUpdate = "false") {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };

  useEffect(() => {
    async function fetchPoolData() {
      if (chainId && securityAddress) {
        setLoading(true);
        let result = await getVerifiedSecurityByAddress(
          verifiedWalletSubgraphClient(chainId),
          securityAddress
        );
        const data = result?.data;
        setState(data.security);
        setLoading(false);
      }
    }
    fetchPoolData();
  }, [address, chainId, transport, isUpdate, securityAddress]);

  return { data: state, loading };
}

const getVerifiedSecuritiesList = async (client) => {
  let data = [];
  if (client) {
    try {
      let result = await client.query({
        query: GET_VERIFIED_SECURITIES,
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (e) {
      console.log("getWalletSecurities catch", { e });
      console.log(e);
    }
  }

  return data;
};

const getVerifiedRWAs = async (client) => {
  let data = [];

  if (client) {
    try {
      let result = await client.query({
        query: GET_VERIFIED_RWAS,
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (e) {
      console.log("getRWAs catch", { e });
      console.log(e);
    }
  }

  return data;
};

const getVerifiedCurrenciesList = async (client) => {
  let data = [];

  if (client) {
    try {
      let result = await client.query({
        query: GET_VERIFIED_CURRENCIES,
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (e) {
      console.log("getWalletSecurities catch", { e });
      console.log(e);
    }
  }

  return data;
};

const getVerifiedUsersList = async (client) => {
  let data = [];

  if (client) {
    try {
      let result = await client.query({
        query: GET_VERIFIED_USERS,
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (e) {
      console.log("getWalletSecurities catch", { e });
      console.log(e);
    }
  }

  return data;
};

export function useVerifiedSecuritiesList() {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };

  useEffect(() => {
    async function fetchPoolData() {
      if (chainId) {
        setLoading(true);
        let result = await getVerifiedSecuritiesList(
          verifiedWalletSubgraphClient(chainId)
        );
        const data = result.data || {};

        setState(data.securities || []);
        setLoading(false);
      }
    }
    fetchPoolData();
  }, [chainId, transport]);

  return { data: state, loading };
}

export function useVerifiedRWALists() {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };

  useEffect(() => {
    async function fetchRWAs() {
      if (chainId) {
        setLoading(true);
        let result = await getVerifiedRWAs(
          verifiedWalletSubgraphClient(chainId)
        );
        const data = result.data || {};
        setState(data.rwas || []);
        setLoading(false);
      }
    }
    fetchRWAs();
  }, [chainId, address, transport]);

  return { data: state, loading };
}

export function useVerifiedCurrenciesList() {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };

  useEffect(() => {
    async function fetchPoolData() {
      if (chainId) {
        setLoading(true);
        let result = await getVerifiedCurrenciesList(
          verifiedWalletSubgraphClient(chainId)
        );
        const data = result.data || {};
        setState(data.currencies || []);
        setLoading(false);
      }
    }
    fetchPoolData();
  }, [chainId, transport]);

  return { data: state, loading };
}

export function useVerifiedUsers() {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };

  useEffect(() => {
    async function fetchPoolData() {
      if (chainId) {
        let result = await getVerifiedUsersList(
          verifiedWalletSubgraphClient(chainId)
        );
        const data = result.data || {};
        setState(data.users || []);
        setLoading(false);
      }
    }
    fetchPoolData();
  }, [chainId, transport]);

  return { data: state, loading };
}

export const getVerifiedUserByAddress = async (client, address) => {
  let data = [];
  if (client) {
    try {
      let result = await client.query({
        query: GET_VERIFIED_USER,
        fetchPolicy: "cache-first",
        variables: {
          id: address ?? "0x",
        },
      });
      data = result;
    } catch (e) {
      console.log("getWalletData catch", { e });
      console.log(e);
    }
  }

  return data;
};

const getVerifiedPlatformsList = async (client) => {
  let data = [];
  if (client) {
    try {
      let result = await client.query({
        query: GET_VERIFIED_PLATFORMS,
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (e) {
      console.log("getWalletData catch", { e });
      console.log(e);
    }
  }

  return data;
};

export function useVerifiedUser(userAddress) {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);

  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };

  useEffect(() => {
    async function fetchPoolData() {
      let result = await getVerifiedUserByAddress(
        verifiedWalletSubgraphClient(chainId),
        userAddress.toLowerCase()
      );
      const data = result.data || {};
      setState(data.user || {});
      setLoading(false);
    }
    fetchPoolData();
  }, [address, chainId, transport]);

  return { data: state, loading };
}

export function useVerifiedPlatforms() {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };

  useEffect(() => {
    async function fetchPoolData() {
      let result = await getVerifiedPlatformsList(
        verifiedWalletSubgraphClient(chainId)
      );
      const data = result.data || {};
      setState(data.platforms || {});
      setLoading(false);
    }
    fetchPoolData();
  }, [chainId, transport]);

  return { data: state, loading };
}
