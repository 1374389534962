import axios from "axios";
import config from "../../services/config/homestead.json";
import { ethers } from "ethers";
const sgMail = require("@sendgrid/mail");

export const getImagesInBase64 = async (url) => {
  return await axios({
    method: "GET",
    url: url,
  })
    .then((res) => {
      if (res.data.errors) {
        console.error("Unexpected error occured");
        return;
      } else {
        // console.log("pool res: ", res.data);
        return res.data;
      }
    })
    .catch((err) => {
      console.error("Error while images: ", err);
      return;
    });
};

export const getFileSizeInMB = (fileBase64) => {
  const bytesLengthFmt = fileBase64.length * 3;
  const divider = fileBase64.endsWith("==")
    ? 2
    : fileBase64.endsWith("=1")
    ? 1
    : 0;
  const sizeInBytes = bytesLengthFmt / 4 - divider; // 1.33 times??
  return sizeInBytes / (1024 * 1024);
};

export const getAddressFromExternalId = (externalUserId) => {
  const abiCoder = new ethers.utils.AbiCoder();
  try {
    const decodedData = abiCoder.decode(["string", "string"], externalUserId);
    return decodedData[0];
  } catch (err) {
    return "";
  }
};

export const changeApllicantName = async (
  applicantId,
  firstName,
  lastName,
  middleName
) => {
  return await axios({
    method: "POST",
    url: config.approvalEndpoint,
    headers: {
      Accept: "application/json",
    },
    data:
      middleName !== ""
        ? {
            applicantId: applicantId,
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
          }
        : {
            applicantId: applicantId,
            firstName: firstName,
            lastName: lastName,
          },
  })
    .then((res) => {
      const response = res?.data;
      if (response) {
        console.log("return from name change request: ", response);
        return true;
      }
    })
    .catch((err) => {
      //handle request error
      console.log(`post request to update name failed with error: ${err}`);
      return false;
    });
};

export const approveOrRejectApplicant = async (
  applicantId,
  inspectionId,
  externalUserData,
  reviewResult
) => {
  // console.log("det: ", {
  //   applicantId: applicantId,
  //   inspectionId: inspectionId,
  //   externalUserId: externalUserData,
  //   reviewResult: reviewResult,
  // });
  return await axios({
    method: "POST",
    url: config.approvalEndpoint,
    headers: {
      Accept: "application/json",
    },
    data: reviewResult
      ? {
          applicantId: applicantId,
          inspectionId: inspectionId,
          externalUserId: externalUserData,
          reviewResult: reviewResult,
        }
      : {
          applicantId: applicantId,
          inspectionId: inspectionId,
          externalUserId: externalUserData,
        },
  })
    .then((res) => {
      const response = res?.data;
      if (response) {
        console.log("return from req: ", response);
        return true;
      }
    })
    .catch((err) => {
      //handle request error
      console.log(`post request failed with error: ${err}`);
      return false;
    });
};

export const sendMessageToApplicant = async (
  applicantEmail,
  applicantName,
  messageSubject,
  message
) => {
  return await axios({
    method: "POST",
    url: config.sendGridEndpoint,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${config.keys.sendGridApiKey}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      personalizations: [
        {
          to: applicantName
            ? [
                {
                  email: applicantEmail,
                  name: applicantName,
                },
              ]
            : [
                {
                  email: applicantEmail,
                },
              ],
          subject: messageSubject,
        },
      ],
      content: [
        {
          type: "text/plain",
          value: message,
        },
      ],
      from: {
        email: config.sendGridSenderEmail,
        name: "Verified Network",
      },
      reply_to: {
        email: config.sendGridSenderEmail,
        name: "Verified Network",
      },
    }),
  })
    .then((res) => {
      const response = res?.data;
      if (response) {
        console.log("return from req: ", response);
      }
      return true;
    })
    .catch((err) => {
      //handle request error
      console.log(`post request failed with error: ${err}`);
      return false;
    });
};

export const getApplicantMessages = async (applicantEmail) => {
  return await axios({
    method: "GET",
    url: config.sendGridMessagesEndpoint,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${config.keys.sendGridApiKey}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      const response = res?.data;
      if (response) {
        return response;
      } else {
        return [];
      }
    })
    .catch((err) => {
      //handle request error
      console.log(`post request failed with error: ${err}`);
      return null;
    });
};
