import { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import { useBalancerPoolsData } from "../../../context/balancerPoolData";
import {
  useNetworkOptions,
  useSupportedTokens,
} from "../../../context/contractDataLoaderService";
import { PoolType } from "../../poolDetail/balancer/types";
import UiTable from "../../../components/table/Table";

import Response from "../../../utils/response";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Distribution, contractAddress } from "@verified-network/verified-sdk";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import DistributionArtifact from "../../../utils/abi/Distribution.json";

function IssuesDetails(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  let { data: secondaryData, loading: secondaryLoading } = useBalancerPoolsData(
    PoolType.PrimaryIssue
  );
  let { data: primaryData, loading: primaryLoading } = useBalancerPoolsData(
    PoolType.SecondaryIssue
  );
  let { data: marginData, loading: marginLoading } = useBalancerPoolsData(
    PoolType.MarginIssue
  );
  let finalData = [...marginData, ...secondaryData, ...primaryData];
  // finalData = finalData.filter((data) => {
  //   if (data.verifiedWalletData) {
  //     return (
  //       Response.parseBytes32Value(data.verifiedWalletData.productCategory) ===
  //         "Stock" ||
  //       Response.parseBytes32Value(data.verifiedWalletData.productCategory) ===
  //         "STOCK" ||
  //       Response.parseBytes32Value(data.verifiedWalletData.productCategory) ===
  //         "CFD"
  //     );
  //   }
  // });
  finalData = finalData.map((obj) => ({ ...obj, option: "security" }));
  const { data: networkOptions } = useNetworkOptions();
  const { data: supportedTokens } = useSupportedTokens();
  const [filterOptionSelected, setFilterOptionSelected] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [tableHeader, setTableHeader] = useState(null);
  const filterOption = ["Securities", "Bonds", "Currencies"];
  let headers = [
    { label: "Issue Type", val: "poolType" },
    { label: "Security", val: "security" },
    { label: "Currency", val: "currency" },
    { label: "Start/Close Date", val: "servicerIssueDate" },
    { label: "Call Action", val: "adminIssueCallAction" },
  ];
  useEffect(() => {
    if (filterOptionSelected === "Currencies") {
      const updatedArray = supportedTokens.map((obj) => ({
        ...obj,
        option: "currency",
      }));
      let headers = [
        { label: "Currency", val: "tokenName" },
        { label: "Address", val: "tokenAddress" },
        { label: "Call Action", val: "adminIssueCallAction" },
      ];
      setTableHeader(headers);
      setTableData(updatedArray);
    } else if (filterOptionSelected === "Securities") {
      const dataFilter = finalData.filter((data) => {
        if (data?.verifiedWalletData?.productCategory) {
          return (
            Response.parseBytes32Value(
              data.verifiedWalletData.productCategory
            ) === "Stock" ||
            Response.parseBytes32Value(
              data.verifiedWalletData.productCategory
            ) === "STOCK" ||
            Response.parseBytes32Value(
              data.verifiedWalletData.productCategory
            ) === "Bond" ||
            Response.parseBytes32Value(
              data.verifiedWalletData.productCategory
            ) === "CFD"
          );
        }
      });
      const updatedArray = dataFilter.map((obj) => ({
        ...obj,
        option: "security",
      }));
      setTableHeader(headers);
      setTableData(updatedArray);
    } else if (filterOptionSelected === "Bonds") {
      const dataFilter = finalData.filter(
        (data) =>
          data?.verifiedWalletData?.productCategory &&
          Response.parseBytes32Value(
            data?.verifiedWalletData?.productCategory
          ) === "Bond"
      );
      const updatedArray = dataFilter.map((obj) => ({
        ...obj,
        option: "bond",
      }));
      setTableHeader(headers);
      setTableData(updatedArray);
    }
  }, [filterOptionSelected]);

  let distributionContract;
  if (provider && provider.getSigner && chainId && address && web3) {
    distributionContract = new Distribution(
      provider.getSigner(address),
      contractAddress[chainId].Distribution
    );
    // distributionContract = new web3.eth.Contract(
    //   DistributionArtifact.abi,
    //   contractAddress[chainId].Distribution
    // );
  }

  return (
    <>
      {secondaryLoading || primaryLoading || marginLoading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-50">
            <h3 className="text-left">Fees</h3>
          </div>
          <div className="w-50 text-right">
            <DropdownButton
              id="dropdown-basic-button"
              title={filterOptionSelected ?? "Filter"}
            >
              {filterOption?.map((option) => (
                <Dropdown.Item
                  key={option}
                  onClick={(e) => setFilterOptionSelected(option)}
                >
                  {option}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </div>
        <div className="w-100">
          <section className="d-flex flex-column align-items-start px-0 py-4">
            <div className="pools-table width-100">
              <UiTable
                thead={tableHeader ?? headers}
                tbodyData={tableData ?? finalData}
                hover
                chainId={chainId}
                account={account}
                contract={distributionContract}
                networkOptions={networkOptions}
                bordered={false}
              />
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

export default function AdminIssues(props) {
  return <IssuesDetails {...props} />;
}
