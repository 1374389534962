import "dotenv/config";
import React, { useState, useEffect } from "react";

import Web3 from "web3";
import { ethers, providers } from "ethers";
import { useAsync } from "react-use";
import { format } from "date-fns";
import secondaryPoolArtifact from "../utils/abi/SecondaryIssuePool.json";
import MarginTradingPool from "../utils/abi/MarginTradingPool.json";
import OrderBook from "../utils/abi/Orderbook.json";
import Dropdown from "react-bootstrap/Dropdown";
import paymentGateway from "../pages/custodian/api/PaymentGateway";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  useVerifiedSecuritiesList,
  useVerifiedUsers,
  useVerifiedUser,
  useVerifiedPlatforms,
  useVerifiedCurrenciesList,
  useVerifiedRWALists,
  getVerifiedUserByAddress,
} from "./verifiedWalletData";
import orderBookArtifact from "../utils/abi/Orderbook.json";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
  getCountryName,
  DefaultDecimals,
  DefaultOrderType,
  currenciesToFiat,
} from "../utils/constants";
import { toast } from "react-toastify";
import axios from "axios";
import { useBalancerPoolsData } from "./balancerPoolData";
import { PoolType } from "../pages/poolDetail/balancer/types";
import {
  Liquidity,
  SecuritiesFactory,
  PrimaryIssueManager,
  contractAddress,
  Client,
  Factory,
  Cash,
  SecondaryIssueManager,
  ERC20,
  MarginIssueManager,
  Bond,
} from "@verified-network/verified-sdk";
import config from "../services/config/homestead.json";
import Response, { NULL_ADDRESS } from "../utils/response";
import ERC20ABI from "../utils/abi/ERC20.json";
import MarginManagerArtifact from "../utils/abi/MarginManager.json";
import LiquidityAbi from "../utils/abi/Liquidity.json";
import primaryIssueManagerAbi from "../utils/abi/primaryManager.json";
import Security from "../utils/abi/Security.json";
import { useGlobal } from "./globalContext";
import {
  GET_ALL_BALANCER_MARGIN_ISSUE_POOLS,
  GET_USER_ORDERS,
  GET_USER_TRADES,
  GET_VERIFIED_MARGIN_SECURITIES,
} from "../utils/queries/balancerQueries";
import {
  balancerSubgraphClient,
  verifiedWalletSubgraphClient,
} from "../adapters";
import {
  GET_MARGIN_COLLATERALS,
  GET_MARGIN_PNLS,
  GET_VERIFIED_CURRENCIES,
  GET_VERIFIED_SECURITIES,
  GET_VERIFIED_SECURITY,
} from "../utils/queries/walletQueries";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../utils/helpers/networks";
import { getImagesInBase64 } from "../pages/kycManager/kycUtils";
import { convertToBlockTimestamp } from "../utils";
import { data } from "autoprefixer";

// Function to fetch details of the user with address specified
export async function getOwnerDetails(address, clientContract) {
  const owner = await clientContract.getClientKYC(address);
  const ownerDetails = owner.response?.result;
  const ownerName = Response.parseBytes32Value(ownerDetails[0]);
  const ownerCountry = Response.parseBytes32Value(ownerDetails[2]);
  return { ownerName, ownerCountry };
}

const getUserOrders = async (client, account) => {
  let data = [];
  if (client) {
    try {
      let result = await client.query({
        query: GET_USER_ORDERS(account),
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (err) {
      console.log("Error while getting user orders: ", err);
    }
  }

  return data;
};

const getUserTrades = async (client, account) => {
  let data = [];
  if (client) {
    try {
      let result = await client.query({
        query: GET_USER_TRADES(account),
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (err) {
      console.log("Error while getting user trades: ", err);
    }
  }

  return data;
};

export function useFetchMarginPoolsOrders() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  let { data: balancerPools, loading: PoolLoading } = useBalancerPoolsData(
    PoolType.MarginIssue
  );
  let collaterals = {};

  useEffect(() => {
    const fetchOrders = async () => {
      let allData = [];
      setLoading(true);
      collaterals = await getMarginCollaterals(
        verifiedWalletSubgraphClient(chainId)
      );
      if (collaterals?.data?.marginCollaterals) {
        await Promise.all(
          collaterals.data.marginCollaterals.map(async (col) => {
            if (col?.user?.id && !col?.balance.toString().startsWith("-")) {
              const currencyContract = new ERC20(signer, col.currency);
              allData.push({
                securitySymbol: "",
                currencySymbol: await currencyContract.symbol().then((res) => {
                  return res.response.result[0];
                }),
                transactionType: "COLLATERAL",
                investorName: `${col.user.id.substring(
                  0,
                  5
                )}...${col.user.id.substring(
                  col.user.id.length - 3,
                  col.user.id.length
                )}`,
                transactionAmount: Number(
                  web3.utils.fromWei(col.balance.toString(), "ether")
                ).toFixed(6),
                date: col.time, //todo: add timestamp to margin collateral
              });
            }
          })
        );
      }
      await Promise.all(
        balancerPools.map(async (pool) => {
          pool.marginOrders.map((order) => {
            allData.push({
              securitySymbol: pool.tokens.find(
                (tkn) =>
                  tkn.address.toLowerCase() === pool.security.toLowerCase()
              ).symbol,
              currencySymbol: pool.tokens.find(
                (tkn) =>
                  tkn.address.toLowerCase() === pool.currency.toLowerCase()
              ).symbol,
              transactionType:
                order.tokenIn.address.toLowerCase() ===
                pool.currency.toLowerCase()
                  ? "BUY"
                  : "SELL",
              investorName: `${order.creator.substring(
                0,
                5
              )}...${order.creator.substring(
                order.creator.length - 3,
                order.creator.length
              )}`,
              transactionAmount: Number(order.amountOffered).toFixed(6),
              date: order.timestamp,
            });
          });
        })
      );
      setDataList(allData);
      setLoading(false);
    };
    fetchOrders();
  }, [balancerPools, account, chainId, transport]);

  return { marginOrders: dataList, loading, PoolLoading };
}

export function useFetchMarginOrders(marginIssuePools) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      let allOrders = [];
      let matchedOrders = [];
      let matchedAmount = [];
      let poolsMargin = {};
      setLoading(true);

      await Promise.all([
        await marginIssuePools.map(async (pool) => {
          poolsMargin[pool.address.toLowerCase()] = pool.margin;
          if (pool.marginOrders.length > 0) {
            pool.marginOrders.map((order) => {
              if (order.creator.toLowerCase() === account.toLowerCase()) {
                allOrders.push(order);
              }
            });
          }
        }),
        await getVerifiedUserByAddress(
          verifiedWalletSubgraphClient(chainId),
          account.toLowerCase()
        ).then((res) => {
          if (res?.data?.user) {
            const trade = res?.data?.user;
            if (trade.tradeTransferor.length > 0) {
              trade.tradeTransferor.map((trf) => {
                if (!matchedOrders.includes(trf.orderRef.toLowerCase())) {
                  matchedOrders.push(trf.orderRef.toLowerCase());
                  matchedAmount.push({
                    securityTraded: trf.securityTraded,
                    cashTraded: trf.cashTraded,
                  });
                }
              });
            }
            if (trade.tradeTransferee.length > 0) {
              trade.tradeTransferee.map((trf) => {
                if (!matchedOrders.includes(trf.orderRef.toLowerCase())) {
                  matchedOrders.push(trf.orderRef.toLowerCase());
                  matchedAmount.push({
                    securityTraded: trf.securityTraded,
                    cashTraded: trf.cashTraded,
                  });
                }
              });
            }
          }
        }),
      ]);
      // console.log("all: ", allOrders)

      // console.log("marginMatched: ", matchedOrders)
      let allOrdersFmt = [];
      await Promise.all(
        allOrders.map(async (order) => {
          const securityContract = new web3.eth.Contract(
            Security.abi,
            order.pool.security
          );
          const currencyContract = new web3.eth.Contract(
            ERC20ABI,
            order.pool.currency
          );
          const currencySymbol = await currencyContract.methods.symbol().call();
          const currencyDecimals = await currencyContract.methods
            .decimals()
            .call({ from: address });
          let currencyAmount,
            securityName,
            securitySymbol,
            orderType,
            status,
            quantity,
            modifyTrade,
            tradeMatched;
          let price = order.priceOffered;
          if (
            order.tokenIn.address.toLowerCase() ===
            order.pool.security.toLowerCase()
          ) {
            if (!matchedOrders.includes(order.orderReference.toLowerCase())) {
              status = "UnMatched";
              tradeMatched = false;
              quantity = order.amountOffered;
              currencyAmount = "Not Filled";
            } else {
              const idx = matchedOrders.findIndex(
                (ref) => ref === order.orderReference.toLowerCase()
              );
              const securityDecimals = Number(
                await securityContract.methods.decimals().call()
              );
              status = "Matched";
              tradeMatched = true;
              quantity = order.amountOffered;
              currencyAmount = `${(
                Number(matchedAmount[idx].cashTraded) /
                10 ** currencyDecimals
              ).toFixed(4)} ${currencySymbol}`;
            }
            securityName = web3.utils.toAscii(
              web3.utils.toHex(order.tokenIn.name)
            );
            securitySymbol = web3.utils.toAscii(
              web3.utils.toHex(order.tokenIn.symbol)
            );
            orderType = "Sell";
          } else if (
            order.tokenIn.address.toLowerCase() ===
            order.pool.currency.toLowerCase()
          ) {
            if (!matchedOrders.includes(order.orderReference.toLowerCase())) {
              status = "UnMatched";
              tradeMatched = false;
              quantity = "Not Filled";
              currencyAmount = order.amountOffered;
            } else {
              const idx = matchedOrders.findIndex(
                (ref) => ref === order.orderReference.toLowerCase()
              );
              const securityDecimals = Number(
                await securityContract.methods.decimals().call()
              );
              status = "Matched";
              tradeMatched = true;
              quantity = (
                Number(matchedAmount[idx].securityTraded) /
                10 ** securityDecimals
              ).toFixed(6);
              currencyAmount = `${order.amountOffered} ${currencySymbol}`;
              price = (
                order.amountOffered /
                (Number(matchedAmount[idx].securityTraded) /
                  10 ** securityDecimals)
              ).toFixed(6);
            }
            const _securityName = await securityContract.methods.name().call();
            const _securitySymbol = await securityContract.methods
              .symbol()
              .call();
            securityName = web3.utils.toAscii(
              web3.utils.toHex(_securityName.replace(/\0/g, ""))
            );
            securitySymbol = web3.utils.toAscii(
              web3.utils.toHex(_securitySymbol.replace(/\0/g, ""))
            );
            orderType = "Buy";
          } else if (
            order.tokenIn.address.toLowerCase() ===
            order.pool.address.toLowerCase()
          ) {
            Number(order.priceOffered) === 0
              ? (status = "Cancelled")
              : (status = "Edit/Claim");
            tradeMatched = true;
            const _securityName = await securityContract.methods.name().call();
            const _securitySymbol = await securityContract.methods
              .symbol()
              .call();
            securityName = web3.utils.toAscii(
              web3.utils.toHex(_securityName.replace(/\0/g, ""))
            );
            securitySymbol = web3.utils.toAscii(
              web3.utils.toHex(_securitySymbol.replace(/\0/g, ""))
            );
            if (
              order.tokenOut.address.toLowerCase() ===
              order.pool.currency.toLowerCase()
            ) {
              quantity = order.amountOffered;
              currencyAmount = "Not Filled";
              orderType = "Buy";
            } else {
              quantity = order.amountOffered;
              currencyAmount = "Not Filled";
              orderType = "Sell";
            }
          }
          if (status === "UnMatched" && Number(order.priceOffered) > 0) {
            modifyTrade = true;
          } else {
            modifyTrade = false;
          }
          const poolContract = new web3.eth.Contract(
            MarginTradingPool.abi,
            order.pool.address
          );
          const poolId = await poolContract.methods.getPoolId().call();
          allOrdersFmt.push({
            date: order.timestamp,
            securityName: securityName,
            securitySymbol: securitySymbol,
            currencySymbol: currencySymbol,
            orderType: orderType,
            quantity: quantity,
            amount: order.amountOffered,
            price:
              Number(price) /
              Number(poolsMargin[order.pool.address.toLowerCase()]),
            tokensList: order.pool.tokensList,
            currencyAmount: currencyAmount,
            marketOrder: Number(order.priceOffered) > 0 ? false : true,
            modifyTrade: modifyTrade,
            currency: order.pool.currency,
            security: order.pool.security,
            poolAddress: order.pool.address,
            poolId: poolId,
            orderRef: order.orderReference,
            tradeMatched: tradeMatched,
            status: status,
          });
          setLoading(false);
        })
      );
      setDataList(allOrdersFmt);
      setLoading(false);
      return allOrdersFmt;
    };
    if (chainId && web3) {
      fetchOrders();
    }
  }, [marginIssuePools, account, chainId, transport]);

  return { marginOrders: dataList, loading };
}

export function useFetchMarginTrades(marginIssuePools) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTrades = async () => {
      setLoading(true);
      let allTrades = [];
      let poolsMargin = {};
      let allClaimedTrades = [];
      marginIssuePools.map((pool) => {
        poolsMargin[pool.id.toLowerCase()] = pool.margin;
        if (pool.secondaryTrades.length > 0) {
          pool.secondaryTrades.map((trade) => {
            if (
              trade.party.id.toLowerCase() === account.toLowerCase() ||
              trade.counterparty.id.toLowerCase() === account.toLowerCase()
            ) {
              allClaimedTrades.push(trade.orderReference.toLowerCase());
            }
          });
        }
      });

      if (chainId && account) {
        await getVerifiedUserByAddress(
          verifiedWalletSubgraphClient(chainId),
          account.toLowerCase()
        ).then((res) => {
          //get all user's trades
          if (res?.data?.user) {
            const trade = res?.data?.user;
            if (trade?.securitySeller.length > 0) {
              trade?.securitySeller.map((trf) => {
                if (!allTrades.includes({ trade: trf, orderType: "Sell" })) {
                  allTrades.push({ trade: trf, orderType: "Sell" });
                }
              });
            }
            if (trade?.securityBuyer.length > 0) {
              trade?.securityBuyer.map((trf) => {
                if (!allTrades.includes({ trade: trf, orderType: "Buy" })) {
                  allTrades.push({ trade: trf, orderType: "Buy" });
                }
              });
            }
          }
        });
      }
      let allTradesData = [];
      //format each trade and specify claim and settlement status
      await Promise.all(
        allTrades.map(async (trd) => {
          if (web3) {
            const securityAddress = trd.trade.security.security;
            const securityContract = new web3.eth.Contract(
              Security.abi,
              securityAddress
            );
            const securityName = await securityContract.methods.name().call();
            const fmtSecurityName = web3.utils.toAscii(
              web3.utils.toHex(securityName.replace(/\0/g, ""))
            );
            const poolId = trd.trade.poolid;
            const poolAddress = poolId.substring(0, trd.trade.currency.length);
            const currencyContract = new web3.eth.Contract(
              ERC20ABI,
              trd.trade.currency
            );
            let decimalsToUse;
            let tokenIn;
            let oppositeToken;
            let tokenOutSymbol;
            let tokenOutDecimals;
            let price;
            let isClaimed = false;
            const currencySymbol = await currencyContract.methods
              .symbol()
              .call();
            const _securitySymbol = await securityContract.methods
              .symbol()
              .call();
            const securitySymbol = web3.utils.toAscii(
              web3.utils.toHex(_securitySymbol.replace(/\0/g, ""))
            );
            const currencyDecimals = Number(
              await currencyContract.methods.decimals().call()
            );
            if (trd.orderType === "Sell") {
              decimalsToUse = Number(
                await securityContract.methods.decimals().call()
              );
              tokenIn = trd.trade.security.security;
              oppositeToken = trd.trade.currency;
              tokenOutSymbol = currencySymbol;
              tokenOutDecimals = Number(
                await currencyContract.methods.decimals().call()
              );
            } else {
              decimalsToUse = currencyDecimals;
              tokenIn = trd.trade.currency;
              oppositeToken = trd.trade.security.security;
              tokenOutSymbol = securitySymbol;
              tokenOutDecimals = Number(
                await securityContract.methods.decimals().call()
              );
            }
            const _curAmt =
              Number(trd.trade.amountPaid) / 10 ** currencyDecimals;
            const _secAmt = Number(
              web3.utils.fromWei(trd.trade.unitsToTransfer.toString(), "ether")
            );
            price = _curAmt / _secAmt;
            if (allClaimedTrades.length > 0) {
              allClaimedTrades.includes(trd.trade.tradeRef.toLowerCase()) ||
              allClaimedTrades.includes(trd.trade.orderRef.toLowerCase())
                ? (isClaimed = true)
                : (isClaimed = false);
            }
            allTradesData.push({
              securityName: fmtSecurityName,
              orderType: trd.orderType,
              quantity: Number(
                web3.utils.fromWei(
                  trd.trade.unitsToTransfer.toString(),
                  "ether"
                )
              ).toFixed(6),
              qtyRaw: Number(
                web3.utils.fromWei(
                  trd.trade.unitsToTransfer.toString(),
                  "ether"
                )
              ),
              price: (
                price / Number(poolsMargin[trd.trade.poolid.toLowerCase()])
              ).toFixed(6),
              tradeAmount: `${Number(trd.trade.amountPaid)}`,
              currencyAmount: `${currencySymbol} ${
                Number(trd.trade.amountPaid) / 10 ** currencyDecimals
              }`,
              curAmount: Number(trd.trade.amountPaid) / 10 ** currencyDecimals,
              date: trd.trade.timestamp,
              tradeRef: trd.trade.tradeRef,
              orderRef: trd.trade.orderRef,
              claimTrade: !isClaimed,
              poolId: poolId,
              poolType: "MarginIssue",
              tokenIn: tokenIn,
              tokenOutSymbol: tokenOutSymbol,
              tokenOutDecimals: tokenOutDecimals,
              oppositeToken: oppositeToken,
              poolAddress: poolAddress,
              securityAddress: securityAddress,
              units: trd.trade.unitsToTransfer,
              status: trd.trade.settlementStatus
                ? Response.parseBytes32Value(trd.trade.settlementStatus)
                : trd.trade.settlementStatus,
            });
          }
        })
      );
      setDataList(allTradesData);
      setLoading(false);
      return allTradesData;
    };
    fetchTrades();
  }, [marginIssuePools, account, chainId, transport]);

  return { allTradesData: dataList, loading };
}

// Helper function to filter pending orders from already matched orders
const filterOrders = (
  pendingOrders,
  matchedTrades,
  tokenAddress,
  marketDepth = false
) => {
  let filteredOrders = pendingOrders
    .map((order) => {
      // filtering of already matched orders
      const matchedTrade = matchedTrades.find(
        (trade) =>
          trade.orderReference?.toLowerCase() ===
          order.orderReference?.toLowerCase()
      );
      if (matchedTrade) {
        const price =
          order.tokenIn.address.toLowerCase() === tokenAddress
            ? 1 / matchedTrade.price
            : matchedTrade.price;
        const amount = matchedTrade.amount * price;
        return {
          ...order,
          priceOffered: Number(order.priceOffered),
          amountOffered:
            Number(order.amountOffered - amount) === 0
              ? Number(order.amountOffered)
              : Number(order.amountOffered - amount),
        };
      }
      return order;
    })
    .filter((element) => element && element.amountOffered !== 0);
  if (marketDepth)
    filteredOrders = filteredOrders.filter((order) => order.priceOffered !== 0);

  return filteredOrders;
};

// Function to format daily price data to hourly format for Price Chart
export const formatHourlyData = (priceData) => {
  // Define the number of milliseconds in an hour
  const HOUR_IN_MILLISECONDS = 60 * 60 * 1000;

  // Initialize variables for keeping track of the current hour and the previous price
  let currentHour = null;
  let previousPrice = null;

  // Initialize variables for the OHLC data
  let openPrice = null;
  let highPrice = null;
  let lowPrice = null;
  let closePrice = null;

  // Create an array to store the OHLC data
  const ohlcData = [];

  // Iterate through the price data
  if (priceData.length > 0) {
    for (const [timestamp, price] of priceData) {
      // Round the timestamp down to the nearest hour
      const hourTimestamp =
        Math.floor(Date.parse(timestamp) / HOUR_IN_MILLISECONDS) *
        HOUR_IN_MILLISECONDS;

      // If this is the first price, initialize the OHLC data
      if (previousPrice === null) {
        currentHour = hourTimestamp;
        openPrice = highPrice = lowPrice = closePrice = parseFloat(price);
      }

      // If the timestamp is within the current hour, update the OHLC data
      else if (hourTimestamp === currentHour) {
        const numericPrice = parseFloat(price);
        highPrice = Math.max(highPrice, numericPrice);
        lowPrice = Math.min(lowPrice, numericPrice);
        closePrice = numericPrice;
      }
      // If the timestamp is in a new hour, add the previous hour's OHLC data to the array and initialize the new hour's OHLC data
      else {
        let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = new Date(currentHour - tzoffset).toISOString();
        ohlcData.push([
          localISOTime,
          openPrice,
          highPrice,
          lowPrice,
          closePrice,
        ]);

        currentHour = hourTimestamp;
        openPrice = highPrice = lowPrice = closePrice = parseFloat(price);
      }

      // Update the previous price
      previousPrice = price;
    }
  }

  // If there is any OHLC data for the final hour, add it to the array
  if (openPrice !== null) {
    var tzoffset1 = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = new Date(currentHour - tzoffset1).toISOString();
    ohlcData.push([localISOTime, openPrice, highPrice, lowPrice, closePrice]);
  }

  // The ohlcData array now contains the hourly OHLC data
  // console.log(ohlcData);
  return ohlcData;
};

// Function to convert amount to currencyAmount with specified decimal points
const currencyAmount = (amount, currencyTokenDecimals) => {
  return Number(
    ethers.utils.parseUnits(amount.toString(), currencyTokenDecimals)
  );
};

const handlePaymentIssueOption = async (
  selected,
  option,
  country,
  account,
  chainId,
  cashContract,
  web3,
  setLoading
) => {
  const { paymentRef, requestProduct, amount, userAddress, currency } =
    selected;
  const sendAmount = web3.utils.toWei(amount.toString(), "ether");
  if (option === "Confirm") {
    setLoading(true);
    await paymentGateway
      .confirmCashIssueRequest(country, paymentRef)
      .then(async () => {
        return await cashContract.payIn(
          sendAmount.toString(),
          userAddress,
          currency
        );
      })
      .then(() => {
        toast.success("Success", ToastOptions);
      })
      .catch((error) => {
        console.log("Error", error);
        toast.error("Error", ToastOptions);
      })
      .finally(() => {
        setLoading(false);
      });
  } else if (option === "Reject") {
    setLoading(true);
    await paymentGateway
      .rejectCashIssueRequest(country, paymentRef)
      .then(() => {
        toast.success("Success", ToastOptions);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Error", ToastOptions);
      })
      .finally(() => {
        setLoading(false);
      });
  }
};

// Function to extract proper amount fixed from secondary trades
const getAmount = (
  orderDetails,
  tradeInfo,
  securityAddress,
  currencyTokenDecimals
) => {
  const isSecurityTokenIn =
    orderDetails.tokenIn.address.toLowerCase() ===
    securityAddress.toLowerCase();
  const amount = isSecurityTokenIn
    ? tradeInfo.securityTraded
    : tradeInfo.currencyTraded;
  const returningAmount = isSecurityTokenIn
    ? currencyAmount(
        Number(ethers.utils.formatEther(tradeInfo.currencyTraded)),
        currencyTokenDecimals
      )
    : tradeInfo.securityTraded;
  const approvalAmount = isSecurityTokenIn
    ? tradeInfo.securityTraded
    : tradeInfo.currencyTraded;
  const quantity = ethers.utils.formatEther(tradeInfo.securityTraded);
  const price = tradeInfo.currencyTraded / tradeInfo.securityTraded;
  const currencyAmountData = Number(
    ethers.utils.formatEther(tradeInfo.currencyTraded)
  );
  return {
    amount,
    quantity,
    price,
    returningAmount,
    approvalAmount,
    currencyAmountData,
  };
};

// Function to get the type of the order based on address for secondary trades
const getOrderType = (pendingOrder, securityTokenAddress) => {
  return pendingOrder.tokenIn.address.toLowerCase() ===
    securityTokenAddress.toLowerCase()
    ? "Sell"
    : "Buy";
};

const getUniqueOrders = (orders) => {
  const refs = new Set();
  return orders.reduce((acc, ord) => {
    if (!refs.has(ord.orderType)) {
      refs.add(ord.orderType);
      acc.push(ord);
    }
    return acc;
  }, []);
};

// Function to fetch all matched trades in secondary pool
export function useMatchedTrades(secondaryPoolsData, showPendingOrders) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;

  if (chain) {
    network = getProviderNetwork(chain);
  }

  if (network) {
    provider = getProvider(transport, network);
  }

  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }

  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const abiCoder = new ethers.utils.AbiCoder();

  useEffect(() => {
    const fetchMatchedTrades = async () => {
      const allMatchedTrades = await Promise.all(
        secondaryPoolsData.map(async (pool, index) => {
          if (pool.poolType !== PoolType.MarginIssue && account && web3) {
            let allClaimedTrades = [];
            if (pool.secondaryTrades.length > 0) {
              allClaimedTrades = pool.secondaryTrades.map((trade) => {
                if (
                  trade.party.id.toLowerCase() === account.toLowerCase() ||
                  trade.counterparty.id.toLowerCase() === account.toLowerCase()
                ) {
                  return trade.orderReference.toLowerCase();
                }
              });
            }
            // console.log("allClaimed: ", allClaimedTrades);
            const secondaryPoolContract = new web3.eth.Contract(
              secondaryPoolArtifact.abi,
              pool.address
            );

            const orderBookAddress = await secondaryPoolContract.methods
              ._orderbook()
              .call();
            const orderBookContract = new web3.eth.Contract(
              orderBookArtifact.abi,
              orderBookAddress
            );

            let partyAddress, counterAddress;
            const matchedTrades = await Promise.all(
              pool.secondaryPreTrades.map(async (trade) => {
                if (
                  trade.party.id.toLowerCase() === account.toLowerCase() ||
                  trade.counterparty.id.toLowerCase() === account.toLowerCase()
                ) {
                  partyAddress = trade.party.id;
                  counterAddress = trade.counterparty.id;
                  return await Promise.all([
                    orderBookContract.methods
                      .getTrade(
                        trade.party.id.toLowerCase() === account.toLowerCase()
                          ? trade.party.id
                          : trade.counterparty.id,
                        Number(trade.executionDate)
                      )
                      .call({
                        from:
                          trade.party.id.toLowerCase() === account.toLowerCase()
                            ? trade.party.id
                            : trade.counterparty.id,
                      })
                      .then((res) => {
                        let tradeInfo = Object.assign({}, res);
                        if (tradeInfo) {
                          tradeInfo["party"] = trade.party.id;
                        }
                        return tradeInfo;
                      })
                      .catch((err) => {
                        console.error("trade failed: ", err);
                        return null;
                      }),
                  ]);
                }
              })
            );

            // console.log("matched: ", matchedTrades.flat());

            if (partyAddress && matchedTrades && matchedTrades.length > 0) {
              const allOrders = await Promise.all(
                pool.orders.map(async (ord) => {
                  let claimed, matched, dt;
                  if (
                    ord.creator.toLowerCase() === account.toLowerCase() &&
                    allClaimedTrades.includes(ord.orderReference.toLowerCase())
                  ) {
                    claimed = true;
                  } else {
                    claimed = false;
                  }
                  const matchedOrder =
                    partyAddress.toLowerCase() === account.toLowerCase()
                      ? matchedTrades
                          .flat()
                          .find(
                            (trade) =>
                              trade &&
                              trade.partyRef.toLowerCase() ===
                                ord.orderReference.toLowerCase()
                          )
                      : matchedTrades
                          .flat()
                          .find(
                            (trade) =>
                              trade &&
                              trade.counterpartyRef.toLowerCase() ===
                                ord.orderReference.toLowerCase()
                          );

                  if (matchedOrder) {
                    if (showPendingOrders) {
                      const cashToken = pool.tokens.find(
                        (token) => token.address.toLowerCase() === pool.currency
                      );
                      const securityToken = pool.tokens.find(
                        (token) => token.address.toLowerCase() === pool.security
                      );
                      let quantity, currencyAmount, price, status;
                      if (
                        ord.tokenIn.address.toLowerCase() ===
                          pool.address.toLowerCase() &&
                        Number(ord.priceOffered) === 0
                      ) {
                        // filtering of cancelled orders
                        if (
                          ord.tokenOut.address.toLowerCase() ===
                          pool.security.toLowerCase()
                        ) {
                          quantity = Number(ord.amountOffered);
                          currencyAmount = "Not filled";
                        } else if (
                          ord.tokenOut.address.toLowerCase() ===
                          pool.currency.toLowerCase()
                        ) {
                          quantity = "Not filled";
                          currencyAmount = Number(ord.amountOffered);
                        }
                        price = Number(ord.priceOffered);
                        status = "Cancelled";
                      } else if (
                        ord.tokenIn.address.toLowerCase() ===
                          pool.security.toLowerCase() ||
                        ord.tokenOut.address.toLowerCase() ===
                          pool.security.toLowerCase()
                      ) {
                        quantity = Number(ord.amountOffered);
                        currencyAmount = "Not filled";
                        price = Number(ord.priceOffered);
                      } else if (
                        ord.tokenIn.address.toLowerCase() ===
                          pool.currency.toLowerCase() ||
                        ord.tokenOut.address.toLowerCase() ===
                          pool.currency.toLowerCase()
                      ) {
                        quantity = "Not filled";
                        currencyAmount = Number(ord.amountOffered);
                        price = Number(ord.priceOffered);
                      }

                      return {
                        id: pool.id + index,
                        poolId: pool.id,
                        poolType: pool.poolType,
                        security: pool.security,
                        currency: pool.currency,
                        tokens: pool.tokens,
                        tokensList: pool.tokensList,
                        marketOrder: price === 0 ? true : false,
                        price: price === 0 ? "Not filled" : price?.toFixed(6),
                        status: status,
                        quantity:
                          typeof quantity === "number"
                            ? quantity.toFixed(4)
                            : quantity,
                        currencyAmount:
                          typeof currencyAmount === "number"
                            ? cashToken.symbol + " " + currencyAmount.toFixed(4)
                            : "Not filled",
                        amount: ord.amountOffered,
                        securityName: securityToken.symbol,
                        securityTokenSymbol: securityToken.symbol,
                        currencyTokenSymbol: cashToken.symbol,
                        date: ord.timestamp,
                        orderType: getOrderType(ord, pool.security),
                        orderRef: ord.orderReference,
                        poolAddress: pool.address,
                        tradeMatched: true,
                      };
                    }
                    dt = matchedOrder.dt;
                    matched = true;
                    const assestIN = pool.address;
                    const assestOUT =
                      ord.tokenIn.address.toLowerCase() !== pool.security
                        ? pool.security
                        : pool.currency;
                    const assestINIndex = pool.tokensList.indexOf(assestIN);
                    const assestOUTIndex = pool.tokensList.indexOf(assestOUT);
                    const swapKind =
                      ord.tokenIn.address.toLowerCase() ===
                      pool.security.toLowerCase()
                        ? 0
                        : 1;

                    const securityDetails = pool.tokens.find(
                      (token) =>
                        token.address.toLowerCase() ===
                        pool.security.toLowerCase()
                    );
                    const currencyDetails = pool.tokens.find(
                      (token) =>
                        token.address.toLowerCase() ===
                        pool.currency.toLowerCase()
                    );
                    const priceData = getAmount(
                      ord,
                      matchedOrder,
                      pool.security,
                      currencyDetails.decimals
                    );

                    const userData = abiCoder.encode(
                      ["bytes32", "uint"],
                      [ethers.utils.formatBytes32String(""), matchedOrder.dt]
                    );

                    const approvalContract = new web3.eth.Contract(
                      ERC20ABI,
                      assestIN
                    );
                    const managerApprovalContract = new web3.eth.Contract(
                      ERC20ABI,
                      assestOUT
                    );
                    const limitArr = new Array(pool.tokensList.length).fill(0);
                    limitArr[assestINIndex] = priceData.amount;

                    return {
                      id: pool.address,
                      poolId: pool.id,
                      matchedTrade: false,
                      poolType: "SecondaryIssue",
                      securityName: securityDetails.symbol,
                      currencyName: currencyDetails.symbol,
                      security: pool.security,
                      currency: pool.currency,
                      tokens: pool.tokens,
                      tokensList: pool.tokensList,
                      price: priceData.price.toFixed(6),
                      amount: priceData.amount,
                      currencyAmount: `${
                        currencyDetails.symbol
                      } ${priceData.currencyAmountData.toFixed(4)}`,
                      quantity: Number(priceData.quantity).toFixed(4),
                      date: matchedOrder.dt,
                      currencyQuantity: priceData.currencyAmountData.toFixed(4),
                      orderType: swapKind === 0 ? "Sell" : "Buy",
                      assestINIndex,
                      assestOUTIndex,
                      bptINIndex: pool.tokensList.indexOf(pool.address),
                      approvalContract,
                      managerApprovalContract,
                      managerApprovalAmount: priceData.returningAmount,
                      limitArr,
                      approvalAmount: priceData.approvalAmount,
                      userData,
                      swapKind,
                      claimed: claimed,
                      claimTrade: !claimed,
                      orderRef: ord.orderReference,
                    };
                  } else if (showPendingOrders) {
                    const cashToken = pool.tokens.find(
                      (token) => token.address.toLowerCase() === pool.currency
                    );
                    const securityToken = pool.tokens.find(
                      (token) => token.address.toLowerCase() === pool.security
                    );
                    let quantity, currencyAmount, price, status;
                    if (
                      ord.tokenIn.address.toLowerCase() ===
                        pool.address.toLowerCase() &&
                      Number(ord.priceOffered) === 0
                    ) {
                      // filtering of cancelled orders
                      if (
                        ord.tokenOut.address.toLowerCase() ===
                        pool.security.toLowerCase()
                      ) {
                        quantity = Number(ord.amountOffered);
                        currencyAmount = "Not filled";
                      } else if (
                        ord.tokenOut.address.toLowerCase() ===
                        pool.currency.toLowerCase()
                      ) {
                        quantity = "Not filled";
                        currencyAmount = Number(ord.amountOffered);
                      }
                      price = Number(ord.priceOffered);
                      status = "Cancelled";
                    } else if (
                      ord.tokenIn.address.toLowerCase() ===
                        pool.security.toLowerCase() ||
                      ord.tokenOut.address.toLowerCase() ===
                        pool.security.toLowerCase()
                    ) {
                      quantity = Number(ord.amountOffered);
                      currencyAmount = "Not filled";
                      price = Number(ord.priceOffered);
                    } else if (
                      ord.tokenIn.address.toLowerCase() ===
                        pool.currency.toLowerCase() ||
                      ord.tokenOut.address.toLowerCase() ===
                        pool.currency.toLowerCase()
                    ) {
                      quantity = "Not filled";
                      currencyAmount = Number(ord.amountOffered);
                      price = Number(ord.priceOffered);
                    }

                    return {
                      id: pool.id + index,
                      poolId: pool.id,
                      poolType: pool.poolType,
                      security: pool.security,
                      currency: pool.currency,
                      tokens: pool.tokens,
                      tokensList: pool.tokensList,
                      marketOrder: price === 0 ? true : false,
                      price: price === 0 ? "Not filled" : price?.toFixed(6),
                      status: status,
                      quantity:
                        typeof quantity === "number"
                          ? quantity.toFixed(4)
                          : quantity,
                      currencyAmount:
                        typeof currencyAmount === "number"
                          ? cashToken.symbol + " " + currencyAmount.toFixed(4)
                          : "Not filled",
                      amount: ord.amountOffered,
                      securityName: securityToken.symbol,
                      securityTokenSymbol: securityToken.symbol,
                      currencyTokenSymbol: cashToken.symbol,
                      date: ord.timestamp,
                      orderType: getOrderType(ord, pool.security),
                      orderRef: ord.orderReference,
                      poolAddress: pool.address,
                      tradeMatched: false,
                    };
                  }
                })
              );
              return allOrders;
            }
          }
          return [];
        })
      );

      const finalArr = allMatchedTrades
        .flat()
        .filter((trade) => trade !== undefined);
      setDataList(finalArr);
      setLoading(false);
    };

    if (secondaryPoolsData) {
      fetchMatchedTrades();
    }
  }, [secondaryPoolsData, account, chainId, showPendingOrders, transport]);

  return { matchedTradesList: dataList, loading };
}

// Function to fetch all pending trades (with filtering of edited & cancelled trades) in secondary pool
export function usePendingOrderData(pool) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  let secondaryPoolFiltered = [];
  const { matchedTradesList } = useMatchedTrades(pool);

  let filterPoolData;
  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    const fetchPendingOrders = async () => {
      pool.map((poolData) => {
        let matchedTrades = poolData.secondaryTrades;
        const accountPendingOrders = poolData.orders.filter(
          (order) => order.creator.toLowerCase() === account.toLowerCase()
        );

        const sortedPendingOrders = accountPendingOrders.sort(
          (a, b) => Number(b.timestamp) - Number(a.timestamp)
        );
        // filtering of pending order for edited orders
        const uniquePendingOrders = Object.values(
          sortedPendingOrders.reduce((acc, cur) => {
            if (!acc[cur.orderReference]) {
              // If this is the first time we've seen this orderReference, add it to the accumulator
              acc[cur.orderReference] = cur;
            }
            return acc;
          }, {})
        );

        const matchedOrderRef = matchedTradesList.map((trades) => {
          if (trades) {
            return trades.orderRef.toLowerCase();
          }
        });

        const pendingOrders = filterOrders(
          uniquePendingOrders,
          matchedTrades,
          poolData.security.toLowerCase()
        );

        const cashToken = poolData.tokens.find(
          (token) => token.address.toLowerCase() === poolData.currency
        );
        const securityToken = poolData.tokens.find(
          (token) => token.address.toLowerCase() === poolData.security
        );
        const formattedData = pendingOrders.map((pendingOrder, index) => {
          const tradeMatched = matchedOrderRef.includes(
            pendingOrder.orderReference.toLowerCase()
          );

          let quantity, currencyAmount, price, status, orderMatchedVersion;
          if (tradeMatched) {
            orderMatchedVersion = matchedTradesList.find((mTrd) => {
              if (
                mTrd &&
                mTrd.orderRef &&
                mTrd.orderRef.toLowerCase() ===
                  pendingOrder.orderReference.toLowerCase()
              ) {
                return mTrd;
              }
            });
          }

          if (
            pendingOrder.tokenIn.address.toLowerCase() ===
              poolData.address.toLowerCase() &&
            Number(pendingOrder.priceOffered) === 0
          ) {
            // filtering of cancelled orders
            if (
              pendingOrder.tokenOut.address.toLowerCase() ===
              poolData.security.toLowerCase()
            ) {
              quantity = Number(pendingOrder.amountOffered);
              currencyAmount = "Not filled";
            } else if (
              pendingOrder.tokenOut.address.toLowerCase() ===
              poolData.currency.toLowerCase()
            ) {
              quantity =
                tradeMatched && orderMatchedVersion
                  ? Number(orderMatchedVersion.quantity)
                  : "Not filled";
              currencyAmount = Number(pendingOrder.amountOffered);
            }
            price = Number(pendingOrder.priceOffered);
            status = "Cancelled";
          } else if (
            pendingOrder.tokenIn.address.toLowerCase() ===
              poolData.security.toLowerCase() ||
            pendingOrder.tokenOut.address.toLowerCase() ===
              poolData.security.toLowerCase()
          ) {
            quantity = Number(pendingOrder.amountOffered);
            currencyAmount =
              tradeMatched && orderMatchedVersion
                ? Number(orderMatchedVersion.currencyQuantity)
                : "Not filled";
            price = Number(pendingOrder.priceOffered);
          } else if (
            pendingOrder.tokenIn.address.toLowerCase() ===
              poolData.currency.toLowerCase() ||
            pendingOrder.tokenOut.address.toLowerCase() ===
              poolData.currency.toLowerCase()
          ) {
            quantity =
              tradeMatched && orderMatchedVersion
                ? Number(orderMatchedVersion.quantity)
                : "Not filled";
            currencyAmount = Number(pendingOrder.amountOffered);
            price = Number(pendingOrder.priceOffered);
          }

          filterPoolData = {
            id: poolData.id + index,
            poolId: poolData.id,
            poolType: poolData.poolType,
            security: poolData.security,
            currency: poolData.currency,
            tokens: poolData.tokens,
            tokensList: poolData.tokensList,
            marketOrder: price === 0 ? true : false,
            price: price === 0 ? "Not filled" : price?.toFixed(6),
            status: status,
            quantity:
              typeof quantity === "number" ? quantity.toFixed(4) : quantity,
            currencyAmount:
              typeof currencyAmount === "number"
                ? cashToken.symbol + " " + currencyAmount.toFixed(4)
                : "Not filled",
            amount: pendingOrder.amountOffered,
            securityName: securityToken.symbol,
            securityTokenSymbol: securityToken.symbol,
            currencyTokenSymbol: cashToken.symbol,
            date: pendingOrder.timestamp,
            orderType: getOrderType(pendingOrder, poolData.security),
            orderRef: pendingOrder.orderReference,
            poolAddress: poolData.address,
            tradeMatched,
          };
          return filterPoolData;
        });
        secondaryPoolFiltered = [...secondaryPoolFiltered, ...formattedData];
      });
    };
    fetchPendingOrders();
    setDataList(secondaryPoolFiltered);
  }, [pool, matchedTradesList, transport]);

  return { pendingOrdersData: dataList };
}

// Function to fetch market depth in a partcular secondary pool
export function useMarketDepth(pool) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(true);
  const [buyOrders, setBuyOrders] = useState([]);
  const [sellOrders, setSellOrders] = useState([]);
  useEffect(() => {
    const fetchMarketDepth = async () => {
      const securityAddress = pool.security?.toLowerCase();
      const currencyAddress = pool.currency?.toLowerCase();
      let matchedTradesList = pool.secondaryTrades;
      let accountPendingOrders = pool.orders;

      const buyOrderList = accountPendingOrders?.filter(
        (order) => order.tokenIn.address.toLowerCase() !== securityAddress
      );
      const sellOrderList = accountPendingOrders?.filter(
        (order) => order.tokenIn.address.toLowerCase() !== currencyAddress
      );
      const sortedPendingBuyOrders = buyOrderList?.sort(
        (a, b) => Number(b.timestamp) - Number(a.timestamp)
      );
      const sortedPendingSellOrders = sellOrderList?.sort(
        (a, b) => Number(b.timestamp) - Number(a.timestamp)
      );
      // filtering of pending order for edited orders
      const uniquePendingBuyOrders = Object.values(
        sortedPendingBuyOrders?.reduce((acc, cur) => {
          if (!acc[cur.orderReference]) {
            // If this is the first time we've seen this orderReference, add it to the accumulator
            acc[cur.orderReference] = cur;
          }
          return acc;
        }, {})
      );

      const uniquePendingSellOrders = Object.values(
        sortedPendingSellOrders?.reduce((acc, cur) => {
          if (!acc[cur.orderReference]) {
            acc[cur.orderReference] = cur;
          }
          return acc;
        }, {})
      );
      const pendingBuyOrders = filterOrders(
        uniquePendingBuyOrders,
        matchedTradesList,
        currencyAddress,
        true
      );
      const pendingSellOrders = filterOrders(
        uniquePendingSellOrders,
        matchedTradesList,
        securityAddress,
        true
      );

      const buyData = pendingBuyOrders.map((order) => {
        return {
          bid: Number(order.priceOffered).toFixed(2),
          amountOffered: Number(order.amountOffered).toFixed(2),
        };
      });
      const sellData = pendingSellOrders.map((order) => {
        return {
          offer: Number(order.priceOffered).toFixed(2),
          amountOffered: Number(order.amountOffered).toFixed(2),
        };
      });
      setBuyOrders(buyData);
      setSellOrders(sellData);
      setLoading(false);
    };
    fetchMarketDepth();
  }, [pool, transport]);

  return { buyOrderBook: buyOrders, sellOrderBook: sellOrders, loading };
}

// Function to fetch list of all tokens supported by paraswap API as per selected network
export function useTokenList() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const {
    value: data,
    error,
    loading: isLoading,
  } = useAsync(async () => {
    if (chainId === 137) {
      const response = await fetch(config.apiTokensList + chainId);
      const json = await response.json();
      return json;
    }
  }, [account, chainId]);

  return { data, error, isLoading };
}

// Function to get network options as per network selected
export function useNetworkOptions(type) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchNetworkOptions = async () => {
      let options;
      if (chainId === 137) {
        const price = await axios
          .get(config.maticGasStationEndpoint)
          .then((response) => {
            return response.data?.standard.maxFee;
          });
        if (type === "issue") {
          options = {
            gasPrice: web3.utils.toWei(Math.ceil(price).toString(), "gwei"),
          };
        } else {
          options = {
            gasPrice: web3.utils.toWei(Math.ceil(price).toString(), "gwei"),
          };
        }
      } else {
        options = { gasLimit: type === "issue" ? "8721975" : "2000000" };
      }
      setDataList(options);
      setLoading(false);
    };
    fetchNetworkOptions();
  }, [account, chainId, transport]);

  return { data: dataList, loading };
}

// Function to fetch cash issue request for ROLE:Custodian
export function useCashIssueRequestList() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  let clientContract;
  if (provider && provider.getSigner && chainId && address) {
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
  }
  useEffect(() => {
    const fetchCashIssueRequests = async () => {
      if (clientContract) {
        const result = await clientContract.getCustodyAccount(account);
        let custodianDetail = await Response.array(result);
        custodianDetail = custodianDetail.map((detail) => {
          const currencyCountryPair = Response.parseBytes32Value(
            detail.currency
          );
          return {
            accountId: Response.parseBytes32Value(detail.account),
            country: currencyCountryPair.substring(0, 2),
          };
        });
        const uniqueCustodianDetail = custodianDetail.filter(
          (obj, index, self) => {
            return (
              index === self.findIndex((o) => o.accountId === obj.accountId)
            );
          }
        );

        if (uniqueCustodianDetail.length) {
          uniqueCustodianDetail?.map(async (custodian) => {
            await paymentGateway
              .listCashIssueRequests(
                custodian.accountId,
                custodian.country,
                chainId
              )
              .then(async (payments) => {
                const cashTokens = contractAddress[chainId].CASH;
                const promises = await Promise.all(
                  payments.map(async (element, id) => {
                    const findKeyByValue = (object, value) =>
                      Object.keys(cashTokens).find(
                        (key) => object[key] === value
                      );
                    const cashTokenName = findKeyByValue(
                      cashTokens,
                      element.requestProduct
                    );
                    const { ownerName } = await getOwnerDetails(
                      element.userAddress,
                      clientContract
                    );
                    let cashContract;
                    if (provider && provider.getSigner && chainId && address) {
                      cashContract = new Cash(
                        provider.getSigner(address),
                        element.requestProduct
                      );
                    }
                    return {
                      id,
                      userID: element.userAddress,
                      userName: ownerName,
                      country: custodian.country,
                      requestID: element.paymentRef,
                      formattedDate: new Date(
                        element.date
                      ).toLocaleDateString(),
                      currencyName: element.currency,
                      quantity: element.amount,
                      requestType: "Cash",
                      status: element.status,
                      requestToken: element.requestProduct,
                      requestTokenName: cashTokenName,
                      action:
                        element.status === "requested" ? (
                          <DropdownButton
                            id="dropdown-basic-button"
                            title={"Action"}
                          >
                            <Dropdown.Item
                              onClick={() => {
                                handlePaymentIssueOption(
                                  element,
                                  "Confirm",
                                  custodian.country,
                                  account,
                                  chainId,
                                  cashContract,
                                  web3,
                                  setLoading
                                );
                              }}
                            >
                              Confirm
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                handlePaymentIssueOption(
                                  element,
                                  "Reject",
                                  custodian.country,
                                  account,
                                  chainId,
                                  cashContract,
                                  web3,
                                  setLoading
                                );
                              }}
                            >
                              Reject
                            </Dropdown.Item>
                          </DropdownButton>
                        ) : (
                          ""
                        ),
                    };
                  })
                );
                setDataList(promises);
                setLoading(false);
              })
              .catch((error) => {
                toast.error("Error loading issue requests ", ToastOptions);
              })
              .finally(() => {
                setLoading(false);
              });
          });
        } else {
          setLoading(false);
          setDataList([]);
        }
      }
    };
    fetchCashIssueRequests();
  }, [account, chainId, transport]);

  return { data: dataList, loading };
}

// Function to fetch cash withdraw request for ROLE:Custodian
export function useCashIssueWithdrawList() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, clientContract, cashContract, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
    if (contractAddress[chainId].Cash) {
      cashContract = new Cash(
        provider.getSigner(address),
        contractAddress[chainId].Cash
      );
    }
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCashWithdrawlRequests = async () => {
      if (clientContract) {
        const result = await clientContract.getCustodyAccount(account);
        let custodianDetail = await Response.array(result);
        custodianDetail = custodianDetail.map((detail) => {
          const currencyCountryPair = Response.parseBytes32Value(
            detail.currency
          );
          return {
            accountId: Response.parseBytes32Value(detail.account),
            country: currencyCountryPair.substring(0, 2),
          };
        });
        const uniqueCustodianDetail = custodianDetail.filter(
          (obj, index, self) => {
            return (
              index === self.findIndex((o) => o.accountId === obj.accountId)
            );
          }
        );

        uniqueCustodianDetail?.map(async (custodian) => {
          await paymentGateway
            .listWithdrawRequests(
              custodian.accountId,
              custodian.country,
              chainId
            )
            .then(async (payments) => {
              const cashTokens = contractAddress[chainId].CASH;
              const promises = await Promise.all(
                payments.map(async (element, id) => {
                  const findKeyByValue = (object, value) =>
                    Object.keys(cashTokens).find(
                      (key) => object[key] === value
                    );
                  const cashTokenName = findKeyByValue(
                    cashTokens,
                    element.requestProduct
                  );
                  const { ownerName } = await getOwnerDetails(
                    element.userAddress,
                    clientContract
                  );
                  return {
                    id,
                    userID: element.userAddress,
                    userName: ownerName,
                    country: custodian.country,
                    requestID: element.paymentRef,
                    formattedDate: new Date(element.date).toLocaleDateString(),
                    currencyName: element.currency,
                    quantity: element.amount,
                    requestType: "Cash",
                    status: element.status,
                    requestToken: element.requestProduct,
                    requestTokenName: cashTokenName,
                    action:
                      element.status === "requested" ? (
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={"Action"}
                        >
                          <Dropdown.Item
                            onClick={() => {
                              handlePaymentIssueOption(
                                element,
                                "Confirm",
                                custodian.country,
                                account,
                                chainId,
                                cashContract,
                                web3,
                                setLoading
                              );
                            }}
                          >
                            Confirm
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              handlePaymentIssueOption(
                                element,
                                "Reject",
                                custodian.country,
                                account,
                                chainId,
                                cashContract,
                                web3,
                                setLoading
                              );
                            }}
                          >
                            Reject
                          </Dropdown.Item>
                        </DropdownButton>
                      ) : (
                        ""
                      ),
                  };
                })
              );
              setDataList(promises);
              setLoading(false);
            })
            .catch((error) => {
              toast.error("Error loading issue requests ", ToastOptions);
            })
            .finally(() => {
              setLoading(false);
            });
        });
      }
    };
    fetchCashWithdrawlRequests();
  }, [account, chainId, transport]);

  return { data: dataList, loading };
}

// Function to fetch client KYC details
export function useClientKYCData(
  isProcessed = false,
  getImages = false,
  userCountry = ""
) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, clientContract, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data: usersList } = useVerifiedUsers();
  const { userRole } = useGlobal();

  useEffect(() => {
    setLoading(true);
    const fetchKYCData = async () => {
      const modifiedData = await Promise.all(
        usersList.map(async (data) => {
          if (userCountry !== "") {
            if (
              Response.parseBytes32Value(data.country).toLowerCase() ===
              userCountry.toLowerCase()
            ) {
              let arrayReturn, email;
              if (userRole === "KYCAML") {
                const clientKYCData = await clientContract.getClientKYC(
                  data.client
                );
                const fullClientKYCResult =
                  await clientContract.getFullClientKYC(data.client);
                arrayReturn = fullClientKYCResult?.response.result;
                email = clientKYCData.response.result[4];
              }
              const applicantId = Response.parseBytes32Value(data.accountid);
              const applicantDetails = await axios
                .get(`${config.sumsubEndpoint}?applicantId=${applicantId}`)
                .then((response) => {
                  return response.data;
                })
                .catch((err) => {
                  return null;
                });
              let applicantStatus = applicantDetails
                ? applicantDetails.applicantData.review.reviewStatus
                : null;
              if (applicantStatus === "completed") {
                if (data.status === 2) applicantStatus = "Rejected";
                else if (data.status === 2) applicantStatus = "Accepted";
              }
              const createdAt = applicantDetails
                ? applicantDetails.applicantData.createdAt
                : null;
              const createdAtArray = createdAt
                ? createdAt.split(" ")[0].split("-")
                : null;
              const createdTimeRaw =
                createdAtArray && createdAtArray.length === 3
                  ? convertToBlockTimestamp(
                      createdAtArray[2],
                      createdAtArray[1],
                      createdAtArray[0]
                    )
                  : 0;
              return {
                createdAt:
                  createdAtArray && createdAtArray.length === 3
                    ? `${createdAtArray[2]}/${createdAtArray[1]}/${createdAtArray[0]}`
                    : "",
                createdTimeRaw,
                id: data.client,
                accountAddress: data.client,
                fullAddress: data.client,
                countryName: Response.parseBytes32Value(data.country),
                name: Response.parseBytes32Value(data.name),
                applicantId: applicantId,
                inspectionId: applicantDetails
                  ? applicantDetails.applicantData.inspectionId
                  : "",
                externalUserId: applicantDetails
                  ? applicantDetails.applicantData.externalUserId
                  : "",
                fullName: Response.parseBytes32Value(data.name),
                email: email ? Response.parseBytes32Value(email) : "",
                emailFmt: email
                  ? Response.parseBytes32Value(email)
                      .toString()
                      .split("@")[0]
                      .substring(0, 5) +
                    "...@" +
                    Response.parseBytes32Value(email).toString().split("@")[1]
                  : "",
                identityURL: arrayReturn ? arrayReturn[1] : "",
                selfieURL: arrayReturn ? arrayReturn[2] : "",
                status: data.status,
                applicantStatus: applicantStatus
                  ? applicantStatus.replace(
                      /^./,
                      applicantStatus[0].toUpperCase()
                    )
                  : "",
                action: true,
                isProcessed: isProcessed,
                applicantData: applicantDetails
                  ? applicantDetails.applicantData
                  : null,
              };
            }
          } else {
            let arrayReturn, email;
            if (userRole === "KYCAML") {
              const clientKYCData = await clientContract.getClientKYC(
                data.client
              );
              const fullClientKYCResult = await clientContract.getFullClientKYC(
                data.client
              );
              arrayReturn = fullClientKYCResult?.response.result;
              email = clientKYCData.response.result[4];
            }

            const applicantId = Response.parseBytes32Value(data.accountid);
            const applicantDetails = await axios
              .get(`${config.sumsubEndpoint}?applicantId=${applicantId}`)
              .then((response) => {
                return response.data;
              })
              .catch((err) => {
                return null;
              });
            let applicantStatus = applicantDetails
              ? applicantDetails.applicantData.review.reviewStatus
              : null;
            if (applicantStatus === "completed") {
              if (data.status === 2) applicantStatus = "Rejected";
              else if (data.status === 2) applicantStatus = "Accepted";
            }
            const createdAt = applicantDetails
              ? applicantDetails.applicantData.createdAt
              : null;
            const createdAtArray = createdAt
              ? createdAt.split(" ")[0].split("-")
              : null;
            const createdTimeRaw =
              createdAtArray && createdAtArray.length === 3
                ? convertToBlockTimestamp(
                    createdAtArray[2],
                    createdAtArray[1],
                    createdAtArray[0]
                  )
                : 0;
            return {
              createdAt:
                createdAtArray && createdAtArray.length === 3
                  ? `${createdAtArray[2]}/${createdAtArray[1]}/${createdAtArray[0]}`
                  : "",
              createdTimeRaw,
              id: data.client,
              accountAddress: data.client,
              fullAddress: data.client,
              countryName: Response.parseBytes32Value(data.country),
              name: Response.parseBytes32Value(data.name),
              applicantId: applicantId,
              inspectionId: applicantDetails
                ? applicantDetails.applicantData.inspectionId
                : "",
              externalUserId: applicantDetails
                ? applicantDetails.applicantData.externalUserId
                : "",
              fullName: Response.parseBytes32Value(data.name),
              email: email ? Response.parseBytes32Value(email) : "",
              emailFmt: email
                ? Response.parseBytes32Value(email)
                    .toString()
                    .split("@")[0]
                    .substring(0, 5) +
                  "...@" +
                  Response.parseBytes32Value(email).toString().split("@")[1]
                : "",
              identityURL: arrayReturn ? arrayReturn[1] : "",
              selfieURL: arrayReturn ? arrayReturn[2] : "",
              status: data.status,
              applicantStatus: applicantStatus
                ? applicantStatus.replace(
                    /^./,
                    applicantStatus[0].toUpperCase()
                  )
                : "",
              action: true,
              isProcessed: isProcessed,
              applicantData: applicantDetails
                ? applicantDetails.applicantData
                : null,
            };
          }
        })
      );
      setDataList(
        modifiedData.sort((a, b) => b.createdTimeRaw - a.createdTimeRaw)
      );
      setLoading(false);
    };
    fetchKYCData();
  }, [account, chainId, usersList, transport, userCountry, getImages]);

  return { data: dataList, loading: loading };
}

// Function to fetch corporate resolutions data. ROLE: INVESTOR/ISSUE_MANAGER
export function useResolutionsData(type, view) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data: secondaryPools, loading: secondaryLoading } =
    useBalancerPoolsData(PoolType.SecondaryIssue);
  const { data: primaryPools, loading: primaryLoading } = useBalancerPoolsData(
    PoolType.PrimaryIssue
  );
  useEffect(() => {
    const fetchResolutions = async () => {
      let allPools;
      if (type === PoolType.PrimaryIssue) {
        allPools = primaryPools;
      } else if (type === PoolType.SecondaryIssue) {
        allPools = secondaryPools;
      } else if (type === "All") {
        allPools = [...secondaryPools, ...primaryPools];
      }

      let resolutionDataStack = [];
      if (view === "investorFacing") {
        allPools = allPools.filter(
          (data) => data.verifiedWalletData?.resolutions?.length
        );
        resolutionDataStack = await Promise.all(
          allPools.map(async (pool) => {
            const isPrimarySubscriber =
              pool.verifiedWalletData.primarySubscribers?.find(
                (data) =>
                  data.investor.id.toLowerCase() === account.toLowerCase()
              );
            const isSecondaryInvestor =
              pool.verifiedWalletData.secondaryInvestors?.find(
                (data) =>
                  data.investor.id.toLowerCase() === account.toLowerCase() ||
                  data.issuer.id.toLowerCase() === account.toLowerCase()
              );
            if (isPrimarySubscriber || isSecondaryInvestor) {
              const resolutionPromises =
                pool.verifiedWalletData.resolutions.map(async (resolution) => {
                  const securityTokenContract = new web3.eth.Contract(
                    ERC20ABI,
                    resolution.security.id
                  );
                  const securityTokenSymbol =
                    await securityTokenContract.methods.symbol().call();
                  const voteCounts = await securityTokenContract.methods
                    .countVotes(resolution.recordDate)
                    .call();
                  let votingDisabled = false;
                  let isInvestor = false;
                  const currentDate = new Date();
                  if (new Date(resolution.recordDate * 1000) < currentDate)
                    votingDisabled = true;
                  return {
                    id: resolution.security.id + resolution.recordDate,
                    securityAddress: resolution.security.id,
                    securityTokenSymbol: securityTokenSymbol,
                    offeringDocs: resolution.resolution,
                    date: resolution.recordDate,
                    isVotable: resolution.voting ? "Yes" : "No",
                    poolType: pool.poolType,
                    votingDisabled,
                    totalVotes: voteCounts?.total,
                    totalPositives: voteCounts?.yes,
                  };
                });

              return Promise.all(resolutionPromises);
            }
          })
        );
      } else {
        resolutionDataStack = await Promise.all(
          allPools.map(async (pool) => {
            if (
              pool.verifiedWalletData?.resolutions?.length &&
              pool.verifiedWalletData?.issueManager.toLowerCase() ===
                account.toLowerCase()
            ) {
              const resolutionPromises =
                pool.verifiedWalletData.resolutions.map(async (resolution) => {
                  const securityTokenContract = new web3.eth.Contract(
                    ERC20ABI,
                    resolution.security.id
                  );
                  const securityTokenSymbol =
                    await securityTokenContract.methods.symbol().call();
                  const voteCounts = await securityTokenContract.methods
                    .countVotes(resolution.recordDate)
                    .call();
                  let votingDisabled = false;
                  let isInvestor = false;
                  const currentDate = new Date();
                  if (new Date(resolution.recordDate * 1000) < currentDate)
                    votingDisabled = true;
                  const primarySubscribers =
                    pool.verifiedWalletData.primarySubscribers;
                  const secondaryInvestors =
                    pool.verifiedWalletData.secondaryInvestors;
                  if (primarySubscribers.length || secondaryInvestors.length) {
                    return {
                      id: resolution.security.id + resolution.recordDate,
                      securityAddress: resolution.security.id,
                      securityTokenSymbol: securityTokenSymbol,
                      offeringDocs: resolution.resolution,
                      date: resolution.recordDate,
                      isVotable: resolution.voting ? "Yes" : "No",
                      poolType: pool.poolType,
                      votingDisabled,
                      totalVotes: voteCounts?.total,
                      totalPositives: voteCounts?.yes,
                    };
                  }
                });

              return Promise.all(resolutionPromises);
            }
          })
        );
      }
      // Flatten the resolutionDataStack array
      resolutionDataStack = resolutionDataStack
        .flat()
        .filter((item) => item !== undefined);

      setDataList(resolutionDataStack);
      setLoading(false);
    };
    fetchResolutions();
  }, [account, chainId, primaryPools, secondaryPools, transport]);

  return { data: dataList, loading: secondaryLoading && primaryLoading };
}

// Function to fetch distribution data
export function useDistributionsData() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data: loggedUser } = useVerifiedUser(account);
  // console.log("log user: ", loggedUser);
  useEffect(() => {
    const fetchResolutions = async () => {
      const finalData =
        loggedUser?.earnedByInvestor?.length > 0
          ? await Promise.all(
              loggedUser?.earnedByInvestor?.map(async (data) => {
                if (data && data.vitta) {
                  const tokenContract = new web3.eth.Contract(
                    ERC20ABI,
                    data.vitta
                  );
                  const tokenSymbol = await tokenContract.methods
                    .symbol()
                    .call();
                  return {
                    id: data.platform.id,
                    platformId: data.platform.id,
                    platformName: Response.parseBytes32Value(
                      data.platform.name
                    ),
                    earnings:
                      Response.parseTokenAmount(data.prorataStake, 18).toFixed(
                        2
                      ) +
                      " " +
                      tokenSymbol,
                  };
                }
              })
            )
          : [];
      setDataList(finalData);
      setLoading(false);
    };
    fetchResolutions();
  }, [account, chainId, loggedUser, transport]);

  return { data: dataList, loading };
}

// Function to fetch revenue shared listings. ROLE: ADMIN
export function useRevenueShareList() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, clientContract, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data: usersList } = useVerifiedUsers();

  useEffect(() => {
    const fetchResolutions = async () => {
      const revenueListFiltered = usersList
        ?.map((user) => user.shareholder)
        .filter((obj) => obj.length > 0);

      const finalData = await Promise.all(
        revenueListFiltered.flat()?.map(async (revenue) => {
          const { ownerName, ownerCountry } = await getOwnerDetails(
            revenue.shareholder.id,
            clientContract
          );
          const currencyTokenContract = new web3.eth.Contract(
            ERC20ABI,
            revenue.token
          );
          const tokenDecimals = await currencyTokenContract.methods
            .decimals()
            .call();
          const tokenSymbol = await currencyTokenContract.methods
            .symbol()
            .call();

          return {
            id: revenue.amount,
            accountHolder: ownerName,
            shareholderType: Response.parseBytes32Value(
              revenue.shareholderType
            ),
            tokenName: tokenSymbol,
            date: revenue.timestamp,
            tokenAmount: Response.parseTokenAmount(
              revenue.amount.toString(),
              tokenDecimals
            ),
          };
        })
      );
      setDataList(finalData);
      setLoading(false);
    };
    fetchResolutions();
  }, [account, chainId, usersList, transport]);

  return { data: dataList, loading };
}

// Function to fetch custodian data and currency supported by custodian.
export function useCustodianData(
  custodianAddress,
  securityOffered,
  issuerAddress
) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, securitiesFactory, clientContract, factoryContract, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    if (contractAddress[chainId].SecuritiesFactory) {
      securitiesFactory = new SecuritiesFactory(
        provider.getSigner(address),
        contractAddress[chainId].SecuritiesFactory
      );
    }
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
    if (contractAddress[chainId].Factory) {
      factoryContract = new Factory(
        provider.getSigner(address),
        contractAddress[chainId].Factory
      );
    }
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCustodianData = async () => {
      let custodianDetail = [];
      if (securityOffered && issuerAddress) {
        const response = await securitiesFactory.getCustodian(
          securityOffered,
          issuerAddress
        );
        const custodianResponse = await Response.array(response);
        custodianAddress = custodianResponse[0];
      }
      if (custodianAddress && custodianAddress !== "0x") {
        const result = await clientContract?.getCustodyAccount(
          custodianAddress
        );
        custodianDetail = await Response.array(result);
        custodianDetail = await Promise.all(
          custodianDetail.map(async (detail) => {
            const currencyCountryPair = Response.parseBytes32Value(
              detail.currency
            );
            const currency = currencyCountryPair.substring(
              2,
              currencyCountryPair.length
            );
            const issueCurrency = "VC" + currency;
            const response = await factoryContract.getTokenByNameType(
              issueCurrency,
              "ViaCash"
            );
            const issueCurrencyAddress = await Response.array(response);
            let cashBalance;
            if (provider && provider.getSigner && chainId && address) {
              const currencyTokenContract = new web3.eth.Contract(
                ERC20ABI,
                issueCurrencyAddress[0]
              );
              const tokenDecimals = await currencyTokenContract.methods
                .decimals()
                .call();
              cashBalance = await currencyTokenContract.methods
                .balanceOf(account)
                .call();
              cashBalance = Response.parseTokenAmount(
                cashBalance.toString(),
                tokenDecimals
              );
            }
            return {
              accountId: Response.parseBytes32Value(detail.account),
              country: currencyCountryPair.substring(0, 2),
              currency,
              issueCurrency,
              issueCurrencyAddress: issueCurrencyAddress[0],
              cashBalance,
              decimals: 18,
              fiat: true,
            };
          })
        );
        setDataList(custodianDetail);
        setLoading(false);
      } else {
        setDataList([]);
        setLoading(false);
      }
    };
    fetchCustodianData();
  }, [custodianAddress, securityOffered, issuerAddress, transport]);

  return { data: dataList, loading };
}

// Function to compute candlestick chart data
export function useChartData(verifiedSecurity, pool) {
  const [priceData, setPriceData] = useState([]);
  const [volumeData, setVolumeData] = useState([]);
  const [totalVolume, setTotalVolume] = useState(0.0);
  const [tlp, setTlp] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchChartData = async () => {
      if (verifiedSecurity && pool && pool.poolType !== PoolType.MarginIssue) {
        const subscriptions =
          pool?.poolType === "PrimaryIssue"
            ? verifiedSecurity.primarySubscribers
            : verifiedSecurity.secondaryInvestors;
        // console.log("subs....: ", subscriptions);
        if (subscriptions && subscriptions.length > 0) {
          const securityDecimals = pool?.tokens?.filter(
            (token) =>
              token.address.toLowerCase() === pool.security.toLowerCase()
          )[0].decimals;
          let currencyDecimals = pool?.tokens?.filter(
            (token) =>
              token.address.toLowerCase() === pool.currency.toLowerCase()
          )[0].decimals;

          const subscribersKey =
            pool?.poolType === "PrimaryIssue" ? "securitySwapped" : "amount";
          const cashsubscribersKey =
            pool?.poolType === "PrimaryIssue" ? "cashSwapped" : "price";
          currencyDecimals =
            pool?.poolType === "PrimaryIssue" ? currencyDecimals : 18;
          let modifiedPriceArray = [];
          let totalVolume = 0;
          await Promise.all(
            subscriptions.map((data) => {
              modifiedPriceArray.push({
                ...data,
                price:
                  pool?.poolType === "PrimaryIssue"
                    ? Response.parseTokenAmount(
                        data[cashsubscribersKey],
                        currencyDecimals
                      ) /
                      Response.parseTokenAmount(
                        data[subscribersKey],
                        securityDecimals
                      )
                    : Response.parseTokenAmount(data.price, 18),
              });
            })
          );
          let priceDataFmt = [];
          if (modifiedPriceArray.length > 0) {
            setTlp(
              modifiedPriceArray.sort((a, b) => a.timestamp - b.timestamp)
            );
            priceDataFmt = await Promise.all(
              modifiedPriceArray
                .sort((a, b) => a.timestamp - b.timestamp)
                .map((data) => {
                  const date = new Date(parseInt(data["timestamp"]) * 1000);
                  const formattedDate = format(date, "yyyy-MM-dd hh:mm a");
                  return [formattedDate, Number(data.price)];
                })
            );
          }
          let volumeDataFmt = [];
          if (priceDataFmt.length > 0) {
            const ohlcData = formatHourlyData(priceDataFmt);
            setPriceData(ohlcData);
            volumeDataFmt = await Promise.all(
              modifiedPriceArray
                .sort((a, b) => a.timestamp - b.timestamp)
                .map((data) => {
                  totalVolume += Response.parseTokenAmount(
                    data[subscribersKey],
                    securityDecimals
                  );
                  const date = new Date(parseInt(data["timestamp"]) * 1000);
                  const formattedDate = format(date, "yyyy-MM-dd hh:mm a");
                  return [
                    formattedDate,
                    Response.parseTokenAmount(
                      data[subscribersKey],
                      securityDecimals
                    ),
                  ];
                })
            );
          }
          if (volumeDataFmt.length > 0) {
            setVolumeData(volumeDataFmt);
            setTotalVolume(totalVolume);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    fetchChartData();
  }, [verifiedSecurity, pool]);
  // console.log("pricedata: ", priceData);

  return {
    priceData: priceData,
    volumeData: volumeData,
    totalVolume: totalVolume,
    ltp: tlp,
    loading: loading,
  };
}

export function useInvestorData() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, liquidityContract, clientContract, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    if (contractAddress[chainId].Liquidity) {
      liquidityContract = new Liquidity(
        provider.getSigner(address),
        contractAddress[chainId].Liquidity
      );
    }
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data } = useVerifiedCurrenciesList();

  useEffect(() => {
    const fetchInvestors = async () => {
      if (data.length) {
        const promises = await Promise.all(
          data.map(async (eachEntry) => {
            const reducedData = eachEntry.liquidityTokenRequests?.reduce(
              (acc, entry) => {
                const investorId = entry.investor.id;
                const tokenAmount = parseInt(entry.tokenAmount);
                if (!acc[investorId]) {
                  acc[investorId] = {
                    investor: entry.investor,
                    token: entry.token,
                    totalTokenAmount: 0,
                  };
                }
                acc[investorId].totalTokenAmount += tokenAmount;
                return acc;
              },
              {}
            );
            let reducedIssuedData = {};
            await eachEntry.liquidityTokenIssues?.map(async (entry) => {
              const investorId = entry.investor.id.toLowerCase();
              const tokenAmount = parseInt(entry.tokenAmount);
              if (!reducedIssuedData[investorId]) {
                reducedIssuedData[investorId] = {
                  investor: entry.investor,
                  token: entry.token,
                  tokenDecimals: await new web3.eth.Contract(
                    ERC20ABI,
                    entry.token.id
                  ).methods
                    .decimals()
                    .call(),
                  totalTokenAmount: 0,
                };
              }
              reducedIssuedData[investorId].totalTokenAmount += tokenAmount;
            }); //don't use .reduce for accurate result
            const result = Object.values(reducedData);
            const finalData = await Promise.all(
              result.map(async (entry) => {
                let tokenDecimals;
                if (entry.token.id !== ethers.constants.AddressZero) {
                  const currencyTokenContract = new web3.eth.Contract(
                    ERC20ABI,
                    entry.token.id
                  );
                  tokenDecimals = await currencyTokenContract.methods
                    .decimals()
                    .call();
                } else {
                  tokenDecimals = 18;
                }
                const roleData = await clientContract
                  .getRole(entry.investor.id)
                  .then((response) => Response.array(response))
                  .then((response) => Response.parseBytes32Array(response))
                  .then((role) => {
                    return role;
                  });
                return {
                  id: entry.investor.id + entry.token.id,
                  address: entry.investor.id,
                  tokenName: entry.token.name,
                  tokenAddress: entry.token.id,
                  investorName: Response.parseBytes32Value(entry.investor.name),
                  investorCountry: Response.parseBytes32Value(
                    entry.investor.country
                  ),
                  amountInvested: Response.parseTokenAmount(
                    entry.totalTokenAmount.toString(),
                    tokenDecimals
                  ),
                  amountIssued: reducedIssuedData[
                    entry.investor.id.toLowerCase()
                  ]
                    ? Response.parseTokenAmount(
                        reducedIssuedData[
                          entry.investor.id.toLowerCase()
                        ].totalTokenAmount.toString(),
                        reducedIssuedData[entry.investor.id.toLowerCase()]
                          .tokenDecimals
                      )
                    : 0,
                  amountInvestedCurrencyFixed:
                    entry.totalTokenAmount.toString(),
                  role: roleData[0],
                  country: roleData[2],
                };
              })
            );
            return finalData;
          })
        );
        setDataList(promises.flat());
        setLoading(false);
      } else {
        setDataList([]);
        setLoading(false);
      }
    };
    fetchInvestors();
  }, [account, chainId, data, transport]);

  return { data: dataList, loading };
}

// Function to fetch liquidity issued data
export function useLiquidityIssuesData(userRole) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, clientContract, web3;
  if (provider && provider.getSigner && chainId && address && transport) {
    signer = provider.getSigner(address);
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data } = useVerifiedCurrenciesList();

  useEffect(() => {
    const fetchInvestors = async () => {
      const promises = await Promise.all(
        data.map(async (eachEntry) => {
          if (userRole !== "AM") {
            const result = eachEntry.liquidityTokenIssues.filter(
              (entry) =>
                entry.investor.id.toLowerCase() === account.toLowerCase()
            );
            const finalData = await Promise.all(
              result.map(async (entry) => {
                const currencyTokenContract = new web3.eth.Contract(
                  ERC20ABI,
                  entry.token.id
                );
                const tokenDecimals = await currencyTokenContract.methods
                  .decimals()
                  .call();
                return {
                  id: entry.investor.id,
                  address: entry.investor.id,
                  tokenName: entry.token.name,
                  investorName: Response.parseBytes32Value(entry.investor.name),
                  investorCountry: Response.parseBytes32Value(
                    entry.investor.country
                  ),
                  amountInvested: Response.parseTokenAmount(
                    entry.tokenAmount.toString(),
                    tokenDecimals
                  ),
                  liquidityTokens:
                    Response.parseTokenAmount(entry.LPToIssue.toString(), 18) +
                    " VITTA",
                };
              })
            );
            setDataList(finalData);
            setLoading(false);
          } else if (userRole === "AM") {
            const managerTokenAmounts = {};
            eachEntry.liquidityOnPlatform.forEach((entry) => {
              const managerId = entry.manager.id;
              const platformId = entry.platform.id;
              const tokenAmount = parseInt(entry.tokenAmount);
              const lptoIssueAmount = parseInt(entry.LPToken);
              const token = entry.token.id;

              if (!managerTokenAmounts[platformId]) {
                managerTokenAmounts[platformId] = {
                  platform: entry.platform,
                  managers: {},
                };
              }

              if (!managerTokenAmounts[platformId].managers[managerId]) {
                managerTokenAmounts[platformId].managers[managerId] = {
                  manager: entry.manager,
                  tokens: {},
                };
              }

              if (
                !managerTokenAmounts[platformId].managers[managerId].tokens[
                  token
                ]
              ) {
                managerTokenAmounts[platformId].managers[managerId].tokens[
                  token
                ] = {
                  tokenAmount: 0,
                  lptoIssueAmount: 0,
                };
              }

              managerTokenAmounts[platformId].managers[managerId].tokens[
                token
              ].tokenAmount += Number(tokenAmount);
              managerTokenAmounts[platformId].managers[managerId].tokens[
                token
              ].lptoIssueAmount += Number(lptoIssueAmount);
            });

            let resultStack = [];
            async function processManagerTokenAmount() {
              // Loop through platform-wise data
              for (const platformId in managerTokenAmounts) {
                const platformData = managerTokenAmounts[platformId];
                const platform = platformData.platform;
                // Loop through manager-wise data
                for (const managerId in platformData.managers) {
                  const managerData = platformData.managers[managerId];
                  const manager = managerData.manager;
                  // Loop through token-wise data
                  for (const token in managerData.tokens) {
                    const tokenData = managerData.tokens[token];
                    const tokenAmount = tokenData.tokenAmount;
                    const lptoIssueAmount = tokenData.lptoIssueAmount;
                    const currencyTokenContract = new web3.eth.Contract(
                      ERC20ABI,
                      token
                    );
                    const tokenDecimals = await currencyTokenContract.methods
                      .decimals()
                      .call();
                    const tokenName = await currencyTokenContract.methods
                      .name()
                      .call();
                    resultStack.push({
                      id: platform.id,
                      address: platform.id,
                      platformName: Response.parseBytes32Value(platform.name),
                      currency: token,
                      managerAddress: managerId,
                      tokenName: tokenName,
                      tokenDecimals,
                      investorName: Response.parseBytes32Value(manager.name),
                      investorCountry: Response.parseBytes32Value(
                        manager.country
                      ),
                      amountInvested: Response.parseTokenAmount(
                        tokenAmount.toString(),
                        tokenDecimals
                      ),
                      liquidityTokens:
                        Response.parseTokenAmount(
                          lptoIssueAmount.toString(),
                          18
                        ) + " VITTA",
                    });
                  }
                }
              }
            }

            // Call the async function
            async function run() {
              await processManagerTokenAmount();
              const filteredData = resultStack.map((entry) => {
                if (eachEntry.liquidityEarningsByManager.length) {
                  const distributionAmount =
                    eachEntry.liquidityEarningsByManager?.find(
                      (data) =>
                        data.platform.id === entry.address &&
                        data.manager.id === entry.managerAddress &&
                        data.token.id === entry.currency
                    )?.distribution;
                  return {
                    ...entry,
                    returns: Response.parseTokenAmount(
                      distributionAmount ? distributionAmount.toString() : 0,
                      entry.tokenDecimals
                    ),
                  };
                } else {
                  return { ...entry, returns: 0 };
                }
              });
              const filteredResult = filteredData.filter(
                (value) =>
                  value !== undefined &&
                  value.managerAddress.toLowerCase() === account.toLowerCase()
              );
              setDataList(filteredResult);
              setLoading(false);
            }
            // Call the async function
            run();
          }
        })
      );
    };
    fetchInvestors();
  }, [account, chainId, data, userRole, transport]);

  return { data: dataList, loading };
}

// Function to fetch supported token list by verified dapp
export function useSupportedTokens() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, liquidityContract, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    if (contractAddress[chainId].Liquidity) {
      liquidityContract = new Liquidity(
        provider.getSigner(address),
        contractAddress[chainId].Liquidity
      );
    }
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    const fetchSupportedTokens = async () => {
      if (liquidityContract) {
        const response = await liquidityContract.getSupportedTokens();
        // console.log("response: ", response);
        let json = response.response.result;
        if (json) {
          json = await Promise.all(
            json.map(async (token) => {
              const tokenName = token.tokenName.replace(/\0/g, "");
              const isFiat = tokenName.substring(0, 2) === "VC" ? true : false;
              if (token.tokenAddress !== ethers.constants.AddressZero) {
                const currencyContract = new web3.eth.Contract(
                  ERC20ABI,
                  token.tokenAddress
                );
                const currencyDecimals = await currencyContract.methods
                  .decimals()
                  .call();
                var currencyBalance = await currencyContract.methods
                  .balanceOf(account)
                  .call();
                currencyBalance = Response.parseTokenAmount(
                  currencyBalance.toString(),
                  currencyDecimals
                );
              } else {
                const balance = await web3.eth.getBalance(account);
                currencyBalance = web3.utils.fromWei(balance, "ether");
              }
              return {
                id: token.tokenAddress,
                tokenAddress: token.tokenAddress,
                tokenName,
                isFiat,
                balance: currencyBalance,
              };
            })
          );
          setDataList(json);
          setLoading(false);
        }
      }
    };
    if (address && chainId && provider && provider.getSigner) {
      fetchSupportedTokens();
    }
  }, [account, chainId, transport]);

  return { data: dataList, loading };
}

// Function to fetch supported intermediary list by country
export function useIntermediaryList(country, role = "DP") {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, clientContract;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
  }
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState(null);

  const { data: usersList } = useClientKYCData();
  // console.log("....userlist...: ", usersList)
  useEffect(() => {
    const fetchIntermediaries = async () => {
      if (
        country &&
        role &&
        clientContract &&
        usersList &&
        usersList.length > 0
      ) {
        const response = await clientContract.getManagers(country, role);
        const json = response.response.result;
        // console.log("cont: ", country, json);
        const promises = await Promise.all(
          json
            .filter((manager) => manager !== NULL_ADDRESS)
            .map(async (manager) => {
              const dataResult = usersList.find((user) => {
                if (
                  user &&
                  user.accountAddress.toLowerCase() === manager.toLowerCase()
                ) {
                  return user;
                }
              });
              return {
                name: dataResult?.fullName,
                address: manager,
              };
            })
        );
        const isAccountPresent = promises.find(
          (user) => user.address.toLowerCase() === account.toLowerCase()
        );
        if (!isAccountPresent && role === "DP") {
          promises.push({
            name: "Self",
            address: account,
          });
        }
        setDataList(promises);
        setLoading(false);
      }
    };
    fetchIntermediaries();
  }, [account, chainId, country, role, usersList, transport]);

  return { data: dataList, loading };
}

// Function to fetch KYC data
export function useKYCData() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, kycContract;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    if (contractAddress[chainId].Client) {
      kycContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
  }

  const {
    value: data,
    error,
    loading: isLoading,
  } = useAsync(async () => {
    const address = await signer.getAddress();
    // console.log("add: ", address);
    const response = await kycContract.getClientKYC(address);
    return response;
  }, [account, chainId]);

  return { data, error, isLoading };
}

// Function to fetch list of securities offered and issues to be started. ROLE: DP
export function useOfferedSecurities() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const { userRole } = useGlobal();
  let { data: verifiedSecurities } = useVerifiedSecuritiesList();

  let factoryContract,
    primaryIssueManager,
    liquidityContract,
    clientContract,
    BalancerPrimaryIssueManager;
  if (provider && provider.getSigner && chainId && address) {
    if (contractAddress[chainId].BalancerPrimaryIssueManager) {
      BalancerPrimaryIssueManager =
        contractAddress[chainId].BalancerPrimaryIssueManager;
      primaryIssueManager = new PrimaryIssueManager(
        provider.getSigner(address),
        contractAddress[chainId].BalancerPrimaryIssueManager,
        "balancer"
      );
    }
    if (contractAddress[chainId].SecuritiesFactory) {
      factoryContract = new SecuritiesFactory(
        provider.getSigner(address),
        contractAddress[chainId].SecuritiesFactory
      );
    }
    if (contractAddress[chainId].Liquidity) {
      liquidityContract = new Liquidity(
        provider.getSigner(address),
        contractAddress[chainId].Liquidity
      );
    }
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
  }

  useEffect(() => {
    const fetchTokens = async () => {
      if (
        provider &&
        provider.getSigner &&
        chainId &&
        address &&
        liquidityContract
      ) {
        const response = await liquidityContract.getSupportedTokens();
        // const response = await new web3.eth.Contract(
        //   LiquidityAbi.abi,
        //   contractAddress[chainId].Liquidity
        // ).methods
        //   .getSupportedTokens()
        //   .call(); //to fix tempOut error from verified sdk
        if (
          response &&
          BalancerPrimaryIssueManager &&
          verifiedSecurities &&
          verifiedSecurities.length > 0
        ) {
          setLoading(true);
          const currencyToken = response.response.result;
          const promises = await currencyToken.map(
            async (token, tokenIndex) => {
              const res = await new web3.eth.Contract(
                primaryIssueManagerAbi.abi,
                BalancerPrimaryIssueManager
              ).methods
                .getOffered(token?.tokenAddress)
                .call({ from: account });
              const securityOffered = res;
              let tokenDecimals;
              if (token.tokenAddress !== ethers.constants.AddressZero) {
                const currencyTokenContract = new web3.eth.Contract(
                  ERC20ABI,
                  token.tokenAddress
                );
                tokenDecimals = await currencyTokenContract.methods
                  .decimals()
                  .call();
              } else {
                tokenDecimals = 18;
              }

              const offered = await securityOffered?.map(
                async (security, index) => {
                  const securityTokenContract = new web3.eth.Contract(
                    ERC20ABI,
                    security["offered"]
                  );
                  const securityTokenSymbol =
                    await securityTokenContract.methods.symbol().call();

                  const { ownerName, ownerCountry } = await getOwnerDetails(
                    security["owner"],
                    clientContract
                  );

                  const securityData =
                    verifiedSecurities &&
                    verifiedSecurities.length > 0 &&
                    verifiedSecurities?.filter(
                      (data) =>
                        data.security.toLowerCase() ===
                        security["offered"].toLowerCase()
                    );

                  const filteredLiquidityOffered = securityData
                    .map((data) => {
                      return data.liquidityOffered;
                    })
                    .flat();
                  const filteredLiquidityProviders = securityData
                    .map((data) => {
                      return data.liquidityProviders;
                    })
                    .flat();
                  const filteredSettlements = securityData
                    .map((data) => {
                      return data.primarySettlements;
                    })
                    .flat();
                  const filteredClosed = securityData
                    .map((data) => {
                      return data.subscriptionsClosed;
                    })
                    .flat();
                  const activeIssue = filteredLiquidityProviders.length
                    ? true
                    : false;

                  const liquidityOffered = await Promise.all(
                    filteredLiquidityOffered.map(async (data) => {
                      const { amount, desired, minimum, offeredBy, ...rest } =
                        data;
                      const currencyTokenContract = new web3.eth.Contract(
                        ERC20ABI,
                        rest.offered
                      );
                      const currencyDecimals =
                        await currencyTokenContract.methods.decimals().call();
                      const currencySymbol = await currencyTokenContract.methods
                        .symbol()
                        .call();
                      const securityTokenContract = new web3.eth.Contract(
                        ERC20ABI,
                        rest.tomatch.id
                      );
                      const securityDecimals =
                        await securityTokenContract.methods.decimals().call();
                      return {
                        ...rest,
                        id: rest.id,
                        amountOffered: Response.parseTokenAmount(
                          amount,
                          currencyDecimals
                        ),
                        desired: Response.parseTokenAmount(
                          desired,
                          securityDecimals
                        ),
                        desiredPrice: (
                          Response.parseTokenAmount(amount, currencyDecimals) /
                          Response.parseTokenAmount(desired, securityDecimals)
                        ).toFixed(2),
                        minimum: Response.parseTokenAmount(
                          minimum,
                          securityDecimals
                        ).toFixed(2),
                        minimumPrice: (
                          Response.parseTokenAmount(amount, currencyDecimals) /
                          Response.parseTokenAmount(minimum, securityDecimals)
                        ).toFixed(2),
                        securityName: Response.parseBytes32Value(
                          rest.isin
                        ).replace(/\0/g, ""),
                        currencyName: currencySymbol,
                        offeredBy: offeredBy.id,
                      };
                    })
                  );

                  const liquidityProvidersData =
                    filteredLiquidityProviders.filter(
                      (data) =>
                        data.owner?.id.toLowerCase() === account.toLowerCase()
                    );

                  const liquidityProviders = await Promise.all(
                    liquidityProvidersData.map(async (data) => {
                      const { tokenOffered, security, ...rest } = data;
                      const currencyTokenContract = new web3.eth.Contract(
                        ERC20ABI,
                        rest.currency
                      );
                      const currencyDecimals =
                        await currencyTokenContract.methods.decimals().call();
                      const currencySymbol = await currencyTokenContract.methods
                        .symbol()
                        .call();
                      return {
                        ...rest,
                        security: security.id,
                        tokenOffered: Response.parseTokenAmount(
                          tokenOffered,
                          currencyDecimals
                        ).toFixed(2),
                        currencyName: currencySymbol,
                      };
                    })
                  );

                  let settlements = await Promise.all(
                    filteredSettlements.map(async (data) => {
                      const { underwritingFee, subscription, ...rest } = data;
                      const currencyTokenContract = new web3.eth.Contract(
                        ERC20ABI,
                        rest.currency
                      );
                      const currencyDecimals =
                        await currencyTokenContract.methods.decimals().call();
                      const currencySymbol = await currencyTokenContract.methods
                        .symbol()
                        .call();
                      return {
                        ...rest,
                        underwritingFee: Response.parseTokenAmount(
                          underwritingFee,
                          currencyDecimals
                        ),
                        subscription: Response.parseTokenAmount(
                          subscription,
                          currencyDecimals
                        ),
                        currencyName: currencySymbol,
                      };
                    })
                  );
                  settlements = settlements.filter(
                    (data) =>
                      data.liquidityProvider.id.toLowerCase() ===
                      account.toLowerCase()
                  );

                  return {
                    id: index + tokenIndex,
                    owner: security["owner"],
                    ownerName: ownerName,
                    ownerCountry: ownerCountry,
                    issueManager: securityData[0]?.issueManager,
                    // country: Response.parseBytes32Value(securityData[0]?.country),
                    currencyAddress: token.tokenAddress,
                    currencyName: token.tokenName,
                    offered: security["offered"],
                    securityName: securityTokenSymbol.replace(/\0/g, ""),
                    isin: Response.parseBytes32Value(security["isin"]),
                    offeringDoc: security["offeringdocs"],
                    amountOffered: Response.parseWeiToEther(
                      security["amountOffered"].toString()
                    ),

                    amountDesired: Response.parseTokenAmount(
                      security["amountDesired"].toString(),
                      tokenDecimals
                    ),
                    min: Response.parseTokenAmount(
                      security["min"].toString(),
                      tokenDecimals
                    ),
                    liquidityOffered: liquidityOffered,
                    liquidityProviders: liquidityProviders,
                    settlements: settlements,
                    subscriptionsClosed: filteredClosed,
                    activeIssue,
                  };
                }
              );
              const securities = await Promise.all(offered);
              return securities;
            }
          );
          const finalToken = await Promise.all(promises);
          let finalArr = [];
          finalToken.map((token) => {
            token.map((securities) => {
              finalArr.push(securities);
            });
          });
          const clientRole = await clientContract.getRole(account);
          let managerCountry = Response.parseBytes32Value(
            clientRole.response.result[2]
          );
          managerCountry = getCountryName(managerCountry);

          if (userRole === "DP")
            //filtering out those issues are not active, managed by DP and issued for DP's country
            // finalArr = finalArr?.filter(pool => !pool.activeIssue && (pool.issueManager?.toLowerCase() === account.toLowerCase() || managerCountry === pool.ownerCountry));
            finalArr = finalArr?.filter(
              (pool) =>
                !pool.activeIssue &&
                (pool.issueManager?.toLowerCase() === account.toLowerCase() ||
                  true)
            );
          else if (userRole === "AM")
            //filtering out those issues which are not closed
            finalArr = finalArr?.filter(
              (pool) => !pool.subscriptionsClosed?.length
            );

          setDataList(finalArr);
          setLoading(false);
        }
      }
    };
    fetchTokens();
  }, [account, chainId, verifiedSecurities, transport]);

  return { data: dataList, loading };
}
// Function to settle the secondary trade request
const settleRequest = async (
  rowData,
  action,
  contract,
  isUpdate,
  setIsUpdate,
  chainId,
  networkOptions,
  securityContract = null,
  securityBuyer = null,
  amount = null,
  address = null
) => {
  try {
    const tx = await contract.setSettlementStatus(
      rowData,
      action,
      networkOptions
    );
    if (tx.code === "ACTION_REJECTED") {
      toast.error("Transaction rejected by user!", ToastOptions);
      return;
    } else {
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
      toast.success(SuccessToast(transactionLink), ToastOptions);
      if (
        securityContract &&
        securityBuyer &&
        amount &&
        address &&
        action === "Confirm"
      ) {
        // const buyerSecurityBalance = await securityContract.methods
        //   .balanceOf(securityBuyer)
        //   .call();
        await securityContract.methods
          .whiteList(
            securityBuyer,
            // Number(buyerSecurityBalance) > Number(amount)
            //   ? buyerSecurityBalance
            //   : amount
            amount
          )
          .send({ from: address })
          .then((res) => {
            toast.success("Whitelist Successful", ToastOptions);
          })
          .catch((err) => {
            console.error("Whitelist failed with error: ", err);
            toast.error("Whitelist failed", ToastOptions);
            return;
          });
      }
      setIsUpdate(!isUpdate);
    }
  } catch (err) {
    let error = { err };
    console.log(error);
    const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
    toast.error(FailureToast(transactionLink), ToastOptions);
    return;
  }
};

// Function to fetch details about the secondary settlements to be Accepted/Rejected
export function useSecondarySettlements(verifiedSecurity) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState(null);
  const { data: usersList } = useClientKYCData();
  const { isUpdate, setIsUpdate } = useGlobal();
  const { data: networkOptions } = useNetworkOptions();

  let clientContract, secondaryIssueManager;

  if (provider && provider.getSigner && chainId && address) {
    const BalancerSecondaryIssueManager =
      contractAddress[chainId].BalancerSecondaryIssueManager;
    if (BalancerSecondaryIssueManager) {
      secondaryIssueManager = new SecondaryIssueManager(
        provider.getSigner(address),
        BalancerSecondaryIssueManager,
        "balancer"
      );
    }
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
  }

  useEffect(() => {
    const fetchInvestors = async () => {
      if (verifiedSecurity.secondaryInvestors && chainId) {
        const dataFetch = await Promise.all(
          verifiedSecurity?.secondaryInvestors?.map(async (investor) => {
            // const { ownerName: transferorName, ownerCountry: transferorCountry } = await getOwnerDetails(investor.issuer, clientContract);
            const transferorDetails = usersList.find((user) => {
              if (
                user &&
                user.accountAddress.toLowerCase() ===
                  investor.issuer.id.toLowerCase()
              ) {
                return user;
              }
            });

            const currencyTokenContract = new web3.eth.Contract(
              ERC20ABI,
              investor.currency
            );
            const currencyTokenDecimals = await currencyTokenContract.methods
              .decimals()
              .call();

            const currencySymbol = await currencyTokenContract.methods
              .symbol()
              .call();

            const securityTokenLink = `${networks[chainId].blockExplorerUrls[0]}address/${investor.security.id}`;
            const currencyTokenLink = `${networks[chainId].blockExplorerUrls[0]}address/${investor.currency}`;

            const securityTokenContract = new web3.eth.Contract(
              Security.abi,
              investor.security.id
            );
            const securityTokenDecimals = await securityTokenContract.methods
              .decimals()
              .call();

            const securitySymbol = await securityTokenContract.methods
              .symbol()
              .call();

            const securityFrozenStatus = await securityTokenContract.methods
              .frozen(investor.issuer.id)
              .call();

            // const { ownerName: transfereeName, ownerCountry: transfereeCountry } = await getOwnerDetails(investor.investor, clientContract);
            const transfereeDetails = usersList.find(
              (user) =>
                user.accountAddress.toLowerCase() ===
                investor.investor.id.toLowerCase()
            );

            const transfereeLink = `${networks[chainId].blockExplorerUrls[0]}address/${investor.investor.id}`;
            const transferorLink = `${networks[chainId].blockExplorerUrls[0]}address/${investor.issuer.id}`;

            const snapShots = await securityTokenContract.methods
              .getNextSnapshots()
              .call();
            const snapshotData = await snapShots.map(async (time) => {
              const earnings = await securityTokenContract.methods
                .getDistribution(time)
                .call();
              const balance = await securityTokenContract.methods
                .snapshotBalanceOf(time, investor.issuer.id)
                .call();
              return {
                time,
                earnings: earnings[1],
                balance: web3.utils.fromWei(balance, "ether"),
              };
            });

            const tradeDetails = verifiedSecurity?.trades.find(
              (trade) => trade.tradeRef === investor.tradeRef
            );
            const status = tradeDetails?.settlementStatus
              ? Response.parseBytes32Value(tradeDetails?.settlementStatus)
              : null;
            let buttonData;
            if (status === "Confirm") {
              buttonData = (
                <span>
                  <b>Settled</b>
                </span>
              );
            } else if (status === "Reject") {
              buttonData = (
                <span>
                  <b>Failed</b>
                </span>
              );
            } else {
              buttonData = (
                <DropdownButton id="dropdown-basic-button" title={"Action"}>
                  <Dropdown.Item
                    onClick={async () => {
                      setLoading(true);
                      await settleRequest(
                        investor.tradeRef,
                        "Confirm",
                        secondaryIssueManager,
                        isUpdate,
                        setIsUpdate,
                        chainId,
                        networkOptions,
                        securityTokenContract,
                        investor.investor.id,
                        investor.amount,
                        address
                      ).then(() => {
                        setLoading(false);
                      });
                    }}
                  >
                    Accept
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={async () => {
                      setLoading(true);
                      await settleRequest(
                        investor.tradeRef,
                        "Reject",
                        secondaryIssueManager,
                        isUpdate,
                        setIsUpdate,
                        chainId,
                        networkOptions
                      ).then(() => {
                        setLoading(false);
                      });
                    }}
                  >
                    Reject
                  </Dropdown.Item>
                </DropdownButton>
              );
            }
            return {
              id: investor.issuer.id,
              transferor: investor.issuer.id,
              transferorNameAsLink: transferorDetails?.fullName,
              transferorCountry: transferorDetails?.country,
              transfereeLink,
              transferorLink,
              currencySymbolFmt: currencySymbol.replace(/\0/g, ""),
              securitySymbolFmt: currencySymbol.replace(/\0/g, ""),
              transferee: investor.investor.id,
              transfereeNameAsLink: transfereeDetails?.fullName,
              transfereeCountry: transfereeDetails?.country,
              currencySymbolAsLink: currencySymbol.replace(/\0/g, ""),
              securitySymbolAsLink: securitySymbol.replace(/\0/g, ""),
              securityTokenLink,
              currencyTokenLink,
              securityAddress: investor.security.id,
              ref: investor.tradeRef,
              status,
              currencyAddress: investor.currency,
              price: Response.parseTokenAmount(investor.price, 18).toFixed(2),
              unitsToTransfer: Response.parseTokenAmount(
                investor.amount,
                securityTokenDecimals
              ).toFixed(2),
              date: investor.timestamp,
              snapShots: snapShots,
              securityTokenContract: securityTokenContract,
              web3: web3,
              snapshotData: snapshotData,
              buttonDetails: buttonData,
              frozen: Number(securityFrozenStatus),
            };
          })
        );

        setDataList(dataFetch);
        setLoading(false);
      }
    };
    if (verifiedSecurity) fetchInvestors();
  }, [account, chainId, verifiedSecurity, usersList, transport]);

  return { data: dataList, loading };
}

// Function to fetch primary subscribers
export function useGetsubscribers(verifiedSecurity) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const { data: usersList } = useClientKYCData();

  let clientContract;
  if (provider && provider.getSigner && chainId && address) {
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
  }

  useEffect(() => {
    const fetchInvestors = async () => {
      const subscribers = verifiedSecurity?.primarySubscribers;
      if (subscribers) {
        setLoading(true);
        const dataFetch = await Promise.all(
          subscribers?.map(async (investor) => {
            const ownerDetails = usersList.find((user) => {
              if (
                user &&
                user.accountAddress.toLowerCase() ===
                  investor.investor?.id.toLowerCase()
              ) {
                return user;
              }
            });
            const currencyTokenContract = new web3.eth.Contract(
              ERC20ABI,
              investor.currency
            );
            const currencyTokenDecimals = await currencyTokenContract.methods
              .decimals()
              .call();
            const currencyTokenName = await currencyTokenContract.methods
              .name()
              .call();

            const securityTokenContract = new web3.eth.Contract(
              ERC20ABI,
              investor.security.id
            );
            const securityTokenDecimals = await securityTokenContract.methods
              .decimals()
              .call();
            const securityFrozenStatus = await securityTokenContract.methods
              .frozen(investor.investor.id)
              .call();
            // formatting refunds data
            const refunds = verifiedSecurity.primaryRefunds.filter(
              (subscriber) => subscriber.investor.id === investor.investor.id
            );
            const redundsData = await Promise.all(
              refunds.map(async (investor) => {
                const tokenContract = new web3.eth.Contract(
                  ERC20ABI,
                  investor.currency
                );
                const tokenDecimals = await tokenContract.methods
                  .decimals()
                  .call();
                const tokenName = await tokenContract.methods.symbol().call();
                return {
                  ...investor,
                  amount: Response.parseTokenAmount(
                    investor.refundAmount,
                    tokenDecimals
                  ).toFixed(2),
                  tokenName: tokenName,
                };
              })
            );

            const allotments = verifiedSecurity.primaryAllotments.filter(
              (subscriber) => subscriber.investor.id === investor.investor.id
            );
            const allotmentsData = await Promise.all(
              allotments.map(async (investor) => {
                const currencyTokenContract = new web3.eth.Contract(
                  ERC20ABI,
                  investor.currency
                );
                const currencyTokenDecimals =
                  await currencyTokenContract.methods.decimals().call();
                const currencyTokenName = await currencyTokenContract.methods
                  .symbol()
                  .call();

                const securityTokenContract = new web3.eth.Contract(
                  ERC20ABI,
                  investor.security.id
                );
                const securityTokenDecimals =
                  await securityTokenContract.methods.decimals().call();
                const securityTokenName = await securityTokenContract.methods
                  .symbol()
                  .call();
                return {
                  ...investor,
                  allotedAmount: Response.parseTokenAmount(
                    investor.allotedAmount,
                    currencyTokenDecimals
                  ).toFixed(2),
                  currencyTokenName: currencyTokenName,
                  securitySubscribed: Response.parseTokenAmount(
                    investor.securitySubscribed,
                    securityTokenDecimals
                  ).toFixed(2),
                  securityTokenName: securityTokenName,
                };
              })
            );
            return {
              id: investor.investor.id,
              poolId: investor.pool,
              asset: investor.currency,
              securityAddress: investor.security.id,
              investorName: ownerDetails?.fullName,
              address: investor.investor.id,
              tokenName: currencyTokenName,
              bought: investor.bought,
              qty: Response.parseTokenAmount(
                investor.cashSwapped,
                currencyTokenDecimals
              ),
              traded: Response.parseTokenAmount(
                investor.securitySwapped,
                securityTokenDecimals
              ),
              refunds: redundsData,
              allotments: allotmentsData,
              frozen: Number(securityFrozenStatus),
              poolClosed: verifiedSecurity.subscriptionsClosed.length
                ? true
                : false,
              poolSettled: verifiedSecurity.primarySettlements.length
                ? true
                : false,
              date: investor.timestamp,
            };
          })
        );
        const reducedInvestors = Object.values(
          dataFetch.reduce((acc, curr) => {
            const { address, qty, traded, bought, ...rest } = curr;
            acc[address] = acc[address] || {
              address,
              qty: 0,
              traded: 0,
              ...rest,
            };
            acc[address].qty += bought ? qty : -qty;
            acc[address].traded += bought ? traded : -traded;
            return acc;
          }, {})
        );
        setDataList(reducedInvestors);
      }
      setLoading(false);
    };
    fetchInvestors();
  }, [account, chainId, verifiedSecurity, usersList, transport]);

  return { data: dataList, loading };
}

// Function to get details about the security token
export function useSecurityTokenDetails(security) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, factoryContract, web3;
  if (provider && provider.getSigner && chainId && address) {
    if (contractAddress[chainId].SecuritiesFactory) {
      factoryContract = new SecuritiesFactory(
        provider.getSigner(address),
        contractAddress[chainId].SecuritiesFactory
      );
    }
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState(null);

  useEffect(() => {
    const fetchTokens = async () => {
      if (factoryContract && account) {
        const response = await factoryContract.getIssues();
        const securityTokenAddress = response.response?.result;
        const promises = await securityTokenAddress?.map(
          async (securityAddress) => {
            const res = await factoryContract.getSecurityToken(
              securityAddress,
              account
            );
            const tokenDetails = res.response?.result;
            return {
              address: tokenDetails?.[0],
              isin: Response.parseBytes32Value(tokenDetails[1]),
              companyName: Response.parseBytes32Value(tokenDetails[2]),
              currencyAddress: tokenDetails?.[3],
            };
          }
        );
        const finalToken = await Promise.all(promises ?? []);
        setDataList(finalToken);
        setLoading(false);
      }
    };
    fetchTokens();
  }, [account, chainId, transport]);

  let isSecurityIssuer;
  if (dataList && security) {
    const securityTokenAddressList = dataList.map((token) => {
      return token.address.toLowerCase();
    });
    isSecurityIssuer = securityTokenAddressList.includes(security);
  }
  return { data: dataList, loading, isSecurityIssuer };
}

// Function to get details about the managers of a particular platform
export function useManagerDetails(platformAddress) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState(null);
  const { data: platformsList } = useVerifiedPlatforms();

  let liquidityContract, clientContract;
  if (provider && provider.getSigner && chainId && address) {
    if (contractAddress[chainId].Liquidity) {
      liquidityContract = new Liquidity(
        provider.getSigner(address),
        contractAddress[chainId].Liquidity
      );
    }
    if (contractAddress[chainId].Client) {
      clientContract = new Client(
        provider.getSigner(address),
        contractAddress[chainId].Client
      );
    }
  }

  useEffect(() => {
    const fetchManagerPerformance = async () => {
      if (liquidityContract) {
        const selectedPlatform = platformsList.find(
          (platform) =>
            platform.platform.toLowerCase() === platformAddress.toLowerCase()
        );

        const response = await liquidityContract.getManagers(platformAddress);
        const managers = response.response.result;

        const notNull = managers.filter(
          (managerAddress) => managerAddress !== NULL_ADDRESS
        );
        const tokens = await liquidityContract.getSupportedTokens();
        const tokensList = tokens.response.result;
        const notNullTokenList = tokensList.filter(
          (token) => token.tokenAddress !== NULL_ADDRESS
        );
        const promises = notNull.map(async (managerAddress) => {
          const managers = notNullTokenList.map(async (token) => {
            const managerResponse =
              await liquidityContract.getManagerPerformance(
                platformAddress,
                token.tokenAddress,
                managerAddress
              );
            const result = managerResponse.response.result;
            let tokenDecimals, liquidityContractBalance;
            if (token.tokenAddress !== ethers.constants.AddressZero) {
              const currencyTokenContract = new web3.eth.Contract(
                ERC20ABI,
                token.tokenAddress
              );
              tokenDecimals = await currencyTokenContract.methods
                .decimals()
                .call();
              liquidityContractBalance = await currencyTokenContract.methods
                .balanceOf(liquidityContract.contractAddress)
                .call();
              liquidityContractBalance = Response.parseTokenAmount(
                liquidityContractBalance,
                tokenDecimals
              );
            }
            const vittaTokenContract = new web3.eth.Contract(
              ERC20ABI,
              contractAddress[chainId].Vitta
            );
            let managerVittaBalance = await vittaTokenContract.methods
              .balanceOf(managerAddress)
              .call();
            const { ownerName, ownerCountry } = await getOwnerDetails(
              managerAddress,
              clientContract
            );

            return {
              managerAddress,
              managerName: ownerName,
              managerCountry: ownerCountry,
              tokenAddress: token.tokenAddress,
              tokenName: token.tokenName,
              managerVittaBalance,
              liquidityContractBalance,
              liquidityProvided: Response.parseTokenAmount(
                result[0].toString(),
                tokenDecimals
              ).toFixed(2),
              commissionEarned: Response.parseTokenAmount(
                result[1].toString(),
                tokenDecimals
              ).toFixed(2),
            };
          });
          const data = await Promise.all(managers);
          return data;
        });
        let finalArr = []; //Formatting data as per BootstrapTable requirement
        const finalData = await Promise.all(promises);
        finalData.map((manager) => {
          manager.map((managerDetail) => {
            finalArr.push(managerDetail);
          });
        });

        setDataList(finalArr);
        setLoading(false);
      }
    };
    fetchManagerPerformance();
  }, [account, chainId, platformsList, transport]);

  return { managerDetail: dataList, loading };
}

// Function to fetch details about all the platforms listed
export function usePlatformDetails() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState(null);
  const { data: platformsList } = useVerifiedPlatforms();
  const [reloadData, setReloadData] = useState(true); // New state variable

  useEffect(() => {
    const fetchPlatformsData = async () => {
      const promises = platformsList.map(async (platform) => {
        const platformLiquidityProvided = platform.platformLiquidity.reduce(
          (total, obj) => total + Number(obj.LPToken),
          0
        );
        const feeCollected = platform.feeCollections.reduce(
          (acc, obj) => acc + Number(obj.feeCollected),
          0
        );
        return {
          id: platform.platform,
          platformAddress: platform.platform,
          platformName: Response.parseBytes32Value(platform.name),
          // unstakedLiquidity: result[0].toString(),
          // balancePlatformLiquidity: result[1].toString(),
          platformLiquidityProvided: Number(
            Response.parseTokenAmount(platformLiquidityProvided, 18)
          ).toFixed(2),
          platformCommissionsEarned: Number(
            Response.parseTokenAmount(feeCollected, 6)
          ).toFixed(2),
          isPayOutDone: platform.platformReturns.length ? true : false,
        };
      });
      const finalData = await Promise.all(promises);
      setDataList(finalData);

      setLoading(false);
    };
    if (reloadData) {
      fetchPlatformsData();
      setReloadData(false); // Reset the state variable after fetching data
    }
  }, [account, chainId, platformsList, reloadData, transport]);

  // Function to trigger data reload
  const reloadPlatformData = () => {
    setReloadData(true);
  };

  return { data: dataList, loading, reloadPlatformData };
}

// Function to fetch settled trades in Trades Page Investor facing
export function useSettledTrades() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState(null);
  let { data: verifiedSecurities } = useVerifiedSecuritiesList();
  useEffect(() => {
    const fetchTradeDetails = async () => {
      const securitiesDetails = verifiedSecurities?.filter((data) => {
        if (
          data.trades.length > 0 &&
          data.productCategory.toLowerCase() !==
            "0x4346440000000000000000000000000000000000000000000000000000000000".toLowerCase()
        ) {
          return data;
        }
      });
      let detailsList = [];
      const result = await Promise.all(
        securitiesDetails.map(async (trade) => {
          const currencyTokenContract = new web3.eth.Contract(
            ERC20ABI,
            trade.currency
          );
          const currencyTokenSymbol = await currencyTokenContract.methods
            .symbol()
            .call();
          const securityTokenContract = new web3.eth.Contract(
            ERC20ABI,
            trade.security
          );
          const securityTokenName = (
            await securityTokenContract.methods.symbol().call()
          ).replace(/\0/g, "");
          const currencyTokenName = (
            await currencyTokenContract.methods.symbol().call()
          ).replace(/\0/g, "");

          for (const subscriber of trade.trades) {
            const isSeller =
              subscriber.transferor?.id.toLowerCase() === account.toLowerCase();
            const isBuyer =
              subscriber.transferee?.id.toLowerCase() === account.toLowerCase();
            const orderType = isSeller ? "Sell" : isBuyer ? "Buy" : null;
            //Condition check : If Order type exits: Connected wallet is either Buyer or Seller
            if (orderType) {
              const calcPrice = Number(
                Response.parseTokenAmount(subscriber.price, 18)
              );
              const calcSecAmount = Number(
                Response.parseTokenAmount(subscriber.unitsToTransfer, 18)
              );
              const calcCurrAmount = calcPrice * calcSecAmount;
              // const status = Response.parseBytes32Value(
              //   subscriber.settlementStatus
              // )
              let status;
              subscriber.settlementStatus
                ? (status = Response.parseBytes32Value(
                    subscriber.settlementStatus
                  ))
                : (status = null);
              const data = {
                securityName: securityTokenName,
                currencyName: currencyTokenName,
                date: subscriber.timestamp,
                orderType: orderType,
                price: calcPrice.toFixed(6),
                quantity: calcSecAmount.toFixed(4),
                currencyAmount:
                  currencyTokenSymbol + " " + calcCurrAmount.toFixed(4),
                claimTrade: false,
                ref: subscriber.tradeRef,
                status: status,
              };
              detailsList.push(data);
            }
          }
        })
      );
      setDataList(detailsList);
      setLoading(false);
    };
    fetchTradeDetails();
  }, [account, chainId, verifiedSecurities, transport]);
  return {
    data: dataList,
    loading: loading,
  };
}

// Function to fetch trade details
export function useTradeDetails() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState(null);
  let { data: verifiedSecurities } = useVerifiedSecuritiesList();
  useEffect(() => {
    const fetchTradeDetails = async () => {
      let detailsList = [];
      if (verifiedSecurities && verifiedSecurities.length > 0) {
        const securitiesDetails = verifiedSecurities?.filter(
          (data) => data.primarySubscribers.length
        );

        await Promise.all(
          securitiesDetails.map(async (securityDetail) => {
            const currencyTokenContract = new web3.eth.Contract(
              ERC20ABI,
              securityDetail.currency
            );
            const currencyTokenDecimals = await currencyTokenContract.methods
              .decimals()
              .call();
            const currencyTokenSymbol = await currencyTokenContract.methods
              .symbol()
              .call();
            const securityTokenContract = new web3.eth.Contract(
              ERC20ABI,
              securityDetail.security
            );
            const securityTokenDecimals = await securityTokenContract.methods
              .decimals()
              .call();
            const securityTokenName = (
              await securityTokenContract.methods.symbol().call()
            ).replace(/\0/g, "");
            const currencyTokenName = currencyTokenSymbol.replace(/\0/g, "");

            const allotedPrimarySecurities =
              securityDetail.primaryAllotments?.filter(
                (data) =>
                  data.investor?.id.toLowerCase() === account.toLowerCase()
              );
            const refundedPrimarySecurities =
              securityDetail.primaryRefunds?.filter(
                (data) =>
                  data.investor?.id.toLowerCase() === account.toLowerCase()
              );
            if (
              allotedPrimarySecurities.length ||
              refundedPrimarySecurities.length
            ) {
              for (
                let index = 0;
                index < allotedPrimarySecurities.length;
                index++
              ) {
                const allotedInvestor = allotedPrimarySecurities[index];
                const quantity = Response.parseTokenAmount(
                  allotedInvestor.securitySubscribed,
                  securityTokenDecimals
                );
                const amount = Response.parseTokenAmount(
                  allotedInvestor.allotedAmount,
                  currencyTokenDecimals
                );
                const data = {
                  id: securityTokenName + quantity,
                  securityName: securityTokenName,
                  currencyName: currencyTokenName,
                  date: allotedInvestor.timestamp,
                  orderType: "Buy",
                  status: "Alloted",
                  price: Number(quantity / amount).toFixed(6),
                  quantity: Number(quantity).toFixed(4),
                  currencyAmount: currencyTokenSymbol + " " + amount.toFixed(4),
                  claimTrade: false,
                  isPrimary: true,
                };
                detailsList.push(data);
              }
              for (
                let index = 0;
                index < refundedPrimarySecurities.length;
                index++
              ) {
                const refundedInvestor = refundedPrimarySecurities[index];
                const quantity = Response.parseTokenAmount(
                  refundedInvestor.securitySubscribed,
                  securityTokenDecimals
                );
                const amount = Response.parseTokenAmount(
                  refundedInvestor.refundAmount,
                  currencyTokenDecimals
                );

                const data = {
                  id: securityTokenName + quantity,
                  securityName: securityTokenName,
                  currencyName: currencyTokenName,
                  date: refundedInvestor.timestamp,
                  orderType: "Buy",
                  status: "Refunded",
                  price: Number(quantity / amount).toFixed(2),
                  quantity: Number(quantity).toFixed(2),
                  currencyAmount: currencyTokenSymbol + " " + amount.toFixed(2),
                  claimTrade: false,
                  isPrimary: true,
                };
                detailsList.push(data);
              }
            } else {
              let modifiedPriceArray;
              let subscribers = securityDetail.primarySubscribers;
              const isPrimary = securityDetail.primarySubscribers.length > 0;
              const quantity = "securitySwapped";

              subscribers = subscribers?.filter((data) => {
                const investorMatches =
                  data.investor?.id.toLowerCase() === account.toLowerCase();
                return investorMatches;
              });
              modifiedPriceArray = subscribers.map((subscriber) => ({
                ...subscriber,
                quantity: Response.parseTokenAmount(
                  subscriber[quantity],
                  securityTokenDecimals
                ),
                price:
                  Response.parseTokenAmount(
                    subscriber.cashSwapped,
                    currencyTokenDecimals
                  ) /
                  Response.parseTokenAmount(
                    subscriber.securitySwapped,
                    securityTokenDecimals
                  ),
                amount: Response.parseTokenAmount(
                  subscriber.cashSwapped,
                  currencyTokenDecimals
                ),
              }));

              for (let index = 0; index < modifiedPriceArray.length; index++) {
                const subscribers = modifiedPriceArray[index];
                const isBuy = subscribers.bought === true;
                let status;
                const orderType = isBuy ? "Buy" : "Sell";
                if (orderType === "Sell") {
                  status = "Settled";
                } else {
                  status = "Subscribed";
                }

                const data = {
                  id: securityTokenName + subscribers.price,
                  securityName: securityTokenName,
                  currencyName: currencyTokenName,
                  date: subscribers.timestamp,
                  orderType: orderType,
                  status,
                  price: Number(subscribers.price).toFixed(2),
                  quantity: Number(subscribers.quantity).toFixed(2),
                  currencyAmount:
                    currencyTokenSymbol + " " + subscribers.amount.toFixed(2),
                  claimTrade: false,
                  isPrimary,
                };
                detailsList.push(data);
              }
            }
          })
        );
      }
      setDataList(detailsList);
      setLoading(false);
    };
    fetchTradeDetails();
  }, [account, chainId, verifiedSecurities, transport]);
  return {
    data: dataList,
    loading: loading,
  };
}

// Function to fetch listed securities details and investors: Portfolio Page
export function useSecuritiesDetails() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState(null);
  const [currencyList, setCurrencyList] = useState(null);
  let { data: verifiedSecurities } = useVerifiedSecuritiesList();
  const { data: usersList } = useClientKYCData();

  useEffect(() => {
    const fetchSecuritiesDetail = async () => {
      setLoading(true);
      const securitiesDetails = verifiedSecurities?.filter(
        (data) =>
          data.primarySubscribers.length || data.secondaryInvestors.length
      );

      const result = await Promise.all(
        securitiesDetails.map(async (pool) => {
          const ownerDetails = usersList.find((user) => {
            if (
              user &&
              user.accountAddress?.toLowerCase() ===
                pool.issuer.id.toLowerCase()
            ) {
              return user;
            }
          });
          let totalSecuritySwapped = 0;
          let cashDataPoints = [];
          let acquisitionDataPointList = [];
          let holdingDate, currentMarketPrice;
          const currencyTokenContract = new web3.eth.Contract(
            ERC20ABI,
            pool.currency
          );
          const currencyTokenDecimals = await currencyTokenContract.methods
            .decimals()
            .call();
          const securityTokenContract = new web3.eth.Contract(
            ERC20ABI,
            pool.security
          );
          const securityTokenDecimals = await securityTokenContract.methods
            .decimals()
            .call();

          let modifiedPriceArray;
          let subscribersKey;

          if (pool.primarySubscribers.length) {
            modifiedPriceArray = pool.primarySubscribers.map((subscribers) => ({
              ...subscribers,
              price:
                Response.parseTokenAmount(
                  subscribers.cashSwapped,
                  currencyTokenDecimals
                ) /
                Response.parseTokenAmount(
                  subscribers.securitySwapped,
                  securityTokenDecimals
                ),
              swap: "primary",
            }));
            subscribersKey = "securitySwapped";
          } else {
            modifiedPriceArray = pool.secondaryInvestors.map((subscribers) => ({
              ...subscribers,
              price:
                Response.parseTokenAmount(subscribers.price, 18) /
                Response.parseTokenAmount(
                  subscribers.amount,
                  securityTokenDecimals
                ),
              swap: "secondary",
            }));
            subscribersKey = "amount";
          }
          modifiedPriceArray.sort((a, b) => a.timestamp - b.timestamp);
          currentMarketPrice = modifiedPriceArray.at(-1).price;
          for (const subscribers of modifiedPriceArray) {
            const securitySeller =
              subscribersKey === "amount"
                ? subscribers.issuer?.id.toLowerCase()
                : subscribers.investor?.id.toLowerCase();
            const securityBuyer =
              subscribersKey === "amount"
                ? subscribers.investor?.id.toLowerCase() ===
                  account.toLowerCase()
                : false;
            const securityAmount = Number(
              Response.parseTokenAmount(
                subscribers[subscribersKey],
                securityTokenDecimals
              )
            );

            if (securitySeller === account.toLowerCase()) {
              let dataPoint = [];
              let acquisitionDataPoint = [];
              if (subscribersKey === "amount") {
                totalSecuritySwapped -= securityAmount;
              } else {
                totalSecuritySwapped += subscribers.bought
                  ? securityAmount
                  : -securityAmount;
              }
              const date = new Date(parseInt(subscribers.timestamp) * 1000);
              const formattedDate = format(date, "yyyy-MM-dd hh:mm a");
              dataPoint.push(formattedDate);
              dataPoint.push(
                Number(securityAmount * currentMarketPrice).toFixed(2)
              );
              cashDataPoints.push(dataPoint);
              acquisitionDataPoint.push(formattedDate);
              acquisitionDataPoint.push(
                Number(securityAmount * subscribers.price).toFixed(2)
              );
              acquisitionDataPointList.push(acquisitionDataPoint);
              holdingDate = subscribers.timestamp;
            } else if (securityBuyer) {
              let dataPoint = [];
              let acquisitionDataPoint = [];
              totalSecuritySwapped += securityAmount;
              const date = new Date(parseInt(subscribers.timestamp) * 1000);
              const formattedDate = format(date, "yyyy-MM-dd hh:mm a");
              dataPoint.push(formattedDate);
              dataPoint.push(securityAmount * currentMarketPrice);
              cashDataPoints.push(dataPoint);
              acquisitionDataPoint.push(formattedDate);
              acquisitionDataPoint.push(securityAmount * subscribers.price);
              acquisitionDataPointList.push(acquisitionDataPoint);
              holdingDate = subscribers.timestamp;
            }
          }

          if (totalSecuritySwapped === 0) return;
          if (totalSecuritySwapped < 0) {
            const securityBalance = await securityTokenContract.methods
              .balanceOf(account)
              .call();
            totalSecuritySwapped = Response.parseWeiToEther(securityBalance);
          }
          return {
            id: pool.security,
            security: pool.security,
            issuer: pool.issuer,
            issuerName: ownerDetails?.fullName,
            currency: pool.currency,
            securityId: Response.parseBytes32Value(pool.isin),
            cashSwapped: (
              Number(totalSecuritySwapped) * currentMarketPrice
            ).toFixed(2),
            date: holdingDate,
            securitySwapped: Number(totalSecuritySwapped)?.toFixed(2),
            portfolioData: cashDataPoints,
            acquisitionData: acquisitionDataPointList,
          };
        })
      );
      const filteredResult = result.filter((value) => value !== undefined);
      setDataList(filteredResult);

      const groupedBySecurity = filteredResult?.reduce((acc, obj) => {
        const key = obj.currency;
        if (!acc[key]) acc[key] = [];
        acc[key].push(obj.cashSwapped);
        return acc;
      }, {});

      const promises = await Promise.all(
        Object.entries(groupedBySecurity).map(async ([key, values]) => {
          const currencyTokenContract = new web3.eth.Contract(ERC20ABI, key);
          const currencyTokenDecimals = await currencyTokenContract.methods
            .decimals()
            .call();
          const currencyTokenName = await currencyTokenContract.methods
            .name()
            .call();

          const data = {
            id: currencyTokenName,
            currencyName: currencyTokenName,
            currencyTokenDecimals: currencyTokenDecimals,
            totalAmount: values.reduce(
              (acc, val) => Number(acc) + Number(val),
              0
            ),
          };
          return data;
        })
      );

      const list = await Promise.all(promises);

      setCurrencyList(list);
      setLoading(false);
    };
    if (provider && provider.getSigner && chainId && address)
      fetchSecuritiesDetail();
  }, [account, chainId, verifiedSecurities, usersList, transport]);

  let acquiData = [];
  let portData = [];

  dataList?.reduce((_, { acquisitionData, portfolioData }) => {
    acquiData.push(...acquisitionData);
    portData.push(...portfolioData);
  }, null);

  const sortedAcquisitionData = acquiData.sort((a, b) => {
    const dateA = new Date(a[0]);
    const dateB = new Date(b[0]);
    return dateA - dateB;
  });
  const sortedPortfolioData = portData.sort((a, b) => {
    const dateA = new Date(a[0]);
    const dateB = new Date(b[0]);
    return dateA - dateB;
  });

  return {
    data: dataList,
    loading: loading,
    currencyList: currencyList,
    acquisitionData: sortedAcquisitionData,
    portfolioData: sortedPortfolioData,
  };
}

const getAllCurrencies = async (client) => {
  let data = [];
  if (client) {
    try {
      let result = await client.query({
        query: GET_VERIFIED_CURRENCIES,
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (err) {
      console.log("Error while getting currencies: ", err);
    }
  }

  return data;
};

export function useGetAllCurrencies() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCurrencies = async () => {
      setLoading(true);
      const result = await Promise.all(
        await getAllCurrencies(verifiedWalletSubgraphClient(chainId)).then(
          (res) => {
            let allCurrencies = [];
            if (res?.data?.currencies?.length > 0 && web3) {
              allCurrencies = res.data.currencies.map(async (cur) => {
                const currencyAddress = cur.token;
                const currencyName = cur.name;
                let currencyBalance;
                if (
                  currencyAddress.toLowerCase() === NULL_ADDRESS.toLowerCase()
                ) {
                  currencyBalance = Number(
                    web3.utils.fromWei(
                      await web3.eth.getBalance(account),
                      "ether"
                    )
                  ).toFixed(4);
                } else {
                  const currencyContract = new web3.eth.Contract(
                    ERC20ABI,
                    currencyAddress
                  );
                  const decimals = Number(
                    await currencyContract.methods.decimals().call()
                  );
                  currencyBalance = (
                    Number(
                      await currencyContract.methods.balanceOf(account).call()
                    ) /
                    10 ** decimals
                  ).toFixed(4);
                }
                return {
                  address: currencyAddress,
                  name: currencyName,
                  balance: currencyBalance,
                };
              });
            }
            return allCurrencies;
          }
        )
      );
      setDataList(result);
      setLoading(false);
    };
    getCurrencies();
  }, [account, chainId, transport]);
  const res = {
    allCurrencies: dataList,
    loading: loading,
  };
  return res;
}

const getAllSecurities = async (client) => {
  let data = [];
  if (client) {
    try {
      let result = await client.query({
        query: GET_VERIFIED_SECURITIES,
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (err) {
      console.log("Error while getting all securities: ", err);
    }
  }

  return data;
};

const getManagerMarginSecurities = async (client, manager) => {
  let data = [];
  if (client) {
    try {
      let result = await client.query({
        query: GET_VERIFIED_MARGIN_SECURITIES(manager),
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (err) {
      console.log("get margin securities failed with error: ", err);
    }
  }
  return data;
};

const getAllMarginPools = async (client) => {
  let data = [];
  if (client) {
    try {
      let result = await client.query({
        query: GET_ALL_BALANCER_MARGIN_ISSUE_POOLS(),
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (err) {
      console.log("get all margin pools failed with error: ", err);
    }
  }
  return data;
};

export const getSecurity = async (client, id) => {
  let data = [];
  if (id !== "" && client) {
    try {
      let result = await client.query({
        query: GET_VERIFIED_SECURITY,
        variables: {
          id: id,
        },
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (err) {
      console.log("Error while getting currencies: ", err);
    }
  }
  return data;
};

export function useGetAllSecurities() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState([]);
  const [portifolioData, setPortifolioData] = useState([]);
  const [acquisitionData, setAcquisitionData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSecurities = async () => {
      setLoading(true);
      let allSecurities = [];
      const result = await Promise.all(
        await getAllSecurities(verifiedWalletSubgraphClient(chainId)).then(
          (res) => {
            if (res?.data?.securities?.length > 0 && web3) {
              return res.data.securities.map(async (sec) => {
                if (sec) {
                  const trades = sec.trades;
                  const subcribers = sec.primarySubscribers;
                  const investors = sec.secondaryInvestors;
                  let issuer;
                  sec.issuer
                    ? (issuer = sec.issuer.id)
                    : (issuer = NULL_ADDRESS);
                  let issuerName = issuer;
                  if (issuer !== NULL_ADDRESS) {
                    issuerName = Response.parseBytes32Value(sec.issuer.name);
                  }
                  const securityAddress = sec.security;
                  const securityContract = new web3.eth.Contract(
                    Security.abi,
                    securityAddress
                  );
                  const securityDecimals = Number(
                    await securityContract.methods.decimals().call()
                  );
                  const currencContract = new web3.eth.Contract(
                    ERC20ABI,
                    sec.currency
                  );
                  const currencyDecimals = Number(
                    await currencContract.methods.decimals().call()
                  );
                  const securityBalance =
                    Number(
                      await securityContract.methods.balanceOf(account).call()
                    ) /
                    10 ** securityDecimals;
                  const securityName = await securityContract.methods
                    .name()
                    .call();
                  const currencySymbol = await currencContract.methods
                    .symbol()
                    .call();
                  let holdingDates = [];
                  let cashDataPoints = [];
                  let acquisitionDataPoints = [];
                  let currentPrice = 0.0;
                  let cashSwappedPrice = 0.0;
                  if (trades.length > 0) {
                    let cashData = [];
                    let acquisitions = [];
                    //Todo: all trades in a security have the same pool???
                    const poolAddress = trades[0].poolid
                      .toString()
                      .substring(0, 42);
                    const poolContract = new web3.eth.Contract(
                      MarginTradingPool.abi,
                      poolAddress
                    );
                    const poolMargin = await poolContract.methods
                      .getMargin()
                      .call();
                    const poolMarginFmt = web3.utils.fromWei(
                      poolMargin.toString(),
                      "ether"
                    );

                    trades.map(async (trd) => {
                      const date = new Date(Number(trd.timestamp) * 1000);
                      const formattedDate = format(date, "yyyy-MM-dd hh:mm a");
                      const amountFmt = Number(
                        web3.utils.fromWei(
                          trd.unitsToTransfer.toString(),
                          "ether"
                        )
                      ).toFixed(6);
                      if (
                        (trd.transferor &&
                          trd.transferor.id.toLowerCase() ===
                            account.toLowerCase()) ||
                        (trd.transferee &&
                          trd.transferee.id.toLowerCase() ===
                            account.toLowerCase())
                      ) {
                        const _curAmt =
                          Number(trd.amountPaid) / 10 ** currencyDecimals;
                        const _secAmt = Number(
                          web3.utils.fromWei(
                            trd.unitsToTransfer.toString(),
                            "ether"
                          )
                        );
                        const prc = _curAmt / _secAmt / Number(poolMarginFmt);
                        const priceFmt = prc.toFixed(4);
                        cashSwappedPrice = priceFmt;
                        //Todo: fetch current price from verified gateway server??
                        currentPrice = priceFmt / amountFmt;
                        holdingDates.push(Number(trd.timestamp));
                        cashData.push(formattedDate);
                        acquisitions.push(formattedDate);
                        cashData.push(priceFmt);
                        acquisitions.push((_curAmt / _secAmt).toFixed(4));
                      }
                    });
                    cashDataPoints.push(cashData);
                    portifolioData.push(cashData);
                    acquisitionDataPoints.push(acquisitions);
                    acquisitionData.push(acquisitions);
                  }
                  if (subcribers.length > 0) {
                    let cashData = [];
                    let acquisitions = [];
                    subcribers.map(async (trd) => {
                      const date = new Date(Number(trd.timestamp) * 1000);
                      const formattedDate = format(date, "yyyy-MM-dd hh:mm a");
                      const amountFmt = Number(
                        web3.utils.fromWei(
                          trd.securitySwapped.toString(),
                          "ether"
                        )
                      ).toFixed(6);
                      if (
                        trd.investor &&
                        trd.investor.id.toLowerCase() === account.toLowerCase()
                      ) {
                        const _curAmt =
                          Number(trd.cashSwapped) / 10 ** currencyDecimals;
                        const _secAmt = Number(
                          web3.utils.fromWei(
                            trd.securitySwapped.toString(),
                            "ether"
                          )
                        );
                        const prc = _curAmt / _secAmt;
                        const priceFmt = prc.toFixed(8);
                        cashSwappedPrice = priceFmt;
                        currentPrice = (priceFmt / amountFmt).toFixed(8);
                        holdingDates.push(Number(trd.timestamp));
                        cashData.push(formattedDate);
                        acquisitions.push(formattedDate);
                        cashData.push((amountFmt * currentPrice).toFixed(8));
                        acquisitions.push((amountFmt * priceFmt).toFixed(8));
                      }
                    });
                    cashDataPoints.push(cashData);
                    portifolioData.push(cashData);
                    acquisitionDataPoints.push(acquisitions);
                    acquisitionData.push(acquisitions);
                  }
                  if (investors.length > 0) {
                    let cashData = [];
                    let acquisitions = [];
                    investors.map(async (trd) => {
                      const date = new Date(Number(trd.timestamp) * 1000);
                      const formattedDate = format(date, "yyyy-MM-dd hh:mm a");
                      const amountFmt = Number(
                        web3.utils.fromWei(trd.amount.toString(), "ether")
                      ).toFixed(6);
                      if (
                        (trd.issuer &&
                          trd.issuer.id.toLowerCase() ===
                            account.toLowerCase()) ||
                        (trd.investor &&
                          trd.investor.id.toLowerCase() ===
                            account.toLowerCase())
                      ) {
                        const _curAmt = Number(
                          web3.utils.fromWei(trd.price.toString(), "ether")
                        );
                        const _secAmt = Number(
                          web3.utils.fromWei(trd.amount.toString(), "ether")
                        );
                        const prc = _curAmt / _secAmt;
                        const priceFmt = prc.toFixed(8);
                        cashSwappedPrice = priceFmt;
                        //Todo: fetch current price from verified gateway server??
                        currentPrice = (priceFmt / amountFmt).toFixed(8);
                        holdingDates.push(Number(trd.timestamp));
                        cashData.push(formattedDate);
                        acquisitions.push(formattedDate);
                        cashData.push((amountFmt * currentPrice).toFixed(2));
                        acquisitions.push((amountFmt * priceFmt).toFixed(2));
                      }
                    });
                    cashDataPoints.push(cashData);
                    portifolioData.push(cashData);
                    acquisitionDataPoints.push(acquisitions);
                    acquisitionData.push(acquisitions);
                  }
                  const leastHoldingDate =
                    holdingDates.sort((a, b) => a - b)[0] || 0;
                  const _res = {
                    address: securityAddress,
                    securityId: web3.utils.toAscii(
                      web3.utils.toHex(securityName.replace(/\0/g, ""))
                    ),
                    securitySwapped: Number(securityBalance).toFixed(10),
                    date: leastHoldingDate,
                    issuer: issuer,
                    issuerName: issuerName,
                    portfolioData: cashDataPoints,
                    acquisitionData: acquisitionDataPoints,
                    cashSwapped: `${cashSwappedPrice} ${currencySymbol}`, //current market price of last trade
                  };
                  allSecurities.push(_res);
                  return _res;
                }
              });
            }
            return allSecurities;
          }
        )
      );
      setDataList(result);
      setLoading(false);
    };
    getSecurities();
  }, [account, chainId, transport]);
  const res = {
    allSecurities: dataList,
    portfolioData: portifolioData,
    acquisitionData: acquisitionData,
    loading: loading,
  };
  return res;
}

export const getMarginCollaterals = async (client) => {
  let data;
  if (client) {
    try {
      let result = await client.query({
        query: GET_MARGIN_COLLATERALS,
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (err) {
      console.log("Error while fetching margin collaterals: ", err);
    }
  }
  return data;
};

export const getMarginPnls = async (client) => {
  let data;
  if (client) {
    try {
      let result = await client.query({
        query: GET_MARGIN_PNLS,
        fetchPolicy: "cache-first",
      });
      data = result;
    } catch (err) {
      console.log("Error while fetching margin trade pnls: ", err);
    }
  }
  return data;
};

export function useGetUserMarginCollateral() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCollaterals = async () => {
      setLoading(true);
      let allCollaterals = {};
      let balances = [];
      const result = await Promise.all(
        await getMarginCollaterals(verifiedWalletSubgraphClient(chainId)).then(
          (res) => {
            if (res && res.data && res.data.marginCollaterals.length > 0) {
              // console.log("col: ", res.data.marginCollaterals)
              return res.data.marginCollaterals.map(async (col, idx) => {
                if (
                  col &&
                  col.user &&
                  col.user.id.toLowerCase() === account.toLowerCase() &&
                  signer
                ) {
                  const currencyContract = new ERC20(signer, col.currency);
                  balances.push({
                    address: col.currency.toLowerCase(),
                    balance: web3.utils.fromWei(
                      col.balance.toString(),
                      "ether"
                    ),
                  });
                  allCollaterals[col.currency.toLowerCase()] = {
                    currencySymbol: await currencyContract
                      .symbol()
                      .then((res) => {
                        return res.response.result[0];
                      }),
                    currencyaddress: col.currency,
                    balanceSubgraph: web3.utils.fromWei(
                      col.balance.toString(),
                      "ether"
                    ),
                    balance: web3.utils.fromWei(
                      col.balance.toString(),
                      "ether"
                    ),
                    collateralType: ethers.utils.parseBytes32String(col.action),
                    collateralTypeRaw: col.action,
                    date: col.time,
                  };
                  return allCollaterals;
                }
              });
            }
            return [];
          }
        )
      );
      balances.map((bal) => {
        if (
          allCollaterals[bal.address] &&
          bal.balance > allCollaterals[bal.address]["balance"]
        ) {
          allCollaterals[bal.address]["balance"] = bal.balance;
        }
      });
      setDataList(Object.values(allCollaterals));
      setLoading(false);
    };
    getCollaterals();
  }, [account, chainId, transport]);
  const res = {
    allCollaterals: dataList,
    loading: loading,
  };
  return res;
}

export function useGetUserMarginPnl() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPnls = async () => {
      setLoading(true);
      let allPnlsFmt = [];
      await Promise.all(
        await getMarginPnls(verifiedWalletSubgraphClient(chainId)).then(
          (res) => {
            // console.log("pnl res: ", res);
            if (res && res.data && res.data.marginTradePnLs.length > 0) {
              return res.data.marginTradePnLs.map(async (pnl) => {
                if (
                  pnl &&
                  pnl.user &&
                  pnl.user.id.toLowerCase() === account.toLowerCase() &&
                  signer
                ) {
                  const currencyContract = new ERC20(signer, pnl.currency);
                  const securityContract = new ERC20(signer, pnl.security.id);
                  const securitySymbol = await securityContract
                    .symbol()
                    .then((res) => {
                      return res.response.result[0];
                    });
                  const _res = {
                    currencySymbol: await currencyContract
                      .symbol()
                      .then((res) => {
                        return res.response.result[0];
                      }),
                    securitySymbol: securitySymbol.replace(/\0/g, ""),
                    currencyaddress: pnl.currency,
                    financing: Number(
                      web3.utils.fromWei(pnl.financing.toString(), "ether")
                    ).toFixed(6),
                    dividend: web3.utils.fromWei(
                      pnl.dividend.toString(),
                      "ether"
                    ),
                    commission: pnl.commission,
                    date: pnl.settlementTime,
                  };
                  allPnlsFmt.push(_res);
                  return _res;
                }
              });
            }
            return allPnlsFmt;
          }
        )
      );
      setDataList(allPnlsFmt);
      setLoading(false);
    };
    getPnls();
  }, [account, chainId, transport]);
  const res = {
    allPnls: dataList,
    loading: loading,
  };
  return res;
}

export function useGetMarginSecurities() {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getMarginSecurities = async () => {
      setLoading(true);
      let allMarginSecurities = [];
      let allPools = [];
      await Promise.all([
        await getManagerMarginSecurities(
          verifiedWalletSubgraphClient(chainId),
          account
        ).then((res) => {
          if (res?.data?.securities?.length > 0) {
            return res?.data?.securities?.map(async (sec) => {
              allMarginSecurities.push(sec);
            });
          }
        }),
        await getAllMarginPools(balancerSubgraphClient(chainId)).then((res) => {
          if (res?.data?.pools?.length > 0) {
            return res?.data?.pools?.map(async (pool) => {
              allPools.push({
                security: pool.security.toLowerCase(),
                currency: pool.currency.toLowerCase(),
              });
            });
          }
        }),
      ]);
      let formatedProducts = [];
      await Promise.all(
        allMarginSecurities.map(async (sec) => {
          const msec = {
            security: sec.security.toLowerCase(),
            currency: sec.currency.toLowerCase(),
          };
          const securityContract = new web3.eth.Contract(
            Security.abi,
            sec.security
          );
          const currencyContract = new web3.eth.Contract(
            ERC20ABI,
            sec.currency
          );
          const currencySymbol = await currencyContract.methods.symbol().call();
          let _securitySymbol = await securityContract.methods.symbol().call();
          const securitySymbol = _securitySymbol.replace(/\0/g, "");
          let notIssued = true;
          allPools.map((pool) => {
            if (
              pool.security === msec.security &&
              pool.currency === msec.currency
            ) {
              notIssued = false;
            }
          });
          formatedProducts.push({
            ...sec,
            marginManageAction: notIssued,
            securityCategory: "CFD",
            currencySymbol,
            securitySymbol,
          });
        })
      );
      setDataList(formatedProducts);
      setLoading(false);
    };
    getMarginSecurities();
  }, [account, chainId, transport]);
  return {
    data: dataList,
    loading: loading,
  };
}

export function useGetVerifiedRWAs(isIssuer) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  let { data: verifiedRWA, loading: rwaLoading } = useVerifiedRWALists();
  // const bond = new Bond(signer, contractAddress[chainId].BOND.VBUSD);

  useEffect(() => {
    const formatRWAs = async () => {
      // await bond.getBonds().then(async (res) => {
      //   console.log("bonds: ", res.response.result);
      //   await bond
      //     .getBondIssues(account, res.response.result[0])
      //     .then((_res) => {
      //       console.log("issued: ", _res.response.result);
      //     });
      // });
      if (provider && provider.getSigner && chainId && address && web3) {
        let data = [];
        verifiedRWA.map((rwa) => {
          if (isIssuer) {
            if (rwa.issuer.id.toLowerCase() === address.toLowerCase()) {
              rwa.bond.bondIssues.map((bond) => {
                const borrowed = rwa.bond.bondPurchases
                  .map((prch) => {
                    return Number(
                      web3.utils.fromWei(
                        prch.purchasedAmount.toString(),
                        "ether"
                      )
                    );
                  })
                  .reduce((a, c) => {
                    return a + c;
                  }, 0);
                data.push({
                  Asset: web3.utils
                    .hexToAscii(bond.bondName?.toString())
                    .replace(/\0/g, ""),
                  Issuer: rwa.issuer
                    ? web3.utils
                        .hexToAscii(rwa.issuer.name.toString())
                        .replace(/\0/g, "")
                    : "",
                  IssuerAddress: rwa.issuer ? rwa.issuer.id.toString() : "",
                  IssuerCountry: rwa.issuer
                    ? web3.utils
                        .hexToAscii(rwa.issuer.country.toString())
                        .replace(/\0/g, "")
                    : "",
                  IssuerTotalIssues: rwa.issuer
                    ? rwa.issuer.bondIssues.length.toString()
                    : "0",
                  IssuerTotalIssuesBorrowed: verifiedRWA
                    .filter((_rwa) => _rwa.issuer.id === rwa.issuer.id)
                    .map((irwa) => {
                      return irwa.bond.bondPurchases
                        .map((prch) => {
                          return Number(
                            web3.utils.fromWei(
                              prch.purchasedAmount.toString(),
                              "ether"
                            )
                          );
                        })
                        .reduce((a, c) => {
                          return a + c;
                        }, 0);
                    })
                    .reduce((y, z) => {
                      return y + z;
                    }, 0),
                  IssuerTotalIssuesRepaid: "0", //Todo: update this
                  Collateral: web3.utils
                    .hexToAscii(bond.collateralCurrency.toString())
                    .replace(/\0/g, ""),
                  CollateralName: bond.collateralCurrency.toString(),
                  InvestorsCollateral: bond.collateralCurrency.toString(),
                  BondTokenAddress: rwa.bond.token.toString(),
                  // "Face Value": rwa.faceValue.toString() === "0" ? rwa.faceValue.toString() : web3.utils.fromWei(rwa.faceValue.toString(), "ether"),
                  Currency: web3.utils
                    .hexToAscii(bond.bondName.toString())
                    .replace(/\0/g, "")
                    .replace("VB", ""),
                  APY:
                    rwa.apy.toString() === "0"
                      ? rwa.apy.toString()
                      : web3.utils.fromWei(rwa.apy.toString(), "ether"),
                  IssuedValue:
                    bond.issuedAmount.toString() === "0"
                      ? bond.issuedAmount.toString()
                      : web3.utils.fromWei(
                          bond.issuedAmount.toString(),
                          "ether"
                        ),
                  IssuingDocs:
                    rwa.issuingDocs.toString().substring(0, 15) +
                    "..." +
                    rwa.issuingDocs
                      .toString()
                      .substring(
                        rwa.issuingDocs.toString().length - 5,
                        rwa.issuingDocs.toString().length
                      ),
                  DocUrl: rwa.issuingDocs.toString(),
                  Borrowed: borrowed === 0 ? borrowed : borrowed.toFixed(6),
                  Borrowers: rwa.bond.bondPurchases.length,
                  Repaid: "0", //Todo: update this
                  SoldValue: borrowed === 0 ? borrowed : borrowed.toFixed(6),
                  CollateralPosted:
                    bond.collateralAmount.toString() === "0"
                      ? bond.collateralAmount.toString()
                      : web3.utils.fromWei(
                          bond.collateralAmount.toString(),
                          "ether"
                        ),
                  Status:
                    rwa.bond.bondRedemptions.filter(
                      (rmdBond) =>
                        rmdBond.id.toLowerCase() === bond.id.toLowerCase()
                    )?.redemptionAmount == bond.collateralAmount ||
                    rwa.bond.bondLiquidations.filter(
                      (lqdBond) =>
                        lqdBond.id.toLowerCase() === bond.id.toLowerCase()
                    )?.liquidatedAmount == bond.collateralAmount
                      ? "Inactive"
                      : "Active",
                  Action: "borrowerAction",
                });
              });
            }
          } else {
            if (rwa.issuer?.id.toLowerCase() !== address.toLowerCase()) {
              rwa.bond.bondIssues.map((bond) => {
                const borrowed = rwa.bond.bondPurchases
                  .map((prch) => {
                    return Number(
                      web3.utils.fromWei(
                        prch.purchasedAmount.toString(),
                        "ether"
                      )
                    );
                  })
                  .reduce((a, c) => {
                    return a + c;
                  }, 0);
                data.push({
                  Asset: web3.utils
                    .hexToAscii(bond.bondName?.toString())
                    .replace(/\0/g, ""),
                  Issuer: rwa.issuer
                    ? web3.utils
                        .hexToAscii(rwa.issuer.name.toString())
                        .replace(/\0/g, "")
                    : "",
                  IssuerAddress: rwa.issuer ? rwa.issuer.id.toString() : "",
                  IssuerCountry: rwa.issuer
                    ? web3.utils
                        .hexToAscii(rwa.issuer.country.toString())
                        .replace(/\0/g, "")
                    : "",
                  IssuerTotalIssues: rwa.issuer
                    ? rwa.issuer.bondIssues.length.toString()
                    : "0",
                  IssuerTotalIssuesBorrowed: verifiedRWA
                    .filter((_rwa) => _rwa.issuer.id === rwa.issuer.id)
                    .map((irwa) => {
                      return irwa.bond.bondPurchases
                        .map((prch) => {
                          return Number(
                            web3.utils.fromWei(
                              prch.purchasedAmount.toString(),
                              "ether"
                            )
                          );
                        })
                        .reduce((a, c) => {
                          return a + c;
                        }, 0);
                    })
                    .reduce((y, z) => {
                      return y + z;
                    }, 0),
                  IssuerTotalIssuesRepaid: "0", //Todo: update this
                  Collateral: web3.utils
                    .hexToAscii(bond.collateralCurrency.toString())
                    .replace(/\0/g, ""),
                  CollateralName: bond.collateralCurrency.toString(),
                  InvestorsCollateral: bond.collateralCurrency.toString(),
                  BondTokenAddress: rwa.bond.token.toString(),
                  // "Face Value": rwa.faceValue.toString() === "0" ? rwa.faceValue.toString() : web3.utils.fromWei(rwa.faceValue.toString(), "ether"),
                  Currency: web3.utils
                    .hexToAscii(bond.bondName.toString())
                    .replace(/\0/g, "")
                    .replace("VB", ""),
                  APY:
                    rwa.apy.toString() === "0"
                      ? rwa.apy.toString()
                      : web3.utils.fromWei(rwa.apy.toString(), "ether"),
                  IssuedValue:
                    bond.issuedAmount.toString() === "0"
                      ? bond.issuedAmount.toString()
                      : web3.utils.fromWei(
                          bond.issuedAmount.toString(),
                          "ether"
                        ),
                  IssuingDocs:
                    rwa.issuingDocs.toString().substring(0, 15) +
                    "..." +
                    rwa.issuingDocs
                      .toString()
                      .substring(
                        rwa.issuingDocs.toString().length - 5,
                        rwa.issuingDocs.toString().length
                      ),
                  DocUrl: rwa.issuingDocs.toString(),
                  Borrowed: borrowed === 0 ? borrowed : borrowed.toFixed(6),
                  Borrowers: rwa.bond.bondPurchases.length,
                  Repaid: "0", //Todo: update this
                  SoldValue: borrowed === 0 ? borrowed : borrowed.toFixed(6),
                  CollateralPosted:
                    bond.collateralAmount.toString() === "0"
                      ? bond.collateralAmount.toString()
                      : web3.utils.fromWei(
                          bond.collateralAmount.toString(),
                          "ether"
                        ),
                  Status:
                    rwa.bond.bondRedemptions.filter(
                      (rmdBond) =>
                        rmdBond.id.toLowerCase() === bond.id.toLowerCase()
                    )?.redemptionAmount == bond.collateralAmount ||
                    rwa.bond.bondLiquidations.filter(
                      (lqdBond) =>
                        lqdBond.id.toLowerCase() === bond.id.toLowerCase()
                    )?.liquidatedAmount == bond.collateralAmount
                      ? "Inactive"
                      : "Active",
                  Action: "lenderAction",
                });
              });
            }
          }
        });
        setDataList(data);
        if (!rwaLoading) {
          setLoading(false);
        }
      }
    };
    formatRWAs();
  }, [account, chainId, verifiedRWA, transport]);

  return { data: dataList, loading };
}

export function useGetSecurityOrders(security) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  let securityToken;
  security ? (securityToken = security.toLowerCase()) : (securityToken = "");
  useEffect(() => {
    const getSecurityTrades = async () => {
      setLoading(true);
      const result = await Promise.all(
        await getSecurity(
          verifiedWalletSubgraphClient(chainId),
          securityToken
        ).then((res) => {
          let allOrders = [];
          let priceData = [];
          let volumeData = [];
          let currentPrice;
          if (res.data.security.marginTraders.length > 0) {
            allOrders = res.data.security.marginTraders.map(async (ord) => {
              const securityContract = new web3.eth.Contract(
                Security.abi,
                ord.security.security
              );
              const securityDecimals = Number(
                await securityContract.methods.decimals().call()
              );
              const currencyDecimals = Number(
                await new web3.eth.Contract(ERC20ABI, ord.currency).methods
                  .decimals()
                  .call()
              );
              // const date = new Date(Number(ord.timestamp) * 1000);
              // const formattedDate = format(date, "yyyy-MM-dd hh:mm a");
            });
          }
          return allOrders;
        })
      );
      setChartData(result);
      setLoading(false);
    };
    getSecurityTrades();
  }, [account, chainId, transport]);
  const res = {
    chartData: chartData,
    loading: loading,
  };
  return res;
}

export function useGetSecurity(security) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  let securityToken;
  security ? (securityToken = security.toLowerCase()) : (securityToken = "");
  useEffect(() => {
    const getSecurityTrades = async () => {
      setLoading(true);
      let allOrders = [];
      let priceData = [];
      let volumeData = [];
      let currentPrice;
      let totalVolume = 0;
      const result = await Promise.all([
        await getSecurity(
          verifiedWalletSubgraphClient(chainId),
          securityToken
        ).then((res) => {
          if (
            res.data &&
            res.data.security &&
            res.data.security.trades.length > 0
          ) {
            res.data.security.trades.map(async (trd) => {
              // const securityContract = new web3.eth.Contract(
              //   Security.abi,
              //   trd.security.security
              // );
              // const securityDecimals = Number(
              //   await securityContract.methods.decimals().call()
              // );
              // const currencyDecimals = Number(
              //   await new web3.eth.Contract(ERC20ABI, trd.currency).methods
              //     .decimals()
              //     .call()
              // );
              const priceFmt = web3.utils.fromWei(
                trd.price.toString(),
                "ether"
              );
              const amountFmt = web3.utils.fromWei(
                trd.unitsToTransfer.toString(),
                "ether"
              );
              currentPrice = priceFmt / amountFmt;
              const date = new Date(Number(trd.timestamp) * 1000);
              const formattedDate = format(date, "yyyy-MM-dd hh:mm a");
              priceData.push(formattedDate);
              priceData.push(priceFmt);
              const ohlcData = formatHourlyData(priceData);
              volumeData.push(formattedDate);
              volumeData.push(amountFmt);
              totalVolume += amountFmt;
              allOrders.push({
                priceData: priceData,
                volumeData: volumeData,
                totalVolume: totalVolume,
                currentPrice: currentPrice,
                ohlcData: ohlcData,
              });
            });
          }
          return allOrders;
        }),
      ]);
      setChartData(allOrders);
      setLoading(false);
    };
    getSecurityTrades();
  }, [account, chainId, transport]);
  const res = {
    chartData: chartData,
    loading: loading,
  };
  return res;
}

export function useGetPoolChart(poolData) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [chartData, setChartData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const handlepoolchart = async () => {
        if (
          poolData &&
          poolData.marginSecuritySymbol &&
          poolData.cfiCode &&
          poolData.security
        ) {
          new Promise(async (resolve, reject) => {
            await getSecurity(
              verifiedWalletSubgraphClient(chainId),
              poolData.security
            ).then(async (res) => {
              let priceData = [];
              const cfiCode = web3?.utils.hexToAscii(
                Response.parseBytes32Value(poolData?.cfiCode)
              );
              const brokerageHost = config.brokerageEndpoint;
              const url = `${brokerageHost}/${cfiCode.toLowerCase()}?security=${
                poolData.marginSecuritySymbol
              }&cash=USD&type=BUY`;
              const currentPriceNw = await axios({
                method: "GET",
                url: url,
              })
                .then((res) => {
                  const response = res.data;
                  return {
                    price: response.body.price,
                    timeStamp: response.body.timeStamp,
                  };
                })
                .catch((err) => {
                  return null;
                });
              if (
                res.data &&
                res.data.security &&
                res.data.security.marginTraders.length > 0
              ) {
                let volumeData = [];
                let currentPrice;
                let totalVolume = 0;
                await res.data.security.marginTraders.forEach(async (trd) => {
                  const securityContract = new web3.eth.Contract(
                    Security.abi,
                    trd.security.security
                  );
                  const securityDecimals = Number(
                    await securityContract.methods.decimals().call()
                  );
                  const currencyDecimals = Number(
                    await new web3.eth.Contract(ERC20ABI, trd.currency).methods
                      .decimals()
                      .call()
                  );
                  const amountFmt =
                    Number(trd.securityTraded) / 10 ** securityDecimals;
                  const cashFmt =
                    Number(trd.cashTraded) / 10 ** currencyDecimals;
                  const price = cashFmt / amountFmt;
                  currentPrice = price; //needs formatting??
                  const date = new Date(Number(trd.timestamp) * 1000);
                  const formattedDate = format(date, "yyyy-MM-dd hh:mm a");
                  priceData.push([formattedDate, price]);
                  if (currentPriceNw) {
                    priceData.push([
                      currentPriceNw?.timeStamp,
                      currentPriceNw?.price,
                    ]);
                  }
                  const ohlcData = formatHourlyData(priceData);
                  volumeData.push(formattedDate);
                  volumeData.push(amountFmt);
                  totalVolume += amountFmt;
                  chartData.push({
                    priceData: priceData,
                    volumeData: volumeData,
                    totalVolume: totalVolume,
                    currentPrice: currentPriceNw?.price || currentPrice,
                    ohlcData: ohlcData,
                  });
                  if (chartData && chartData.length > 0) {
                    setAllData(chartData[chartData.length - 1]);
                  }
                  resolve(allData);
                  setLoading(false);
                });
              } else {
                if (currentPriceNw) {
                  priceData.push([
                    currentPriceNw?.timeStamp,
                    currentPriceNw?.price,
                  ]);
                  const ohlcData = formatHourlyData(priceData);
                  chartData.push({
                    priceData: priceData,
                    volumeData: [],
                    totalVolume: 0.0,
                    currentPrice: currentPriceNw?.price || 0,
                    ohlcData: ohlcData,
                  });
                }

                if (chartData && chartData.length > 0) {
                  setAllData(chartData[chartData.length - 1]);
                }
                setLoading(false);
              }
            });
          });
        }
      };
      await handlepoolchart();
    })();
  }, [poolData, account, chainId, transport]);
  const res = {
    chartData: allData,
    loading: loading,
  };
  return res;
}
